import React, { useState, useEffect } from 'react';
import Api from '../../../services/api';
import { withRouter } from "react-router";

import LoadingOverlay from 'react-loading-overlay';

import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import InputMask from 'react-input-mask';
import pt from "date-fns/locale/pt-BR"; 
registerLocale("pt-BR", pt);

const TarifasCon = () => {
    const [ dataInicial, setDataInicial ] = useState(new Date);
    const [ listarAcomodacoes, setListarAcomodacoes ] = useState([]);
    const [ tarifaBalcao, setTarifaBalcao ] = useState([]);
    const [ tarifaPromocional, setTarifaPromocional ] = useState([]);
    const [ carregando, setCarregando ] = useState(true);
    const [ exibe, setExibe ] = useState(false);
    const [ cbxDetalhes, setCbxDetalhes ] = useState(false);
    
    function getListarAcomodacoes() {
        setCarregando(true);
        Api.get("tarifas/listaAcomodacoes").then(rps => {
            setListarAcomodacoes(rps.data.obj);
            setCarregando(false);
        })
    }

    function filtraTarifa() {
      setCarregando(true);
      
      var data = { dt_inicio : dataInicial }
        Api.post("tarifas/filtraTarifa", data).then(rps => {   
            if(rps.data.status==true){
                  getListarAcomodacoes();
                  setTarifaBalcao(rps.data.balcao);
                  setTarifaPromocional(rps.data.promocao);
                  setExibe(true);
                } 
                setCarregando(false);       
          })
    }
    
    return (
        <>
    {/*begin::Entry*/}
    <div className="d-flex flex-column-fluid">
        {/*begin::Container*/}
        <div className="container">

        <div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Consultar Tarifas</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
            </div>
            {/*end::Details*/}
          </div>
        </div>
        {/*begin::Entry*/}
        {/*begin card de pesquisa de vendas */}
        <div className="card card-custom">
          <div className="card-header">
            <h3 className="card-title">Período para consulta</h3>
          </div>
            
          <div className="card-body">
            <div className="row">
              <div className="col-md-2">
                <h5 className="card-title text-right">Data Inicial</h5>
              </div>
              <div className="col-md-6">
                <DatePicker 
                  onChange={setDataInicial}
                  isClearable
                  locale="pt-BR"
                  className="form-control"
                  placeholderText="dd/mm/aaaa" 
                  selected ={dataInicial}
                  dateFormat="dd/MM/y"
                  startDate={dataInicial}
                  customInput={<InputMask mask="99/99/9999" />}/>
                  
                  <button className="btn btn-primary ml-2" onClick={e=>{filtraTarifa()}}>Pesquisar</button>
                </div>
              <div className="col-md-2">
                <div class="checkbox-inline checkbox-t-5">
                  <label class="checkbox checkbox-square checkbox-primary">
                    <input type="checkbox" checked={cbxDetalhes} onChange={e=>{ setCbxDetalhes(e.target.checked)}} />
                    <span></span>Mostrar detalhes
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <br />

        {exibe == true && 
        <>
      <div className="card card-custom">
			<div className="card-header">
				<h3 className="card-title">Plano Tarifário - Balcão</h3>
			</div>
            <div className="card-body no-padding" style={{overflow: 'auto', display: 'inline-grid'}}>          
                    
                    <LoadingOverlay
                            active={carregando}
                            spinner
                            text='Carregando...'
                          >
                          <div className="row">
                            <div className="col-md-1">

                            </div>
                            {
                              tarifaBalcao.map( tarifa => {
                                return(
                                  <>
                                    {tarifa.idTipoAcomodacao == 1 &&
                                    <div className="col border text-center bg-secondary">
                                      {tarifa.dia_semana}<br />
                                      <b>{tarifa.dia_numero}</b><br />
                                      {tarifa.dia_mes}
                                    </div>
                                    }
                                  </>
                                )}
                              )
                            }
                          </div>
                    {
                      listarAcomodacoes.map( acomodacoes => {
                                             
                      return(
                        <>
                        <div className="row">
                        <div className="col-md-1 border text-right mt-2">
                          <label><b>{acomodacoes.nome}</b></label><br />
                          { cbxDetalhes === true && 
                          <>
                            <label className="text-info"><b>Adulto</b></label><br />
                            <label className="text-success"><b>Criança 12</b></label><br />
                            <label className="text-danger"><b>Crianca 14</b></label><br />
                            <label className="text-warning"><b>Pet</b></label>
                          </>
                          } 
                        </div>
                        {
                          
                          tarifaBalcao.map( tarifa => {
                                             
                      return(
                        <>
                            {tarifa.idTipoAcomodacao == acomodacoes.id && 
                            <div className="col border text-right mt-2">   
                              <label>{tarifa.valor}</label><br />
                              { cbxDetalhes === true && 
                              <>
                                <label className="text-info">{tarifa.valorAdExtra}</label><br />
                                <label className="text-success">{tarifa.valorChExtra}</label><br />
                                <label className="text-danger">{tarifa.valorTeExtra}</label><br />
                                <label className="text-warning">{tarifa.valorPet}</label>
                              </>
                              }
                            </div>
                          }
                        </>
                      )}
                          )}
                        </div>
                        </>
                        )}
                       
                  )}               
                  
                    </LoadingOverlay>
                    
                  </div>
        </div>
      <br />

      <div className="card card-custom">
			<div className="card-header">
				<h3 className="card-title">Plano Tarifário - Promocional</h3>
			</div>
            <div className="card-body no-padding" style={{overflow: 'auto', display: 'inline-grid'}}>          
                    
                    <LoadingOverlay
                            active={carregando}
                            spinner
                            text='Carregando...'
                          >
                          <div className="row">
                            <div className="col-md-1">

                            </div>
                            {
                              tarifaPromocional.map( tarifa => {
                                return(
                                  <>
                                    {tarifa.idTipoAcomodacao == 1 &&
                                    <div className="col border text-center bg-secondary">
                                      {tarifa.dia_semana}<br />
                                      <b>{tarifa.dia_numero}</b><br />
                                      {tarifa.dia_mes}
                                    </div>
                                    }
                                  </>
                                )}
                              )
                            }
                          </div>
                    {
                      listarAcomodacoes.map( acomodacoes => {
                                             
                      return(
                        <>
                        <div className="row">
                        <div className="col-md-1 border text-right mt-2">
                          <label><b>{acomodacoes.nome}</b></label><br />
                          { cbxDetalhes === true && 
                          <>
                            <label className="text-info"><b>Adulto</b></label><br />
                            <label className="text-success"><b>Criança 12</b></label><br />
                            <label className="text-danger"><b>Crianca 14</b></label><br />
                            <label className="text-warning"><b>Pet</b></label> 
                          </>
                        }
                        </div>
                        {
                          
                          tarifaPromocional.map( tarifa => {
                                             
                      return(
                        <>
                            {tarifa.idTipoAcomodacao == acomodacoes.id && 
                            <div className="col border text-right mt-2">
                            
                              <label>{tarifa.valor}</label><br />
                              { cbxDetalhes === true && 
                              <>
                                <label className="text-info">{tarifa.valorAdExtra}</label><br />
                                <label className="text-success">{tarifa.valorChExtra}</label><br />
                                <label className="text-danger">{tarifa.valorTeExtra}</label><br />
                                <label className="text-warning">{tarifa.valorPet}</label>
                              </>
                              }
                            </div>
                          }
                        </>
                      )}
                          )}
                        </div>
                        </>
                        )}
                       
                  )}
                
                  
                    </LoadingOverlay>
                    
                  </div>
        </div> 
        </>
}       
    </div>
    
</div>
        </>
    );

}

export default withRouter(TarifasCon);  