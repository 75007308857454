import React, { useState, useEffect } from 'react';
import Api from '../../../services/api';
import { withRouter } from "react-router";

import Swal from "sweetalert2";
import { useToasts } from 'react-toast-notifications';
import LoadingOverlay from 'react-loading-overlay';

import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import InputMask from 'react-input-mask';
import pt from "date-fns/locale/pt-BR"; 
registerLocale("pt-BR", pt);

const TarifasCad = () => {
    const { addToast } = useToasts();

    const [ dataInicial, setDataInicial ] = useState();
    const [ dataFinal, setDataFinal ] = useState();
    const [ tarifa, setTarifa ] = useState();
    const [ mudou , setMudou ] = useState(false);
    const [ listarAcomodacoes, setListarAcomodacoes ] = useState([]);
    const [ cbxDomingo, setCbxDomingo ] = useState(false);
    const [ cbxSegunda, setCbxSegunda ] = useState(false);
    const [ cbxTerca, setCbxTerca ] = useState(false);
    const [ cbxQuarta, setCbxQuarta ] = useState(false);
    const [ cbxQuinta, setCbxQuinta ] = useState(false);
    const [ cbxSexta, setCbxSexta ] = useState(false);
    const [ cbxSabado, setCbxSabado ] = useState(false);
    const [ carregando, setCarregando ] = useState(true);
    
    useEffect(() => {
        getListarAcomodacoes();
      }, []);

    function getListarAcomodacoes() {
        setCarregando(true);
        Api.get("tarifas/listaAcomodacoes").then(rps => {
            setListarAcomodacoes(rps.data.obj);
            setCarregando(false);
        })
    }

    function salvarTarifa() {

        var gravar = {
            apartamento : {valor: document.frm_tarifa.adultos2_1.value,
                adultoExtra: document.frm_tarifa.adultoExtra1.value,
                chdExtra: document.frm_tarifa.chdExtra1.value,
                teenExtra: document.frm_tarifa.teenExtra1.value,
                pet: document.frm_tarifa.pet1.value,
                estMinima: document.frm_tarifa.estMin1.value,
                entrada: document.frm_tarifa.resEntrada1.value,
                saida: document.frm_tarifa.resSaida1.value,
                vendas: document.frm_tarifa.vendas1.value,
            },
            suite : {valor: document.frm_tarifa.adultos2_2.value,
                adultoExtra: document.frm_tarifa.adultoExtra2.value,
                chdExtra: document.frm_tarifa.chdExtra2.value,
                teenExtra: document.frm_tarifa.teenExtra2.value,
                pet: document.frm_tarifa.pet2.value,
                estMinima: document.frm_tarifa.estMin2.value,
                entrada: document.frm_tarifa.resEntrada2.value,
                saida: document.frm_tarifa.resSaida2.value,
                vendas: document.frm_tarifa.vendas2.value,
            },
            luxo : {valor: document.frm_tarifa.adultos2_3.value,
                adultoExtra: document.frm_tarifa.adultoExtra3.value,
                chdExtra: document.frm_tarifa.chdExtra3.value,
                teenExtra: document.frm_tarifa.teenExtra3.value,
                pet: document.frm_tarifa.pet3.value,
                estMinima: document.frm_tarifa.estMin3.value,
                entrada: document.frm_tarifa.resEntrada3.value,
                saida: document.frm_tarifa.resSaida3.value,
                vendas: document.frm_tarifa.vendas3.value,
            },
            familia : {valor: document.frm_tarifa.adultos2_4.value,
                adultoExtra: document.frm_tarifa.adultoExtra4.value,
                chdExtra: document.frm_tarifa.chdExtra4.value,
                teenExtra: document.frm_tarifa.teenExtra4.value,
                pet: document.frm_tarifa.pet4.value,
                estMinima: document.frm_tarifa.estMin4.value,
                entrada: document.frm_tarifa.resEntrada4.value,
                saida: document.frm_tarifa.resSaida4.value,
                vendas: document.frm_tarifa.vendas4.value,
            },
            data_inicial : dataInicial,
            data_final : dataFinal,
            tarifa : tarifa,
            domingo : cbxDomingo,
            segunda : cbxSegunda,
            terca : cbxTerca,
            quarta : cbxQuarta,
            quinta : cbxQuinta,
            sexta : cbxSexta,
            sabado : cbxSabado
        }
        setCarregando(true);
        Api.post("tarifas/salvarTarifa", gravar).then(rps => {   
            if(rps.data.status==true){
                addToast(rps.data.mensagem, {
                        appearance: "success",
                        autoDismiss: true,
                        autoDismissTimeout: 2000
                  });
                } else {
                    Swal.fire({
                        title: "Atenção!",
                        icon: "error",
                        html: rps.data.erro,
                        showCloseButton: true,
                        showCancelButton: false,
                    });
                }
                setCarregando(false);       
          })
          //
    }

    function marcarTodos() {
        setCbxDomingo(true);
        setCbxSegunda(true);
        setCbxTerca(true);
        setCbxQuarta(true);
        setCbxQuinta(true);
        setCbxSexta(true);
        setCbxSabado(true);
    }
    
    function desmarcarTodos() {
        setCbxDomingo(false);
        setCbxSegunda(false);
        setCbxTerca(false);
        setCbxQuarta(false);
        setCbxQuinta(false);
        setCbxSexta(false);
        setCbxSabado(false);
    }

    function inverterTodos() {
        cbxDomingo ? setCbxDomingo(false) : setCbxDomingo(true);
        cbxSegunda ? setCbxSegunda(false) : setCbxSegunda(true);
        cbxTerca ? setCbxTerca(false) : setCbxTerca(true);
        cbxQuarta ? setCbxQuarta(false) : setCbxQuarta(true);
        cbxQuinta ? setCbxQuinta(false) : setCbxQuinta(true);
        cbxSexta ? setCbxSexta(false) : setCbxSexta(true);
        cbxSabado ? setCbxSabado(false) : setCbxSabado(true);
    }

    function calc(ac) {
        var valorbase = document.frm_tarifa.adultos2_1.value;
        if (ac == 1) {
            var valor2adl = document.frm_tarifa.adultos2_1.value;           

            //Formata o valor
            document.frm_tarifa.adultos2_1.value = (parseFloat(valor2adl).toFixed(2));

            //regra 1 - 1 adulto
            var regra = "valor2adl";
            var valorComRegra = eval(regra);
            document.frm_tarifa.adultos1_1.value = (parseFloat(valorComRegra).toFixed(2));
            
            //regra 2 - adulto extra
            var regra = "(valorbase/2)*0.75";
            var valorComRegra = eval(regra);        
            document.frm_tarifa.adultoExtra1.value = (parseFloat(valorComRegra).toFixed(2));
            
            //regra 3 - chd extra
            var regra = "valorbase*0.125";
            var valorComRegra = eval(regra);        
            document.frm_tarifa.chdExtra1.value = (parseFloat(valorComRegra).toFixed(2));
            
            //regra 4 - teen extra
            var regra = "valorbase*0.25";
            var valorComRegra = eval(regra);        
            document.frm_tarifa.teenExtra1.value = (parseFloat(valorComRegra).toFixed(2));

            //regra 5 - pet
            var regra = "(valorbase*0.125)/2";
            var valorComRegra = eval(regra);        
            document.frm_tarifa.pet1.value = (parseFloat(valorComRegra).toFixed(2));
        } else if (ac == 2) {
            var valor2adl = document.frm_tarifa.adultos2_2.value;

            //Formata o valor
            document.frm_tarifa.adultos2_2.value = (parseFloat(valor2adl).toFixed(2));
    
            //regra 1 - 1 adulto
            var regra = "valor2adl";
            var valorComRegra = eval(regra);
            document.frm_tarifa.adultos1_2.value = (parseFloat(valorComRegra).toFixed(2));
            
            //regra 2 - adulto extra
            var regra = "(valorbase/2)*0.75";
            var valorComRegra = eval(regra);        
            document.frm_tarifa.adultoExtra2.value = (parseFloat(valorComRegra).toFixed(2));
    
            //regra 3 - chd extra
            var regra = "valorbase*0.125";
            var valorComRegra = eval(regra);        
            document.frm_tarifa.chdExtra2.value = (parseFloat(valorComRegra).toFixed(2));
    
            //regra 4 - teen extra
            var regra = "valorbase*0.25";
            var valorComRegra = eval(regra);        
            document.frm_tarifa.teenExtra2.value = (parseFloat(valorComRegra).toFixed(2));
            
            //regra 5 - pet
            var regra = "(valorbase*0.125)/2";
            var valorComRegra = eval(regra);        
            document.frm_tarifa.pet2.value = (parseFloat(valorComRegra).toFixed(2));
        } else if (ac == 3) {
            var valor2adl = document.frm_tarifa.adultos2_3.value;

            //Formata o valor
            document.frm_tarifa.adultos2_3.value = (parseFloat(valor2adl).toFixed(2));
    
            //regra 1 - 1 adulto
            var regra = "valor2adl";
            var valorComRegra = eval(regra);
            document.frm_tarifa.adultos1_3.value = (parseFloat(valorComRegra).toFixed(2));
    
            //regra 2 - adulto extra
            var regra = "(valorbase/2)*0.75";
            var valorComRegra = eval(regra);        
            document.frm_tarifa.adultoExtra3.value = (parseFloat(valorComRegra).toFixed(2));
    
            //regra 3 - chd extra
            var regra = "valorbase*0.125";
            var valorComRegra = eval(regra);        
            document.frm_tarifa.chdExtra3.value = (parseFloat(valorComRegra).toFixed(2));
    
            //regra 4 - teen extra
            var regra = "valorbase*0.25";
            var valorComRegra = eval(regra);        
            document.frm_tarifa.teenExtra3.value = (parseFloat(valorComRegra).toFixed(2));

            //regra 5 - pet
            var regra = "(valorbase*0.125)/2";
            var valorComRegra = eval(regra);        
            document.frm_tarifa.pet3.value = (parseFloat(valorComRegra).toFixed(2));
        } else if (ac == 4) {
            var valor2adl = document.frm_tarifa.adultos2_4.value;

            //Formata o valor
            document.frm_tarifa.adultos2_4.value = (parseFloat(valor2adl).toFixed(2));

            //regra 1 - 1 adulto
            var regra = "valor2adl";
            var valorComRegra = eval(regra);
            document.frm_tarifa.adultos1_4.value = (parseFloat(valorComRegra).toFixed(2));
            //document.frm_tarifa.input1adl.closest('.form-group').removeClass('has-warning').addClass('has-success').find('a').removeClass('text-yellow').addClass('text-green');

            //regra 2 - adulto extra
            var regra = "(valorbase/2)*0.75";
            var valorComRegra = eval(regra);        
            document.frm_tarifa.adultoExtra4.value = (parseFloat(valorComRegra).toFixed(2));

            //regra 3 - chd extra
            var regra = "valorbase*0.125";
            var valorComRegra = eval(regra);        
            document.frm_tarifa.chdExtra4.value = (parseFloat(valorComRegra).toFixed(2));

            //regra 4 - teen extra
            var regra = "valorbase*0.25";
            var valorComRegra = eval(regra);        
            document.frm_tarifa.teenExtra4.value = (parseFloat(valorComRegra).toFixed(2));
            
            //regra 5 - pet
            var regra = "(valorbase*0.125)/2";
            var valorComRegra = eval(regra);        
            document.frm_tarifa.pet4.value = (parseFloat(valorComRegra).toFixed(2));
        }
    }
    
    return (
        <>
    {/*begin::Entry*/}
    <div className="d-flex flex-column-fluid">
        {/*begin::Container*/}
        <div className="container">

        <div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Cadastrar Tarifas</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
            </div>
            {/*end::Details*/}
          </div>
        </div>
        {/*begin::Entry*/}
        {/*begin card de pesquisa de vendas */}
        <div className="card card-custom">
			<div className="card-header">
				<h3 className="card-title">Período para atualização</h3>
			</div>
            
            <div className="card-body">
                <div className="row justify-content-md-center">
                    <div className="col text-center">
                    <h5>Data Inicial</h5>
                    <div>
                    <DatePicker 
                        name="dt_inicio"
                        onChange={setDataInicial}
                        isClearable
                        selectsStart
                        locale="pt-BR"
                        className="form-control"
                        placeholderText="dd/mm/aaaa" 
                        selected ={dataInicial}
                        dateFormat="dd/MM/y"
                        startDate={dataInicial}
                        endDate={dataFinal}
                        customInput={
                          <InputMask mask="99/99/9999" />}/>
                </div>
            </div>
                <div className="col text-center">
                    <h5>Data Final</h5>
                    <div>
                    <DatePicker 
                        name="dt_final"
                        onChange={setDataFinal}
                        isClearable
                        locale="pt-BR"
                        className="form-control"
                        placeholderText="dd/mm/aaaa" 
                        selected ={dataFinal}
                        dateFormat="dd/MM/y"
                        selectsEnd
                        startDate={dataInicial}
                        endDate={dataFinal}
                        minDate={dataInicial} 
                        customInput={
                          <InputMask mask="99/99/9999" />}/>
                </div>
            </div>
            <div className="col-md-6 text-center">
                <br />
                <div class="checkbox-inline checkbox-t-5">
                    <label class="checkbox checkbox-square checkbox-primary">
                        <input type="checkbox" checked={cbxDomingo} onChange={e=>{ setCbxDomingo(e.target.checked)}} />
                        <span></span>Domingo
                    </label>
                    <label class="checkbox checkbox-square checkbox-primary">
                        <input type="checkbox" checked={cbxSegunda} onChange={e=>{ setCbxSegunda(e.target.checked)}} />
                        <span></span>Segunda
                    </label>
                    <label class="checkbox checkbox-square checkbox-primary">
                        <input type="checkbox" checked={cbxTerca} onChange={e=>{ setCbxTerca(e.target.checked)}} />
                        <span></span>Terça
                    </label>
                    <label class="checkbox checkbox-square checkbox-primary">
                        <input type="checkbox" checked={cbxQuarta} onChange={e=>{ setCbxQuarta(e.target.checked)}} />
                        <span></span>Quarta
                    </label>
                    <label class="checkbox checkbox-square checkbox-primary">
                        <input type="checkbox" checked={cbxQuinta} onChange={e=>{ setCbxQuinta(e.target.checked)}} />
                        <span></span>Quinta
                    </label>
                    <label class="checkbox checkbox-square checkbox-primary">
                        <input type="checkbox" checked={cbxSexta} onChange={e=>{ setCbxSexta(e.target.checked)}} />
                        <span></span>Sexta
                    </label>
                    <label class="checkbox checkbox-square checkbox-primary">
                        <input type="checkbox" checked={cbxSabado} onChange={e=>{ setCbxSabado(e.target.checked)}} />
                        <span></span>Sábado
                    </label>
                </div>
            
            </div>
            <div className="col-md-3 text-center">
                <br />
                <button className="btn btn-primary" onClick={e=>{marcarTodos()}}>Marcar Todos</button>&nbsp;
                <button className="btn btn-primary" onClick={e=>{desmarcarTodos()}}>Desmarcar</button>&nbsp;
                <button className="btn btn-primary" onClick={e=>{inverterTodos()}}>Inverter</button>
            </div>
        </div>
        </div>
        </div>
        
        <br />

        <div className="card card-custom">
			<div className="card-header">
                <div className="row mt-5">
                <div className="col-md-3">
                    <h3 className="card-title">Tarifa</h3>
                </div>
				 <div className="col-md-9">              
                    <select className="form-control" value={tarifa} onChange={(e) => {setTarifa(e.target.value) }}>
                        <option value="1" selected>Balcão</option>
                        <option value="2">Promocional</option>
                    </select>
                </div>
                </div>
            </div>
            <LoadingOverlay
                active={carregando}
                spinner
                text='Carregando...'>
            <div className="card-body">
            <form name="frm_tarifa">
            {
                        listarAcomodacoes.map( lista => {

                            return(
                                <>    

            
            <div className="row" data-idclasse="{{$classeAcomodacao->id}}" data-nomeclasse="{{$classeAcomodacao->nome}}">
                <div className="col">
                    <div className="form-group">
                        <h5>{lista.nome}</h5>
                    </div>
                </div>
                <div className="col">
                    <div className="form-group has-warning">
                    <label>1 adulto
                        <a className="text-yellow" data-toggle="tooltip" data-placement="top" title="Igual a 2 adultos, preenchimento opcional">
                            <i className="fa fa-fw fa-exclamation-triangle" />
                        </a>
                    </label>
                        <div className="input-group ">
                            <input type="text" className="form-control valor-com-regra" name={"adultos1_"+lista.id} placeholder="2adl" disabled required />
                        </div>
                    </div>
                </div>
                <div className="col-md-2">                                
                    <div className="form-group">
                    <label>2 adultos 
                        <a className="text-aqua" data-toggle="tooltip" data-placement="top" title="Este valor é usado como base para calcular os demais.">
                        <i className="fa fa-fw fa-info-circle" />
                        </a>
                    </label>
                        <div className="input-group ">
                            <input type="text" className="form-control" name={"adultos2_"+lista.id} onChange={(e) => { setMudou(true);  }} required />
                            <div class="input-group-append">
								<button class="btn btn-primary" type="button" onClick={e=> { calc(lista.id) }} >Ok</button>
							</div>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="form-group has-warning">
                        <label>adulto ex
                            <a className="text-yellow" data-toggle="tooltip" data-placement="top" title="37,5% de 2 adultos, preenchimento opcional">
                            <i className="fa fa-fw fa-exclamation-triangle" />
                            </a>
                        </label>
                        <div className="input-group ">
                            <input type="text" className="form-control valor-com-regra" name={"adultoExtra"+lista.id} placeholder="=2adl*0.375" disabled required />
                        </div>
                    </div>
                </div>
                <div className="col">
                  <div className="form-group has-warning">
                        <label>
                            chd extra
                            <a className="text-yellow" data-toggle="tooltip" data-placement="top" title="12,5% de 2 adultos, preenchimento opcional">
                            <i className="fa fa-fw fa-exclamation-triangle" />
                            </a>
                        </label>
                        <div className="input-group ">
                            <input type="text" className="form-control valor-com-regra" name={"chdExtra"+lista.id} placeholder="=2adl*0.125" disabled required />
                        </div>
                    </div>
                </div>
                <div className="col">
                  <div className="form-group has-warning">
                        <label>
                            teen extra
                            <a className="text-yellow" data-toggle="tooltip" data-placement="top" title="25% de 2 adultos, preenchimento opcional">
                            <i className="fa fa-fw fa-exclamation-triangle" />
                            </a>
                        </label>
                        <div className="input-group ">
                            <input type="text" className="form-control valor-com-regra" name={"teenExtra"+lista.id} placeholder="=2adl*0.25" disabled required />
                        </div>
                    </div>
                </div>
                <div className="col">
                  <div className="form-group has-warning">
                        <label>
                            Pet
                            <a className="text-yellow" data-toggle="tooltip" data-placement="top" title="6,25% de 2 adultos, preenchimento opcional">
                            <i className="fa fa-fw fa-exclamation-triangle" />
                            </a>
                        </label>
                        
                        <div className="input-group">
                            <input type="text" className="form-control valor-com-regra" name={"pet"+lista.id} placeholder="=2adl*0.0625" disabled required />
                        </div>
                    </div>
                </div>
                <div className="col">
                  <div className="form-group">
                        <label>Est. Mínima</label>
                        <div className="input-group">
                            <input type="number" min={1} className="form-control" name={"estMin"+lista.id} required />
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="form-group">
                        <label>Entrada</label>
                        <div className="input-group">
                            <select className="form-control" name={"resEntrada"+lista.id}>
                            <option />
                            <option value="N">Aberta</option>
                            <option value="S">Fechada</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="col">
                  <div className="form-group">
                        <label>Saída</label>
                        <div className="input-group">
                            <select className="form-control" name={"resSaida"+lista.id}>
                            <option />
                            <option value="N">Aberta</option>
                            <option value="S">Fechada</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="col">
                  <div className="form-group">
                        <label>Vendas</label>
                        <div className="input-group">
                            <select className="form-control" name={"vendas"+lista.id}>
                            <option />
                            <option value="S">Abrir</option>
                            <option value="N">Fechar</option>
                            </select>
                        </div>
                    </div>
                </div>

                </div>  
                </>
                            )
                        })
                    }
                    </form>
            </div>
            </LoadingOverlay>
        </div>
                    
                             
        
        
        
      <div class="botao-salvar-alteracoes">
            <button disabled={!mudou} type="button" class="btn btn-success btn-lg btn-block" onClick={e=>{salvarTarifa()}}>Salvar</button>
        </div>
    </div>
    
</div>
        </>
    );

}

export default withRouter(TarifasCad);  