import React, { useState, useEffect } from 'react';
import Api from '../../services/api';
import { withRouter } from "react-router";
import Header from '../../layout/Header';

import Swal from "sweetalert2";
import { useToasts } from 'react-toast-notifications';
import { Modal, Button, Row } from "react-bootstrap";

import DataTable from 'react-data-table-component';
import LoadingOverlay from 'react-loading-overlay';

import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import InputMask from 'react-input-mask';
import pt from "date-fns/locale/pt-BR"; 
registerLocale("pt-BR", pt);

const Oportunidades = () => {

  const { addToast } = useToasts();
  const [ oportunidades, setOportunidades ] = useState([]);
  const [ programacao, setProgramacao ] = useState([]);
  const [ programacoes, setProgramacoes ] = useState([]);
  const [ diaria, setDiaria ] = useState();
  const [ nome, setNome ] = useState();
  const [ email, setEmail ] = useState();
  const [ dataEnvioInicial, setDataEnvioInicial ] = useState();
  const [ dataEnvioFinal, setDataEnvioFinal ] = useState(new Date);
  const [ dataPeriodoInicial, setDataPeriodoInicial ] = useState(new Date);
  const [ dataPeriodoFinal, setDataPeriodoFinal ] = useState();
  const [ cotacao, setCotacao ] = useState([]);
  const [ pessoa, setPessoa ] = useState([]);
  const [ exibir, setExibir ] = useState(false);
  const [ dataCheckIn, setDataCheckIn ] = useState();
  const [ dataCheckOut, setDataCheckOut ] = useState();
  const [ qtdePessoas, setQtdePessoas ] = useState(0);
  const [ emailEncaminhar, setEmailEncaminhar ] = useState();
  const [ modal, setModal ] = useState(false);
    
  const [ caProcessa, setCaProcessa ] = useState(false);

  const data = oportunidades;
  const columns = [
    {
      name: <th>Data</th>,
      selector: 'dataEnvio',
      sortable: true,
      width: '20%',
    },
    {
      name: <th>Origem</th>,
      selector: 'origem',
      width: '15%',
      sortable: true,
    },
    {
      name: <th>Período</th>,
      selector: 'prog',
      width: '35%',
      sortable: true,
    },
    {
      name: <th>Nome</th>,
      selector: 'hosp',
      width: '20%',
      sortable: true,
     },
    {
      name: <th>Diárias</th>,
      selector: 'diarias',
      width: '10%',
      sortable: true,
      right: true,
    },
  ];

  useEffect(() => {
    getListar();
  }, []);

  function getListar() {
    Api.get("oportunidades/listaoportunidades").then(rps => {    
      setOportunidades(rps.data.obj);
      setProgramacoes(rps.data.prog);
    })
  }

  function encaminharEmail(){
    setModal(true);
  }

  function fecharModal(){
    setModal(false);
  }

  function apagarCotacao($id, $acao) {
    var data = {
      idCotacao: $id,
      apagadaMotivo: $acao,
      apagada: 'S',
      usuario: localStorage.getItem('id_usuario')
    }
   
    Api.post("cotacoes/cotacaoApagar", data).then(rps => {
      Swal.fire({
        title: "Informação!",
        icon: "info",
        html: rps.data.mensagem,
        showCloseButton: true,
        showCancelButton: false,
    });
    })
    getListar();
  }

  function pesquisar() {
    setExibir(false);
      var data = {
        dtInicial: dataEnvioInicial,
        dtFinal: dataEnvioFinal,
        checkin: dataPeriodoInicial,
        checkout: dataPeriodoFinal,
        diaria: diaria,
        nome: nome,
        email: email,
        programacao: programacao,
      }

      Api.post("oportunidades/listaoportunidades", data).then(rps => {
        setOportunidades();
        if(rps.data.status==false){
          Swal.fire({
            title: "Erro!",
            icon: "error",
            html: rps.data.mensagem,
            showCloseButton: true,
            showCancelButton: false,
          });
        } else {
          setOportunidades(rps.data.obj);
          setProgramacoes(rps.data.prog);
        }
      setCaProcessa(false); 
      })
  }

  function iniciaLigacao() {
    // data e hora atual a região
    let data = new Date();
    // Fri Nov 16 2018 18:36:40 GMT-0200 (Horário de Verão de Brasília)

    let data2 = new Date(data.valueOf() - data.getTimezoneOffset() * 60000);
    var dataBase = data2.toISOString().replace(/\.\d{3}Z$/, '');
    
    sessionStorage.setItem('inicioLigacao', dataBase);
  }

  function abrirCotacao(id, idPessoa) {  
    var data = {
      cotacao: id,
      pessoa: idPessoa
    } 
    sessionStorage.setItem('cotacao', id);

    setCaProcessa(true);
    Api.post('cotacoes/por_id', data).then(rps => {
      setCotacao(rps.data.cot);
      setQtdePessoas(parseInt(rps.data.cot.adultos)+parseInt(rps.data.cot.chd_12)+parseInt(rps.data.cot.chd_14));
      setDataCheckIn(new Date(rps.data.cot.checkin));
      setDataCheckOut(new Date(rps.data.cot.checkout));
    })

    Api.post('pessoas/por_id', data).then(rps => {
      setPessoa(rps.data.obj);

      setCaProcessa(false);
    })
    setExibir(true);
  }

  function atualizaCalendario(idProg) {
        
    programacoes.forEach(programacao => {
      if (programacao.id === idProg) {
        //Engenharia Alternativa " + ' 12:00:00'" para a data não se perder
        setDataCheckIn(new Date(programacao.dtInicio + ' 12:00:00'));
        setDataCheckOut(new Date(programacao.dtFim + ' 12:00:00'));
      }
    });      
  }

  async function enviar() {
    cotacao.checkin = dataCheckIn;
    cotacao.checkout = dataCheckOut;

    const response = await Api.post('cotacoes/simular', {
      enviarEmail: "Sim",
      atualizaCotacao: "Sim",
      cotacao: cotacao.id,
      pacote: cotacao.idProgramacao,
      dtInicial: cotacao.checkin,
      dtFinal: cotacao.checkout,
      adulto: cotacao.adultos,
      crianca12: cotacao.chd_12,
      crianca14: cotacao.chd_14,
      pet: cotacao.pet,
      nome: pessoa.nome,
      email: pessoa.email,
      diaria: cotacao.diarias,
      resposta: cotacao.resposta,
      enviarApartamento: cotacao.enviarApartamento,
      enviarSuite: cotacao.enviarSuite,
      enviarLuxo: cotacao.enviarLuxo,
      enviarFamilia: cotacao.enviarFamilia,
      usuario: localStorage.getItem('id_usuario')
    });

    if(response.data.status==true){
      addToast(response.data.mensagem, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 2000
      });
        setCotacao('');
        setPessoa('');
        setExibir(false);
    } else {
      Swal.fire({
        title: "Erro!",
        icon: "error",
        html: response.data.erro,
        showCloseButton: true,
        showCancelButton: false,
      });
    }
    pesquisar();   
  }

  async function encaminhar() {
    cotacao.checkin = dataCheckIn;
    cotacao.checkout = dataCheckOut;

    const response = await Api.post('cotacoes/simular', {
      enviarEmail: "Sim",
      atualizaCotacao: "Sim",
      cotacao: cotacao.id,
      pacote: cotacao.idProgramacao,
      dtInicial: cotacao.checkin,
      dtFinal: cotacao.checkout,
      adulto: cotacao.adultos,
      crianca12: cotacao.chd_12,
      crianca14: cotacao.chd_14,
      pet: cotacao.pet,
      nome: pessoa.nome,
      email: emailEncaminhar,
      diaria: cotacao.diarias,
      resposta: cotacao.resposta,
      enviarApartamento: cotacao.enviarApartamento,
      enviarSuite: cotacao.enviarSuite,
      enviarLuxo: cotacao.enviarLuxo,
      enviarFamilia: cotacao.enviarFamilia,
      usuario: localStorage.getItem('id_usuario')
    });

    if(response.data.status==true){
      addToast(response.data.mensagem, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 2000
      });
        setCotacao('');
        setPessoa('');
        setExibir(false);
    } else {
      Swal.fire({
        title: "Erro!",
        icon: "error",
        html: response.data.erro,
        showCloseButton: true,
        showCancelButton: false,
      });
    }
    pesquisar();
    setModal(false);   
  }
  
    return (
        <>
<div>

<div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Lista de Oportunidades</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center">

            </div>
            {/*end::Toolbar*/}
          </div>
        </div>


        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <div class="card card-custom gutter-b">
                  <div class="card-body">
                    <div className="row">
                      <div className="col-md-3 form-group">
                        <label>Data do envio de</label>
                          <DatePicker 
                            name="dt_inicio"
                            onChange={setDataEnvioInicial}
                            isClearable
                            selectsStart
                            locale="pt-BR"
                            className="form-control"
                            placeholderText="dd/mm/aaaa" 
                            selected ={dataEnvioInicial}
                            dateFormat="dd/MM/y"
                            startDate={dataEnvioInicial}
                            endDate={dataEnvioFinal}
                            customInput={
                              <InputMask mask="99/99/9999" />}/>      
                      </div>
                      <div className="col-md-3 form-group">
                        <label>Data do envio até</label>
                          <DatePicker 
                            name="dt_final"
                            onChange={setDataEnvioFinal}
                            isClearable
                            locale="pt-BR"
                            className="form-control"
                            placeholderText="dd/mm/aaaa" 
                            selected ={dataEnvioFinal}
                            dateFormat="dd/MM/y"
                            selectsEnd
                            startDate={dataEnvioInicial}
                            endDate={dataEnvioFinal}
                            minDate={dataEnvioInicial} 
                            customInput={
                              <InputMask mask="99/99/9999" />}/>
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Diárias</label>
                          <input className="form-control" 
                                 value={diaria} 
                                 onChange={e => {setDiaria(e.target.value)}} />
                      </div>
                    </div>
                    
                    <div className="row">
                      <div className="col-md-3 form-group">
                        <label>Período de</label>
                          <DatePicker 
                            name="dt_inicio"
                            onChange={setDataPeriodoInicial}
                            isClearable
                            selectsStart
                            locale="pt-BR"
                            className="form-control"
                            placeholderText="dd/mm/aaaa" 
                            selected ={dataPeriodoInicial}
                            dateFormat="dd/MM/y"
                            startDate={dataPeriodoInicial}
                            endDate={dataPeriodoFinal}
                            customInput={
                              <InputMask mask="99/99/9999" />}/>      
                      </div>
                      <div className="col-md-3 form-group">
                        <label>Período até</label>
                          <DatePicker 
                            name="dt_final"
                            onChange={setDataPeriodoFinal}
                            isClearable
                            locale="pt-BR"
                            className="form-control"
                            placeholderText="dd/mm/aaaa" 
                            selected ={dataPeriodoFinal}
                            dateFormat="dd/MM/y"
                            selectsEnd
                            startDate={dataPeriodoInicial}
                            endDate={dataPeriodoFinal}
                            minDate={dataPeriodoInicial} 
                            customInput={
                              <InputMask mask="99/99/9999" />}/>
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Nome</label>
                          <input className="form-control" 
                                 placeholder="Pesquise pelo nome ou por parte do nome"
                                 value={nome} 
                                 onChange={e => {setNome(e.target.value)}} />
                      </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label>Pacote</label>
                            <select className="form-control" value={programacao} onChange={e => {setProgramacao(e.target.value)}}>
                                <option value="">Selecione...</option>
                                {programacoes.map((item) =>{ return(
                                <option value={item.id}>{item.nome}</option>
                            )})}
                            </select>
                        </div>
                        
                        <div className="col-md-6 form-group">
                            <label>E-mail</label>
                            <input className="form-control" type="email" value={email} onChange={(e)=> setEmail(e.target.value)}/>
                        </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12 text-right">
                        <button className="btn btn-primary" onClick={e=>{pesquisar()}}><i class="flaticon2-search-1" />Pesquisar</button>
                      </div>
                    </div>
                    
                    <LoadingOverlay
                            active={caProcessa}
                            spinner
                            text='Carregando...'
                          > 
                    <DataTable
                      title="Lista das Pessoas Interessadass"
                      columns={columns}
                      data={data}
                      onRowClicked={data => { abrirCotacao(data.id, data.idPessoa)}}
                      striped="true"
                      pagination="true"
                    />
                    </LoadingOverlay>

                  </div>
                </div>
              </div>    

              {exibir === true &&              
              <div className="col-md-4">
                <LoadingOverlay
                            active={caProcessa}
                            spinner
                            text='Carregando...'
                          > 
                <div class="card card-custom gutter-b">
                  <div class="card-header">
										<div class="card-title">
											<span class="card-icon">
												<i class="flaticon-users-1 text-danger"></i>
											</span>
											<h3 class="card-label">{pessoa.nome}</h3><br />
                      <small>{pessoa.cidade}</small>
										</div>
                    <div class="card-toolbar">
											{pessoa.fidelidade_cat === null &&
                        <label className="label label-lg label-inline font-weight-bolder label-rounded label-danger text-center pt-6 pb-6"><b>Sem<br />Reservas</b></label>
                      }
										</div>                    
                  </div>
                  <div class="card-body">
                    <i class="fas fa-envelope icon-md text-info" /> <b className="ml-2"> {pessoa.email}</b><br />
                    <i class="fas fa-phone-alt icon-md text-info" />
                      <b>
                        {pessoa.telefone1 !== null && <label className="bg-gray-100 pt-2 pl-2 pr-2 pb-2 ml-2">{pessoa.telefone1}</label>}
                        {pessoa.telefone2 !== null && <label className="bg-gray-100 pt-2 pl-2 pr-2 pb-2 ml-2">{pessoa.telefone2}</label>}
                        {pessoa.celular !== null && <label className="bg-gray-100 pt-2 pl-2 pr-2 pb-2 ml-2">{pessoa.celular}</label>}
                      </b>
                    <hr />
                    <div className="row">
                      <div className="col text-right">
                        <a data-toggle="modal" data-target="#kt_chat_modalR" className="btn btn-success btn-sm" onClick={e=>{iniciaLigacao()}}><i class="fas fa-phone-alt icon-sm" />Ligar</a>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card card-custom gutter-b">
                  <div class="card-header">
										<div class="card-title">
											<span class="card-icon">
												<i class="fas fa-bell text-success"></i>
											</span>
											<h3 class="card-label">Oportunidade - Site Lazer</h3>
										</div>                    
                  </div>
                  <div class="card-body">
                    <label>Site Lazer em <b>{cotacao.dataEnvio}</b></label><br />
                    <label>Checkin <b>{cotacao.checkinF}</b> e Checkout <b>{cotacao.checkoutF}</b> ({cotacao.diarias} diária(s))</label><br />
                    <label><b>{cotacao.adultos}</b> adulto(s), <b>{cotacao.chd_12}</b> criança(s) até 12 anos, <b>{cotacao.chd_14}</b> criança(s) de 13/14 anos</label><br />
                    {cotacao?.observacao !== null && 
                    <div className="bg-gray-100 pt-2 pl-2 pr-2 pb-2">
                      <label><b>Mensagem do Cliente:</b><br />{cotacao.observacao}</label>
                    </div>
                    }
                    <div className="row mt-2">
                      <div className="col-md-12">
                        <select className="form-control" value={cotacao.idProgramacao} 
                          onChange={(e) =>{atualizaCalendario(e.target.value); 
                            setCotacao({...cotacao, idProgramacao: e.target.value})}}>
                        <option value="">Selecione...</option>
                          {programacoes.map((item) =>{ return(
                            <option value={item.id}>{item.nome}</option>
                          )})}
                        </select>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-6">
                        <DatePicker 
                          name="dt_inicio"
                          onChange={setDataCheckIn}
                          isClearable
                          selectsStart
                          locale="pt-BR"
                          className="form-control"
                          placeholderText="dd/mm/aaaa" 
                          selected ={dataCheckIn}
                          dateFormat="dd/MM/y"
                          startDate={dataCheckIn}
                          endDate={dataCheckOut}
                          customInput={
                           <InputMask mask="99/99/9999" />}
                        />
                      </div>
                      <div className="col-md-6">
                        <DatePicker 
                          name="dt_final"
                          onChange={setDataCheckOut}
                          isClearable
                          locale="pt-BR"
                          className="form-control"
                          placeholderText="dd/mm/aaaa" 
                          selected ={dataCheckOut}
                          dateFormat="dd/MM/y"
                          selectsEnd
                          startDate={dataCheckIn}
                          endDate={dataCheckOut}
                          minDate={dataCheckIn} 
                          customInput={
                            <InputMask mask="99/99/9999" />}
                        />
                      </div>
                    </div>
                    <div className="row mt-2">
                    <div className="col-md-3 form-group">
                				<div className="bg-secondary text-center rounded-top">
														<label><b>A</b></label>
													</div>
														<input type="number" min="1" max="10" className="form-control" 
                              value={cotacao?.adultos} 
                              onChange={(e) =>{
                                setQtdePessoas(parseInt(e.target.value)+parseInt(cotacao?.chd_12)+parseInt(cotacao?.chd_14));
                                setCotacao({...cotacao, adultos: e.target.value})}}/>
                      </div>
                      <div className="col-md-3 form-group">
                				<div className="bg-secondary text-center rounded-top">
														<label><b>C12</b></label>
													</div>
                            <input type="number" min="0" max="9" className="form-control"
                              value={cotacao?.chd_12} 
                              onChange={(e) =>{
                                setQtdePessoas(parseInt(cotacao?.adultos)+parseInt(e.target.value)+parseInt(cotacao?.chd_14));
                                setCotacao({...cotacao, chd_12: e.target.value})}} />	
                      </div>
                      <div className="col-md-3 form-group">
                				<div className="bg-secondary text-center rounded-top">
														<label><b>C14</b></label>
													</div>
														<input type="number" min="0" max="9" className="form-control" 
                              value={cotacao?.chd_14} 
                              onChange={(e) =>{
                                setQtdePessoas(parseInt(cotacao?.adultos)+parseInt(cotacao?.chd_12)+parseInt(e.target.value));
                                setCotacao({...cotacao, chd_14: e.target.value})}}/>
											</div>
                      <div className="col-md-3 form-group">
                				<div className="bg-secondary text-center rounded-top">
														<label><b>Pet</b></label>
													</div>
														<input type="number" min="0" max="2" className="form-control" 
                              value={cotacao?.pet} 
                              onChange={(e) =>{setCotacao({...cotacao, pet: e.target.value})}}/>				
											</div>
                    </div>

                    <div className="row">
                      { (qtdePessoas < 4) &&  <>                      
                      <div className="col">
                        <label class="checkbox checkbox-square checkbox-primary">
                          <input type="checkbox" checked={cotacao?.enviarApartamento} onChange={e=>{ setCotacao({...cotacao, enviarApartamento: e.target.checked}) }} />
                          <span></span>Apartamento
                        </label>
                      </div>
                      </> }
                      { (qtdePessoas < 6) && <>
                      <div className="col">
                        <label class="checkbox checkbox-square checkbox-primary">
                          <input type="checkbox" checked={cotacao?.enviarSuite} onChange={e=>{ setCotacao({...cotacao, enviarSuite: e.target.checked}) }} />
                          <span></span>Suíte
                        </label>
                      </div>
                      </> }
                      { (qtdePessoas < 6) && <>
                      <div className="col">
                        <label class="checkbox checkbox-square checkbox-primary">
                          <input type="checkbox" checked={cotacao?.enviarLuxo} onChange={e=>{ setCotacao({...cotacao, enviarLuxo: e.target.checked}) }} />
                          <span></span>Suíte Max
                        </label>
                      </div>
                      </> }
                      { (qtdePessoas < 7) && <>
                      <div className="col">
                        <label class="checkbox checkbox-square checkbox-primary">
                          <input type="checkbox" checked={cotacao?.enviarFamilia} onChange={e=>{ setCotacao({...cotacao, enviarFamilia: e.target.checked}) }} />
                          <span></span>Suíte Família
                        </label>
                      </div>
                      </> }
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <textarea className="form-control" onChange={(e) =>{setCotacao({...cotacao, resposta: e.target.value})}}>Resposta para a solicitação</textarea>
                      </div>
                    </div>

                    <div className="row mt-2">
                      <div className="col-md-2">
                        <div class="btn-group">
                          <button className="btn btn-danger btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="flaticon-delete-1" />Apagar</button>
                          <div class="dropdown-menu">
                            <a class="dropdown-item" onClick={e => { apagarCotacao(cotacao.id, "Duplicada") }}>Duplicada</a>
                            <a class="dropdown-item" onClick={e => { apagarCotacao(cotacao.id, "Inválida") }}>Inválida</a>
                            <a class="dropdown-item" onClick={e => { apagarCotacao(cotacao.id, "Spam/Teste") }}>Spam/Teste  </a>
                          </div>
                        </div>
                      </div>
                      <div className="col text-right">
                        <button className="btn btn-warning btn-sm" onClick={e=>{encaminharEmail(true)}}><i class="flaticon-reply" />Encaminhar</button>
                        <button className="btn btn-success btn-sm ml-2" onClick={e=>{enviar()}}><i class="flaticon2-paperplane" />Enviar Cotação</button>
                      </div>
                    </div>
                  </div>
                </div>
                </LoadingOverlay>
              </div>
              }

            </div>
          </div>
        

        
                  
                </div>
              </div>  

      <Modal size={'sm'} show={modal} onHide={() => fecharModal()}>
      <Modal.Header className="bg-warning">
      <Modal.Title>Encaminhar E-mail de Cotação</Modal.Title>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => {fecharModal()}}>
            <i aria-hidden="true" className="ki ki-close" />
          </button>
      </Modal.Header>
      <div className="row mt-5 ml-5 mr-5">
        <div className="form-group col-md-12">
          <label>E-mail</label>
          <input type="text" value={emailEncaminhar} onChange={e => {setEmailEncaminhar(e.target.value)}} className="form-control" />
        </div>        
      </div>    
      
      <Modal.Footer>
        <button type="button" onClick={e => { fecharModal(); }} className="btn btn-secondary font-weight-bold" data-dismiss="modal"><i className="fas fa-door-open" />Fechar</button>
        <button type="button" onClick={e => { encaminhar(); }} className="btn btn-warning font-weight-bold"><i class="flaticon-reply" />Encaminhar</button>
      </Modal.Footer>
    </Modal>
      
        </>
    );

}

export default withRouter(Oportunidades);                  