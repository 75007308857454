import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';

import Swal from "sweetalert2";
import { useToasts } from 'react-toast-notifications';
import { Modal } from "react-bootstrap";

//Socket.io
import io from 'socket.io-client';

import Api from '../../services/api';

export default function Header({ storeDetails, testeLucas }) {

  const { addToast } = useToasts();
  const [ qtdeCotacoes, setQtdeCotacoes ] = useState();
  const [ qtdeCotacoesEventos, setQtdeCotacoesEventos ] = useState();
  const [ programacoesTel, setProgramacoesTel ] = useState([]);
  const [ conheceusTel, setConheceusTel ] = useState([]);
  const [ nomeTel, setNomeTel ] = useState();
  const [ conheceuTel, setConheceuTel ] = useState();
  const [ cidadeTel, setCidadeTel ] = useState();
  const [ pacoteTel, setPacoteTel ] = useState();
  const [ observacoesTel, setObservacoesTel ] = useState();
  const [ dataTel, setDataTel ] = useState();
  const [ efetuada, setEfetuada ] = useState();
  const [ modalTelefone, setModalTelefone ] = useState(false);
  
  useEffect(() => {
    getQtdeCotacoes();
    getQtdeCotacoesEventos();
  }, []);

  function getListarTel() {
    Api.get("cotacoes/listaprogramacoes").then(rps => {    
      setProgramacoesTel(rps.data.prog);
    })
  }

  function getConheceuTel() {
    Api.get("conheceu/listar").then(rps => {    
      setConheceusTel(rps.data.obj);
    })
  }

  function getQtdeCotacoes(){
    Api.get("oportunidades/listaquantidade").then(rps => {
      
      setQtdeCotacoes(rps.data.obj);
    })
  }

  function getQtdeCotacoesEventos(){
    Api.get("oportunidadeseventos/listaquantidade").then(rps => {
      
      setQtdeCotacoesEventos(rps.data.obj);
    })
  }

  function abrirModalTelefone(){
    setModalTelefone(true);
    getListarTel();
    getConheceuTel();
    
    // data e hora atual a região
    let data = new Date();
    // Fri Nov 16 2018 18:36:40 GMT-0200 (Horário de Verão de Brasília)

    let data2 = new Date(data.valueOf() - data.getTimezoneOffset() * 60000);
    var dataBase = data2.toISOString().replace(/\.\d{3}Z$/, '');
    
    setDataTel(dataBase);
  }

  function salvarLigacao() {
    var data = {
      nome: nomeTel,
      conheceu: conheceuTel,
      cidade: cidadeTel,
      pacote: pacoteTel,
      observacoes: observacoesTel,
      idUsuario: localStorage.getItem('id_usuario'),
      inicio: dataTel 
    }

    Api.post("ligacoes/salvarRecebidas", data).then(rps => {
      if(rps.data.status===true){
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
          setNomeTel('');
          setConheceuTel('');
          setCidadeTel('');
          setPacoteTel('');
          setObservacoesTel('');
        } else {
          Swal.fire({
            title: "Atenção!",
            icon: "error",
            html: rps.data.erro,
            showCloseButton: true,
            showCancelButton: false,
      });
    }      
  })
  }

  function salvarEfetuada() {
    var data = {
      cotacao: sessionStorage.getItem('cotacao'),
      observacoes: observacoesTel,
      idUsuario: localStorage.getItem('id_usuario'),
      situacao: efetuada,
      inicio: sessionStorage.getItem('inicioLigacao') 
    }

    Api.post("ligacoes/salvarEfetuadas", data).then(rps => {
      if(rps.data.status===true){
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
          setNomeTel('');
          setConheceuTel('');
          setCidadeTel('');
          setPacoteTel('');
          setObservacoesTel('');
        } else {
          Swal.fire({
            title: "Atenção!",
            icon: "error",
            html: rps.data.erro,
            showCloseButton: true,
            showCancelButton: false,
      });
    }      
  })
  }
  
  return (
    <>

      <div id="kt_header" className="header header-fixed">
        {/*begin::Container*/}
        <div className="container d-flex align-items-stretch justify-content-between">
          {/*begin::Left*/}
          <div className="d-flex align-items-center mr-3">
            {/*begin::Aside Toggle*/}
            <button onClick={e => { testeLucas() }} className="btn btn-icon aside-toggle ml-n3 mr-10">
              
              <span className="svg-icon svg-icon-xxl svg-icon-dark-75 icon-menu-ajust">
                {/*begin::Svg Icon | path:assets/media/svg/icons/Text/Align-left.svg*/}
                <i className="icon-xl fas fa-align-justify" />
              </span>
            </button>
            {/*end::Aside Toggle*/}
            {/*begin::Logo*/}
            <Link to="/painel" className="menu-link">
              <img alt="Logo" src="https://expertpluss.hotelfazendasaojoao.com.br/img/logo2.png" className="logo-sticky max-h-60px" />
            </Link>
            {/*end::Logo*/}
          </div>
          {/*end::Left*/}
          {/*begin::Topbar*/}
          <div className="topbar">
            {/*begin::Notifications*/}
            { (localStorage.getItem('id_perfil') == '1' || localStorage.getItem('id_perfil') == '3' || localStorage.getItem('id_perfil') == '5') && <>     
            <div className="topbar-item mr-3">
              <Link to="/oportunidadeseventos">
                <div className="btn font-weight-bolder btn-sm btn-light-danger px-5">
                  {qtdeCotacoesEventos}
                </div>
              </Link>
            </div>
            </>
            }
            {/*end::Notifications*/}

            {/*begin::Notifications*/}
            { (localStorage.getItem('id_perfil') == '1' || localStorage.getItem('id_perfil') == '2' || localStorage.getItem('id_perfil') == '4') && <>     
            <div className="topbar-item mr-3">
              <Link to="/oportunidades">
                <div className="btn font-weight-bolder btn-sm btn-light-info px-5">
                  {qtdeCotacoes}
                </div>
              </Link>
            </div>
            </>
            }
            {/*end::Notifications*/}
            {/*begin::Telefone*/}
            
            <div className="topbar-item">
                <div className="btn btn-sm btn-clean btn-text-dark-75">
                  <a data-toggle="modal" data-target="#kt_chat_modal" onClick={e=>{abrirModalTelefone()}}>
                  <span className="svg-icon svg-icon-lg">
                    {/*begin::Svg Icon | path:assets/media/svg/icons/General/User.svg*/}
                    <svg xmlns="https://www.w3.org/2000/svg" xmlnsXlink="https://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                        <polygon points="0 0 24 0 24 24 0 24" />
                        <path d="M13.0799676,14.7839934 L15.2839934,12.5799676 C15.8927139,11.9712471 16.0436229,11.0413042 15.6586342,10.2713269 L15.5337539,10.0215663 C15.1487653,9.25158901 15.2996742,8.3216461 15.9083948,7.71292558 L18.6411989,4.98012149 C18.836461,4.78485934 19.1530435,4.78485934 19.3483056,4.98012149 C19.3863063,5.01812215 19.4179321,5.06200062 19.4419658,5.11006808 L20.5459415,7.31801948 C21.3904962,9.0071287 21.0594452,11.0471565 19.7240871,12.3825146 L13.7252616,18.3813401 C12.2717221,19.8348796 10.1217008,20.3424308 8.17157288,19.6923882 L5.75709327,18.8875616 C5.49512161,18.8002377 5.35354162,18.5170777 5.4408655,18.2551061 C5.46541191,18.1814669 5.50676633,18.114554 5.56165376,18.0596666 L8.21292558,15.4083948 C8.8216461,14.7996742 9.75158901,14.6487653 10.5215663,15.0337539 L10.7713269,15.1586342 C11.5413042,15.5436229 12.4712471,15.3927139 13.0799676,14.7839934 Z" fill="#000000"/>
                        <path d="M14.1480759,6.00715131 L13.9566988,7.99797396 C12.4781389,7.8558405 11.0097207,8.36895892 9.93933983,9.43933983 C8.8724631,10.5062166 8.35911588,11.9685602 8.49664195,13.4426352 L6.50528978,13.6284215 C6.31304559,11.5678496 7.03283934,9.51741319 8.52512627,8.02512627 C10.0223249,6.52792766 12.0812426,5.80846733 14.1480759,6.00715131 Z M14.4980938,2.02230302 L14.313049,4.01372424 C11.6618299,3.76737046 9.03000738,4.69181803 7.1109127,6.6109127 C5.19447112,8.52735429 4.26985715,11.1545872 4.51274152,13.802405 L2.52110319,13.985098 C2.22450978,10.7517681 3.35562581,7.53777247 5.69669914,5.19669914 C8.04101739,2.85238089 11.2606138,1.72147333 14.4980938,2.02230302 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/>
                      </g>
                    </svg>
                    {/*end::Svg Icon*/}
                  </span>
                  </a>
                </div>
              </div>
            
            {/*end::Telefone*/}
            
            {/*begin::User*/}
            
              <div className="topbar-item mr-4">
                <div className="btn btn-sm btn-clean btn-text-dark-75">
                  <span className="svg-icon svg-icon-lg">
                    {/*begin::Svg Icon | path:assets/media/svg/icons/General/User.svg*/}
                    <svg xmlns="https://www.w3.org/2000/svg" xmlnsXlink="https://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                        <polygon points="0 0 24 0 24 24 0 24" />
                        <path d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                        <path d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z" fill="#000000" fillRule="nonzero" />
                      </g>
                    </svg>
                    {/*end::Svg Icon*/}
                    {localStorage.getItem('nome')}
                  </span>
                </div>
              </div>
            
            {/*end::User*/}
          </div>
          {/*end::Topbar*/}
        </div>
        {/*end::Container*/}
      </div>
      


      {/*begin::Chat Panel*/}
<div className="modal modal-sticky modal-sticky-bottom-right" id="kt_chat_modal" role="dialog" data-backdrop="false">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
      {/*begin::Card*/}
      <div className="card card-custom">
        {/*begin::Header*/}
        <div className="card-header align-items-center px-4 py-3">
          <div className="text-center flex-grow-1">
            <div className="text-dark-75 font-weight-bold font-size-h5">Atender Ligação</div>
            <div>
              <span className="label label-dot label-success" />
              <span className="font-weight-bold text-muted font-size-sm">Ativo</span>
            </div>
          </div>
        </div>
        {/*end::Header*/}
        {/*begin::Body*/}
        <div className="card-body">
          {/*begin::Scroll*/}
          <div className="scroll scroll-pull" data-height={375} data-mobile-height={300}>
            {/*begin::Messages*/}
            <div className="messages">
              {/*begin::Message In*/}
              
              <label>Nome</label>
              <input type="text" className="form-control" value={nomeTel} onChange={(e)=> {setNomeTel(e.target.value); }}/>

              <label>Cidade</label>
              <input type="text" className="form-control" value={cidadeTel} onChange={(e)=> {setCidadeTel(e.target.value); }} />
              
              <label>Pacote</label>
              <select className="form-control" value={pacoteTel} onChange={(e)=> {setPacoteTel(e.target.value); }}>
                  <option value="">Selecione...</option>
                  {programacoesTel.map((item) =>{ return(
                  <option value={item.id}>{item.nome}</option>
              )})}
              </select>

              <label>Conheceu</label>
              <select className="form-control" value={conheceuTel} onChange={(e)=> {setConheceuTel(e.target.value); }}>
                  <option value="">Selecione...</option>
                  {conheceusTel.map((item) =>{ return(
                  <option value={item.id}>{item.descricao}</option>
              )})}
              </select>

              <label>Observações</label>
              <textarea className="form-control" rows={2} defaultValue={""} value={observacoesTel} onChange={(e)=> {setObservacoesTel(e.target.value); }}/>

              {/*end::Message In*/}
              
            </div>
            {/*end::Messages*/}
          </div>
          {/*end::Scroll*/}
        </div>
        {/*end::Body*/}
        {/*begin::Footer*/}
        <div className="card-footer align-items-center">
          {/*begin::Compose*/}
          <div className="d-flex align-items-center justify-content-between mt-5">
              <button type="button" data-toggle="modal" data-target="#kt_chat_modal" className="btn btn-danger btn-md font-weight-bold chat-send py-2 px-6">Cancelar</button>
            <div>
              <button type="button" data-toggle="modal" data-target="#kt_chat_modal" className="btn btn-success btn-md font-weight-bold chat-send py-2 px-6" onClick={e=>{salvarLigacao()}}>Finalizar</button>
            </div>
          </div>
          {/*begin::Compose*/}
        </div>
        {/*end::Footer*/}
      </div>
      {/*end::Card*/}
    </div>
  </div>
</div>
{/*end::Chat Panel*/}


{/*begin::Panel Efetuada*/}
<div className="modal modal-sticky modal-sticky-bottom-right" id="kt_chat_modalR" role="dialog" data-backdrop="false">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
      {/*begin::Card*/}
      <div className="card card-custom">
        {/*begin::Header*/}
        <div className="card-header align-items-center px-4 py-3">
          <div className="text-center flex-grow-1">
            <div className="text-dark-75 font-weight-bold font-size-h5">Efetuando Ligação</div>
            <div>
              <span className="label label-dot label-success" />
              <span className="font-weight-bold text-muted font-size-sm">Ativo</span>
            </div>
          </div>
        </div>
        {/*end::Header*/}
        {/*begin::Body*/}
        <div className="card-body">
          {/*begin::Scroll*/}
          <div className="scroll scroll-pull" data-height={375} data-mobile-height={300}>
            {/*begin::Messages*/}
            <div className="messages">
              {/*begin::Message In*/}
              <label>Ligação Atendida:</label><br />
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" onChange={(e)=> setEfetuada(false)}  checked={efetuada===false} id="inlineRadio1"  />
                <label className="form-check-label" for="inlineRadio1">Não</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" onChange={(e)=> setEfetuada(true)}  checked={efetuada===true} id="inlineRadio2" />
                <label class="form-check-label" for="inlineRadio2">Sim</label>
              </div>

              <br /><br />
              <label>Observações</label>
              <textarea className="form-control" rows={2} defaultValue={""} value={observacoesTel} onChange={(e)=> {setObservacoesTel(e.target.value); }}/>

              {/*end::Message In*/}
              
            </div>
            {/*end::Messages*/}
          </div>
          {/*end::Scroll*/}
        </div>
        {/*end::Body*/}
        {/*begin::Footer*/}
        <div className="card-footer align-items-center">
          {/*begin::Compose*/}
          <div className="d-flex align-items-center justify-content-between mt-5">
              <button type="button" data-toggle="modal" data-target="#kt_chat_modalR" className="btn btn-danger btn-md font-weight-bold chat-send py-2 px-6">Cancelar</button>
            <div>
              <button type="button" data-toggle="modal" data-target="#kt_chat_modalR" className="btn btn-success btn-md font-weight-bold chat-send py-2 px-6" onClick={e=>{salvarEfetuada()}}>Finalizar</button>
            </div>
          </div>
          {/*begin::Compose*/}
        </div>
        {/*end::Footer*/}
      </div>
      {/*end::Card*/}
    </div>
  </div>
</div>
{/*end::Panel Efetuadas*/}

      
    </>
  )
};