import React, { useState, useEffect } from 'react';
import Api from '../../services/api';
import { withRouter } from "react-router";
import Moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';

const Programacao = () => {
  const [ programacao, setProgramacao ] = useState([]);
  const [ caProcessa, setCaProcessa ] = useState(false);
  
  useEffect(() => {
    getListar();
  }, []);

  function getListar() {
    setCaProcessa(true); 
    Api.get("programacao/programacaoSoma").then(rps => {    
          setProgramacao(rps.data.obj);

          setCaProcessa(false); 
    })
  }
    
    return (
        <>
<div>

<div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Programações em Andamento</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center">

            </div>
            {/*end::Toolbar*/}
          </div>
        </div>
        {/*begin::Entry*/}
{/*begin::Entry*/}
<div className="d-flex flex-column-fluid">
  {/*begin::Container*/}
  <div className="container">
    {/*begin::Row*/}
    

    <LoadingOverlay
                active={caProcessa}
                spinner
                text='Carregando...'
              >
  <div className="row">
    { programacao.map((item) =>{ return(
        
     <div className="col-xl-4">
  {/*begin::Nav Panel Widget 3*/}
  <div className="card card-custom card-stretch gutter-b">
    {/*begin::Body*/}
    <div className="card-body">
      {/*begin::Wrapper*/}
      <div className="d-flex justify-content-between flex-column h-100">
        {/*begin::Container*/}
        <div className="h-100">
          {/*begin::Header*/}
          <div className="d-flex flex-column flex-center">
            {/*begin::Image*/}
            <div><img className="img-fluid rounded" src={'https://expertpluss.hotelfazendasaojoao.com.br/img/programacoes/' + item.imgBanner}></img></div>
            {/*end::Image*/}
            {/*begin::Title*/}
            <a href="#" className="card-title font-weight-bolder text-dark-75 text-hover-primary font-size-h4 m-0 pt-7 pb-1">{item.nome}</a>
            {/*end::Title*/}
            {/*begin::Text*/}
            <div className="font-weight-bold text-dark-50 font-size-sm pb-7">Programação de {Moment(item.dtInicio).format('DD/MM/YYYY')} até {Moment(item.dtFim).format('DD/MM/YYYY')}</div>
            {/*end::Text*/}
          </div>
          {/*end::Header*/}
          {/*begin::Body*/}
          <div className="pt-1">
            {/*begin::Item*/}
            <div className="d-flex align-items-center pb-9">
              {/*begin::Symbol*/}
              <div className="symbol symbol-45 symbol-light mr-4">
                <span className="symbol-label">
                  <span className="svg-icon svg-icon-2x svg-icon-dark-50">
                    {/*begin::Svg Icon | path:assets/media/svg/icons/Communication/Group.svg*/}
                    <svg xmlns="https://www.w3.org/2000/svg" xmlnsXlink="https://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                        <polygon points="0 0 24 0 24 24 0 24" />
                        <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                        <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fillRule="nonzero" />
                      </g>
                    </svg>
                    {/*end::Svg Icon*/}
                  </span>
                </span>
              </div>
              {/*end::Symbol*/}
              {/*begin::Text*/}
              <div className="d-flex flex-column flex-grow-1">
                <a href="#" className="text-dark-75 text-hover-primary mb-1 font-size-lg font-weight-bolder">Pessoas Interessadas</a>
                {/*<span className="text-muted font-weight-bold">Successful Fellas</span>*/}
              </div>
              {/*end::Text*/}
              {/*begin::label*/}
              <span className="font-weight-bolder label label-xl label-light-success label-inline px-3 py-5 min-w-45px">{item.qtdePessoas}</span>
              {/*end::label*/}
            </div>
            {/*end::Item*/}
            
            {/*begin::Item*/}
            <div className="d-flex align-items-center pb-9">
              {/*begin::Symbol*/}
              <div className="symbol symbol-45 symbol-light mr-4">
                <span className="symbol-label">
                  <span className="svg-icon svg-icon-2x svg-icon-dark-50">
                    {/*begin::Svg Icon | path:assets/media/svg/icons/Media/Equalizer.svg*/}
                    <svg xmlns="https://www.w3.org/2000/svg" xmlnsXlink="https://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                        <rect x={0} y={0} width={24} height={24} />
                        <rect fill="#000000" opacity="0.3" x={13} y={4} width={3} height={16} rx="1.5" />
                        <rect fill="#000000" x={8} y={9} width={3} height={11} rx="1.5" />
                        <rect fill="#000000" x={18} y={11} width={3} height={9} rx="1.5" />
                        <rect fill="#000000" x={3} y={13} width={3} height={7} rx="1.5" />
                      </g>
                    </svg>
                    {/*end::Svg Icon*/}
                  </span>
                </span>
              </div>
              {/*end::Symbol*/}
              {/*begin::Text*/}
              <div className="d-flex flex-column flex-grow-1">
                <a href="#" className="text-dark-75 text-hover-primary mb-1 font-size-lg font-weight-bolder">Cotações Enviadas</a>
                {/*<span className="text-muted font-weight-bold">Good Fellas</span>*/}
              </div>
              {/*end::Text*/}
              {/*begin::label*/}
              <span className="font-weight-bolder label label-xl label-light-success label-inline px-3 py-5 min-w-45px">{item.qtdeCotacoes}</span>
              {/*end::label*/}
            </div>
            {/*end::Item*/}

            

          </div>
          {/*end::Body*/}
        </div>
        {/*eng::Container*/}
      </div>
      {/*end::Wrapper*/}
    </div>
    {/*end::Body*/}
  </div>
  {/*end::Nav Panel Widget 3*/}
</div>
)})}
</div>
</LoadingOverlay>
</div>
        </div>
    </div>
        </>
    );

}

export default withRouter(Programacao);                  