import React, { useState, useEffect } from 'react';
import Api from '../../../../services/api';
import { withRouter } from "react-router";
import Axios from 'axios';
import { Redirect  } from 'react-router-dom';

import Swal from "sweetalert2";
import { useToasts } from 'react-toast-notifications';
import LoadingOverlay from 'react-loading-overlay';

const SBannerGrande = () => {
  const { addToast } = useToasts();
  const refImgBanner = React.useRef(null);
  const [ caProcessa, setCaProcessa ] = useState(false);
  const [ formProgramacao, setFormProgramacao ] = useState({});
  const [ redirecionar, setRedirecionar ] = useState(false);
  const [ programacoes, setProgramacoes ] = useState([]);

  useEffect(() => {
    getListar();
    getListarProgramacao();
  }, []);

  function getListar() { 
    setCaProcessa(true); 
  
    Api.get('site_texto/getbannergrande').then((rps) => {
      
      setFormProgramacao(rps.data.obj);
      setCaProcessa(false);
    })
  }

  function getListarProgramacao() {
    Api.get("cotacoes/listaprogramacoes").then(rps => {    
      setProgramacoes(rps.data.prog);
    })
  }
  
  function editar(id){  
    var ca = JSON.parse(JSON.stringify(id));
    
    setFormProgramacao(ca);          
  }

  function salvar(){
    setCaProcessa(true);

    Api.post('site_programacao/adicionar', formProgramacao).then(rps => {
      if (rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
        getListar();
      } else {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: rps.data.erros,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
      setCaProcessa(false);
    });
  }
 
  async function handleImgBannerChange(e) {
    const form_data = new FormData();
    form_data.append('arquivo', e.target.files[0]);
    form_data.append('id', formProgramacao.id);
      
    const cf = {
        baseURL: Api.defaults.baseURL,
        headers: {
          'content-type': 'multipart/form-data',
          'Authorization' : Api.defaults.headers['Authorization']
      }
    }
    
    const response = await Axios.post('site_texto/imgbannergrande', form_data, cf);
    if (response.data.status !== undefined && response.data.status === true) {
      addToast(response.data.mensagem, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 5000
      });
      setFormProgramacao({...formProgramacao, imagem_banner_grande: response.data.url_imagem});
    } else {
      Swal.fire({
        title: "Atenção!",
        icon: "info",
        html: response.data.erros,
        showCloseButton: true,
        showCancelButton: false
      });
    }
  }

  function sair() {   
    setRedirecionar(true);
  }

  if (redirecionar === true) {
      return <Redirect to="/painel" />
  } 


    return (
        <>
<div>

<div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mt-7 mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mr-5">Site</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mr-5 bg-gray-200" />
              {/*end::Separator*/}
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mr-5">Banner Grande</h5>
              {/*end::Title*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            
            {/*end::Toolbar*/}
          </div>
        </div>
        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">

            <div className="card card-custom">
              <div className="ml-10 mr-10 mt-10">    
                <div className="row">               
                  <div className="form-group col-md-12">
                    <label>Selecione a Programação <span class="text-danger">*</span></label>
                    <select className="form-control" value={formProgramacao?.link_banner_grande} onChange={(e)=> {setFormProgramacao({...formProgramacao, link_banner_grande: e.target.value})}}>
                        <option value="">Selecione...</option>
                        {programacoes.map((item) =>{ return(
                        <option value={item.id}>{item.nome}</option>
                    )})}
                    </select>
                  </div>
                </div> 

                
                <div className="row">
                  <div className="from-group col-md-12">
                    <label>Banner Grande</label>
                    <div className="area-imagem dropzone--squared menu-category-drawer-item-details-tab__image">
                      <div className="dropzone__area" aria-disabled="false" style={{ position: 'relative' }}
                        onClick={e => { refImgBanner.current.click() }} >
                        <div className="dropzone-preview dropzone__preview">
                          <img className="dropzone-preview__image" src={formProgramacao?.imagem_banner_grande ? "https://motor.hotelfazendasaojoao.com.br/assets/img/programacoes/"+formProgramacao?.imagem_banner_grande : "/img/no-image.jpg"} alt="" />
                        </div>
                        <div className="dropzone-placeholder dropzone__placeholder">
                          <span>Trocar imagem</span>
                        </div>
                        <input type="file" ref={refImgBanner} name="imgBanner" onChange={(e) => handleImgBannerChange(e)} accept=".png, .jpg, .jpeg" className="classeInputImage" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='card-footer text-right'>
                  <button type="button" onClick={e => { sair(); }} className="btn btn-secondary font-weight-bold mr-5"><i className="fas fa-door-open" />Sair</button>
                  <button type="button" onClick={e => { salvar(); }} className="btn btn-success font-weight-bold"><i className="fas fa-save" />Salvar</button>
                </div>

              </div>

                  </div>
                  </div>
                  </div>
                  </div>
                                   
        </>
    );
}

export default withRouter(SBannerGrande);                  