import React from 'react';
import { BrowserRouter as HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import Layout from '../layout';

import Swal from 'sweetalert2';

import Login from '../pages/login';
import Painel from '../pages/painel';
import Programacao from '../pages/programacao';
import Interesse from '../pages/pessoas/interesse';
import Pessoa from '../pages/pessoas/pessoa';
import CotacaoEnviar from '../pages/cotacoes/enviar';
import CotacaoSimular from '../pages/cotacoes/simular';
import CotacaoAndamento from '../pages/cotacoes/andamento';
import CotacaoDetalhe from '../pages/cotacoes/andamento/detalhe';
import TarifasCad from '../pages/tarifas/cadastrar';
import TarifasCon from '../pages/tarifas/consultar';
import TarifasConEvento from '../pages/tarifas/consultar_eventos';
import Oportunidades from '../pages/oportunidades';
import OportunidadesEventos from '../pages/oportunidadeseventos';
import Fechar from '../pages/fechamento/fechar';
import Listar from '../pages/fechamento/listar';
import Fechadas from '../pages/fechadas';
import LigacoesColaborador from '../pages/relatorios/ligacoes/colaborador';
import LigacoesConheceu from '../pages/relatorios/ligacoes/conheceu';
import LigacoesProgramacao from '../pages/relatorios/ligacoes/programacao';
import LigacoesAno from '../pages/relatorios/ligacoes/ano';
import CotacoesPeriodo from '../pages/relatorios/cotacoes/periodo';
import CotacoesArquivamento from '../pages/relatorios/cotacoes/arquivamento';
import CotacoesConheceu from '../pages/relatorios/cotacoes/conheceu';
import FecEsperaColaborador from '../pages/relatorios/fechamento/espera_colaborador';
import FecFechadaColaborador from '../pages/relatorios/fechamento/fechada_colaborador';
import FecEsperaProgramacao from '../pages/relatorios/fechamento/espera_programacao';
import FecFechadaProgramacao from '../pages/relatorios/fechamento/fechada_programacao';
import FecEsperaOrigem from '../pages/relatorios/fechamento/espera_origem';
import FecFechadaOrigem from '../pages/relatorios/fechamento/fechada_origem';
import FecFechadaPor from '../pages/relatorios/fechamento/fechada_por';
//Eventos
import EventosCafes from '../pages/eventos/cafes';
import EventosSugestoes from '../pages/eventos/sugestoes';
import EventosBebidas from '../pages/eventos/bebidas';
import EventosCoquetel from '../pages/eventos/coquetel';
//Site
import SInfraestrutura from '../pages/site/infraestrutura';
import SGastronomia from '../pages/site/gastronomia';
import SLazer from '../pages/site/lazer';
import SProgramacao from '../pages/site/programacao';
import SAcomodacao from '../pages/site/acomodacao';
import STOHome from '../pages/site/texto/home';
import STOHotel from '../pages/site/texto/ohotel';
import STOGastronomia from '../pages/site/texto/gastronomia';
import STOLazer from '../pages/site/texto/lazer';
import STOProgramacoes from '../pages/site/texto/programacoes';
import STOContato from '../pages/site/texto/contato';
import SBannerDestaque from '../pages/site/outros/destaques';
import SBannerGrande from '../pages/site/outros/grande';
//Configuracoes
import Usuarios from '../pages/configuracoes/usuarios';

// Verificar se o usuário está autenticado
const isAuthenticated = () => {
    if("hash" in localStorage){
        return true
    } else {
        Swal.fire(
            'Erro!',
            'Você não está logado, tente novamente.',
            'error'
        )
        return false
    }
};
// A função abaixo é para proteger uma rota
const AuthenticatedRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/login" }} />
        )
      }
    />
  );

const Routes = (props) => (
    <HashRouter>
        <Switch>
            { /* Rotas de Login não necessitam de Auth */}
            <Route exact path="/" component={Login}></Route>
            <Route exact path="/login" component={Login}></Route>
            <Route exact path="/login/:usuario/:hash" component={Login}></Route>
            <Route exact path="/auth" component={Login}></Route>
            { /* ------------------------------------- */}
            <Layout>
                <AuthenticatedRoute exact path="/painel" component={Painel} />
                <AuthenticatedRoute exact path="/programacao" component={Programacao} />
                <AuthenticatedRoute exact path="/pessoas/interesse" component={Interesse} />
                <AuthenticatedRoute exact path="/pessoas/pessoa" component={Pessoa} />
                <AuthenticatedRoute exact path="/cotacoes/enviar" component={CotacaoEnviar} />
                <AuthenticatedRoute exact path="/cotacoes/simular" component={CotacaoSimular} />
                <AuthenticatedRoute exact path="/cotacoes/andamento" component={CotacaoAndamento} />
                <AuthenticatedRoute exact path="/cotacoes/andamento/detalhe" component={CotacaoDetalhe} />
                <AuthenticatedRoute exact path="/tarifas/cadastrar" component={TarifasCad} />
                <AuthenticatedRoute exact path="/tarifas/consultar" component={TarifasCon} />
                <AuthenticatedRoute exact path="/tarifas/consultar_eventos" component={TarifasConEvento} />
                <AuthenticatedRoute exact path="/oportunidades" component={Oportunidades} />
                <AuthenticatedRoute exact path="/oportunidadeseventos" component={OportunidadesEventos} />
                <AuthenticatedRoute exact path="/fechamento/fechar" component={Fechar} />
                <AuthenticatedRoute exact path="/fechamento/listar" component={Listar} />
                <AuthenticatedRoute exact path="/fechadas" component={Fechadas} />
                <AuthenticatedRoute exact path="/relatorios/ligacoes/colaborador" component={LigacoesColaborador} />
                <AuthenticatedRoute exact path="/relatorios/ligacoes/conheceu" component={LigacoesConheceu} />
                <AuthenticatedRoute exact path="/relatorios/ligacoes/ano" component={LigacoesAno} />
                <AuthenticatedRoute exact path="/relatorios/ligacoes/programacao" component={LigacoesProgramacao} />
                <AuthenticatedRoute exact path="/relatorios/cotacoes/periodo" component={CotacoesPeriodo} />
                <AuthenticatedRoute exact path="/relatorios/cotacoes/arquivamento" component={CotacoesArquivamento} />
                <AuthenticatedRoute exact path="/relatorios/cotacoes/conheceu" component={CotacoesConheceu} />
                <AuthenticatedRoute exact path="/fechamento/espera/colaborador" component={FecEsperaColaborador} />
                <AuthenticatedRoute exact path="/fechamento/fechada/colaborador" component={FecFechadaColaborador} />
                <AuthenticatedRoute exact path="/fechamento/espera/programacao" component={FecEsperaProgramacao} />
                <AuthenticatedRoute exact path="/fechamento/fechada/programacao" component={FecFechadaProgramacao} />
                <AuthenticatedRoute exact path="/fechamento/espera/origem" component={FecEsperaOrigem} />
                <AuthenticatedRoute exact path="/fechamento/fechada/origem" component={FecFechadaOrigem} />
                <AuthenticatedRoute exact path="/fechamento/fechada/por" component={FecFechadaPor} />
                
                <AuthenticatedRoute exact path="/eventos/cafes" component={EventosCafes} />
                <AuthenticatedRoute exact path="/eventos/sugestoes" component={EventosSugestoes} />
                <AuthenticatedRoute exact path="/eventos/bebidas" component={EventosBebidas} />
                <AuthenticatedRoute exact path="/eventos/coquetel" component={EventosCoquetel} />

                <AuthenticatedRoute exact path="/site/infraestrutura" component={SInfraestrutura} />
                <AuthenticatedRoute exact path="/site/gastronomia" component={SGastronomia} />
                <AuthenticatedRoute exact path="/site/lazer" component={SLazer} />
                <AuthenticatedRoute exact path="/site/programacao" component={SProgramacao} />
                <AuthenticatedRoute exact path="/site/acomodacao" component={SAcomodacao} />
                <AuthenticatedRoute exact path="/site/texto/home" component={STOHome} />
                <AuthenticatedRoute exact path="/site/texto/ohotel" component={STOHotel} />
                <AuthenticatedRoute exact path="/site/texto/gastronomia" component={STOGastronomia} />
                <AuthenticatedRoute exact path="/site/texto/lazer" component={STOLazer} />
                <AuthenticatedRoute exact path="/site/texto/programacoes" component={STOProgramacoes} />
                <AuthenticatedRoute exact path="/site/texto/contato" component={STOContato} />
                <AuthenticatedRoute exact path="/site/outros/destaques" component={SBannerDestaque} />
                <AuthenticatedRoute exact path="/site/outros/grande" component={SBannerGrande} />

                <AuthenticatedRoute exact path="/configuracoes/usuarios" component={Usuarios} />

            </Layout>
        </Switch>
    </HashRouter>
);

export default Routes;