import React, { useState, useEffect } from 'react';
import Api from '../../../../services/api';
import { withRouter } from "react-router";

import { useToasts } from 'react-toast-notifications';
import LoadingOverlay from 'react-loading-overlay';
import { Bar } from 'react-chartjs-2';

const LigacoesAno = () => {
  const { addToast } = useToasts();

  const [ carregando, setCarregando ] = useState(false);
  
  //Grafico das Programações
  const [ ligacoes , setLigacoes ] = useState([]);
  const [ mesLabel , setMesLabel ] = useState([]);
  const [ mesValor , setMesValor ] = useState([]);
  const [ totalQtdLigacoes, setTotalQtdLigacoes ] = useState(0);

  useEffect(() => {
    getGerar();
  }, []);

  function getGerar() {
    setCarregando(true);
          
      Api.get("relatorio/ligacoesMes").then(rps => {
        //***Inicio do valor Grafico Programacao
        var formataMesTexto = [];
        var formataMesValor = [];
        var qtdLigacoes = 0;
  
        setLigacoes(rps.data.obj);

        rps.data.obj.map( item => {
            formataMesTexto.push(item.mes);
            formataMesValor.push(item.qtde);
            qtdLigacoes = parseInt(qtdLigacoes) + parseInt(item.qtde);
        })
  
        setMesLabel(formataMesTexto);
        setMesValor(formataMesValor);
        setTotalQtdLigacoes(qtdLigacoes);
      
        setCarregando(false);
      })
  }
  
  //Grafico
  const data = {
    labels: mesLabel,
    datasets: [{
      label: "Ligações Recebidas",
      data: mesValor,
      backgroundColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
        'rgba(220, 20, 60, 1)',
        'rgba(127, 255, 0, 1)',
        'rgba(189, 183, 107, 1)',
        'rgba(255, 215, 0, 1)',
        'rgba(255, 255, 0, 1)',
        'rgba(128, 0, 128, 1)',
        'rgba(175, 238, 238, 1)',
        'rgba(0, 0, 128, 1)',
        'rgba(178, 34, 34, 1)',
      ],
      hoverOffset: 4
    }]
  };

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  } 

    return (
      <>
      {/*begin::Entry*/}
      <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
  
          <div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
            <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
              {/*begin::Details*/}
              <div className="d-flex align-items-center flex-wrap mr-2">
                {/*begin::Title*/}
                <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Relatório</h5>
                {/*end::Title*/}
                {/*begin::Separator*/}
                <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
                {/*end::Separator*/}
                Ligações Recebidas agrupadas por Mês em 2021
              </div>
              {/*end::Details*/}
            </div>
          </div>
          {/*begin::Entry*/}
                                   
          <br />

          <LoadingOverlay
            active={carregando}
            spinner
            text='Carregando...'
          >
          <div className="row">
            <div className="col-md-4">
              <div className="card card-custom">
                <div className="card-body">
                  <h3 className="card-title"><b>{totalQtdLigacoes} Ligações em 2021</b></h3>
                  
                  { ligacoes.map((item) =>{ return(
                    <>
                    <hr />
                    <div className="row">
                      <div className="col-md-10">
                        <h6>{item.mes}</h6>
                      </div>
                      <div className="col-md-2 text-right">
                        <h6><b>{item.qtde}</b></h6>
                      </div>
                    </div>
                    </>
                    )}
                  )}
                  
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="card card-custom">
                <div className="card-body">
                  <Bar data={data}  options={options}/>
                </div>
              </div>
            </div>
          </div>

          </LoadingOverlay>
            </div>
          </div>        
        </>
    );

}



export default withRouter(LigacoesAno);                  