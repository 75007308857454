import React, { useState, useEffect } from 'react';
import Api from '../../../../services/api';
import { withRouter } from "react-router";

import Swal from "sweetalert2";
import { useToasts } from 'react-toast-notifications';
import LoadingOverlay from 'react-loading-overlay';
import { HorizontalBar } from 'react-chartjs-2';

import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import InputMask from 'react-input-mask';
import pt from "date-fns/locale/pt-BR"; 
registerLocale("pt-BR", pt);

const LigacoesColaborador = () => {
  const { addToast } = useToasts();

  const [ periodo, setPeriodo ] = useState(0);
  const [ dataInicial, setDataInicial ] = useState();
  const [ dataFinal, setDataFinal ] = useState(new Date());

  const [ carregando, setCarregando ] = useState(false);
  const [ grafNome, setGrafNome ] = useState([]);
  const [ grafLigacaoRealizada, setGrafLigacaoRealizada ] = useState([]);
  const [ grafEfetuadaPositiva, setGrafEfetuadaPositiva ] = useState([]);
  const [ grafEfetuadaNegativa, setGrafEfetuadaNegativa ] = useState([]);

  const [ impTotalLigacoes, setImpTotalLigacoes ] = useState(0);
  const [ impTotalEfetuadas, setImpTotalEfetuadas ] = useState(0);
  const [ impTotalEfetuadasPositivas, setImpTotalEfetuadasPositivas ] = useState(0);
  const [ impTotalEfetuadasNegativas, setImpTotalEfetuadasNegativas ] = useState(0);
  const [ impTotalLigacoesRecebidas, setImpTotalLigacoesRecebidas ] = useState(0);

  useEffect(() => {
    getGerar();
  }, []);

  function getGerar() {
    setCarregando(true);
      
      var data = {
        periodo: periodo,
        data_inicio: dataInicial,
        data_final: dataFinal,
      };
    
      Api.post("relatorio/ligacoesPainel", data).then(rps => {    
      
        //Salvo para usar no gráfico
        const formataNome = rps.data.obj.map( item => {
          return item.name
        });
        const formataEfetuadaPositiva = rps.data.obj.map( item => {
          return item.efetuadaPositiva
        });
        const formataEfetuadaNegativa = rps.data.obj.map( item => {
          return item.efetuadaNegativo
        });
        const formataRealizada = rps.data.obj.map( item => {
          return item.realizada
        });

        //Dados para gerar o gráfico
        setGrafNome(formataNome);
        setGrafLigacaoRealizada(formataRealizada);
        setGrafEfetuadaPositiva(formataEfetuadaPositiva);
        setGrafEfetuadaNegativa(formataEfetuadaNegativa);

        console.log(formataNome);

        //Envio para efetuar os calculos individuais
        efetuaCalculo(rps.data.obj);

        setCarregando(false);
      })
  }

  function efetuaCalculo(dados) {
    var totalLigacoes = 0;
    var totalEfetuadas = 0;
    var totalEfetuadasPositivas = 0;
    var totalEfetuadasNegativas = 0;
    var totalLigacoesRecebidas = 0;


    dados.forEach(linha => {
      
      totalLigacoes = parseInt(totalLigacoes) + parseInt(linha.efetuadaPositiva)
       + parseInt(linha.efetuadaNegativo) + parseInt(linha.realizada);
      totalEfetuadas = parseInt(totalEfetuadas) + parseInt(linha.efetuadaPositiva)
      + parseInt(linha.efetuadaNegativo);
      totalEfetuadasPositivas = parseInt(totalEfetuadasPositivas) + parseInt(linha.efetuadaPositiva);
      totalEfetuadasNegativas = parseInt(totalEfetuadasNegativas) + parseInt(linha.efetuadaNegativo);
      totalLigacoesRecebidas = parseInt(totalLigacoesRecebidas) + parseInt(linha.realizada);
    });

    setImpTotalLigacoes(totalLigacoes);
    setImpTotalEfetuadas(totalEfetuadas);
    setImpTotalEfetuadasPositivas(totalEfetuadasPositivas);
    setImpTotalEfetuadasNegativas(totalEfetuadasNegativas);
    setImpTotalLigacoesRecebidas(totalLigacoesRecebidas);
  }

  //Grafico
  const data = {
    labels: grafNome,
    datasets: [
      {
        label: 'Ligações Recebidas',
        data: grafLigacaoRealizada,
        backgroundColor: 'rgb(54, 162, 235)', 
      },
      {
        label: 'Efetuadas Positivas',
        data: grafEfetuadaPositiva,
        backgroundColor: 'rgb(75, 192, 192)',
      },
      {
        label: 'Efetuadas Negativas',
        data: grafEfetuadaNegativa,
        backgroundColor: 'rgb(255, 99, 132)',
      },
    ],
  }
  
  const options = {
    scales: {
      yAxes: [
        {
          stacked: true,
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          stacked: true,
        },
      ],
    },
  }
 
    return (
      <>
      {/*begin::Entry*/}
      <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
  
          <div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
            <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
              {/*begin::Details*/}
              <div className="d-flex align-items-center flex-wrap mr-2">
                {/*begin::Title*/}
                <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Relatório</h5>
                {/*end::Title*/}
                {/*begin::Separator*/}
                <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
                {/*end::Separator*/}
                Ligações por Colaborador
              </div>
              {/*end::Details*/}
            </div>
          </div>
          {/*begin::Entry*/}
          {/*begin card de pesquisa de vendas */}
            <div className="card card-custom">
                          
              <div className="card-body">
                <div className="row">
                  <div className="col-md-2 form-group">
                      <label>Período</label>
                      <select onChange={(e) => { setPeriodo(e.target.value)}} className="form-control">
                        <option value="0">Dia atual</option>
                        <option value="7">Últimos 7 dias</option>
                        <option value="30">Últimos 30 dias</option>
                        <option value="60">Últimos 60 dias</option>
                        <option value="90">Últimos 90 dias</option>
                        <option value="PP">Período Personalizado</option>
                      </select>
                  </div>
                  { periodo === "PP" && <>
                    <div className="col-md-2 form-group">
                        <label>Data Inicial</label>
                        <div className="input-group">
                        <DatePicker 
                          onChange={setDataInicial}
                          isClearable
                          selectsStart
                          locale="pt-BR"
                          className="form-control"
                          placeholderText="dd/mm/aaaa" 
                          selected ={dataInicial}
                          dateFormat="dd/MM/y"
                          startDate={dataInicial}
                          endDate={dataFinal}
                          customInput={
                            <InputMask mask="99/99/9999" />}/>
                        </div>
                    </div>
                    <div className="col-md-2 form-group">
                      <label>Data Final</label>
                      <div className="input-group">
                        <DatePicker 
                          onChange={setDataFinal}
                          isClearable
                          locale="pt-BR"
                          className="form-control"
                          placeholderText="dd/mm/aaaa" 
                          selected ={dataFinal}
                          dateFormat="dd/MM/y"
                          selectsEnd
                          startDate={dataInicial}
                          endDate={dataFinal}
                          minDate={dataInicial} 
                          customInput={
                            <InputMask mask="99/99/9999" />}/>
                        </div>
                      </div>
                    </>
                  }
                  <div className="d-flex align-items-center">
                    {/*begin::Button*/}
                    <button onClick={e => { getGerar(); }} className="btn btn-info btn-block pl-10 pr-10 btn-md">Filtrar</button>
                    {/*end::Button*/}
                    {/*begin::Button*/}
                  </div>   
                </div>
                  
                  
              </div> 
            </div>                         
          <br />

          <LoadingOverlay
            active={carregando}
            spinner
            text='Carregando...'
          >
          <div className="row">
            <div className="col-md-4">
              <div className="card card-custom">
                <div className="card-body">
                  <h3 className="card-title"><b>{impTotalLigacoes} Ligações</b></h3>
                  
                  <hr />
                  <div className="row" style={{color: "rgb(54, 162, 235)"}}>
                    <div className="col-md-10">
                      <h5 className="card-title">Ligações Recebidas</h5>
                    </div>
                    <div className="col-md-2 text-right">
                      <h5 className="card-title"><b>{impTotalLigacoesRecebidas}</b></h5>
                    </div>
                  </div>

                  <hr />
                  <div className="row">
                    <div className="col-md-10">
                      <h4 className="card-title"><b>Total de Ligações Efetuadas</b></h4>
                    </div>
                    <div className="col-md-2 text-right">
                      <h4 className="card-title"><b>{impTotalEfetuadas}</b></h4>
                    </div>
                  </div>

                  <hr />
                  <div className="row"  style={{color: "rgb(75, 192, 192)"}}>
                    <div className="col-md-10">
                      <h5 className="card-title">Efetuadas Positivas</h5>
                    </div>
                    <div className="col-md-2 text-right">
                      <h5 className="card-title"><b>{impTotalEfetuadasPositivas}</b></h5>
                    </div>
                  </div>

                  <hr />
                  <div className="row" style={{color: "rgb(255, 99, 132)"}}>
                    <div className="col-md-10">
                      <h5 className="card-title">Efetuadas Negativas</h5>
                    </div>
                    <div className="col-md-2 text-right">
                      <h5 className="card-title"><b>{impTotalEfetuadasNegativas}</b></h5>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="card card-custom">
                <div className="card-body">
                  <HorizontalBar data={data} options={options} />
                </div>
              </div>
            </div>
          </div>

          </LoadingOverlay>
            </div>
          </div>        
        </>
    );

}



export default withRouter(LigacoesColaborador);                  