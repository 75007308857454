import React, { useState, useEffect } from 'react';
import Api from '../../../services/api';
import { withRouter } from "react-router";
import Axios from 'axios';

import Swal from "sweetalert2";
import { useToasts } from 'react-toast-notifications';
import LoadingOverlay from 'react-loading-overlay';
import Select from 'react-select';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import DataTable from 'react-data-table-component';
import { Modal, Form, Button, Container } from "react-bootstrap";
import 'react-advanced-cropper/dist/style.css';
import { Cropper, CropperState, CoreSettings } from 'react-advanced-cropper';

const SInfraestrutura = () => {
  const { addToast } = useToasts();
  const refCapa = React.useRef(null);
  const [ infraestrutura, setInfraestrutura ] = useState([]);
  const [ caProcessa, setCaProcessa ] = useState(false);
  const [ buscaNome, setBuscaNome ] = useState();
  const [ buscaStatus, setBuscaStatus ] = useState();
  const [ buscaExLazer, setBuscaExLazer ] = useState();
  const [ buscaExEventos , setBuscaExEventos ] = useState();
  const [ modal, setModal ] = useState(false);
  const [ fotoModal, setFotoModal ] = useState(false);
  const [ formInfraestrutura, setFormInfraestrutura ] = useState({});
  const [ acao, setAcao ] = useState();
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
 
  function fecharModal(){
    setModal(false);
    getListar();
  }

  function fecharFotoModal(){
    setFotoModal(false);
    setModal(false);
  }

  const data = infraestrutura;
  const columns = [
    {
      name: <th>Foto</th>,
      width: '10%',
      center: true,
      cell: row => <img src={"https://motor.hotelfazendasaojoao.com.br/assets/img/infraestrutura/"+row.imagem} width="150px" />,
    },
    {
      name: <th>Nome</th>,
      selector: 'nome',
      sortable: true,
      width: '15%',
    },
    {
      name: <th>Descrição</th>,
      selector: 'descricao',
      cell: (row) => 
      { return (<>
        <span className="content" dangerouslySetInnerHTML={{__html: row.descricao}}></span> 
      </>)
      },
      width: '28%',
      sortable: true,
    },
    {
      name: <th>Eventos</th>,
      selector: 'exibir_eventos',
      width: '10%',
      sortable: true,
      center: true,
      cell: row => { if (row.exibir_eventos == "S") {return <span class="label label-success label-inline mr-2">Sim</span>}
        else {return <span class="label label-secondary label-inline mr-2">Não</span>}}
    },
    {
      name: <th>Lazer</th>,
      selector: 'exibir_lazer',
      width: '10%',
      center: true,
      sortable: true,
      cell: row => { if (row.exibir_lazer == "S") {return <span class="label label-success label-inline mr-2">Sim</span>}
        else {return <span class="label label-secondary label-inline mr-2">Não</span>}}
    },
    {
      name: <th>Ativo</th>,
      selector: 'ativo',
      width: '10%',
      center: true,
      sortable: true,
      cell: row => { if (row.ativo == "S") {return <span class="label label-primary label-inline mr-2">Sim</span>}
        else {return <span class="label label-secondary label-inline mr-2">Não</span>}}
    },
    {
      name: <th>Ações</th>,
      cell: (row) => 
      { return (<>
        <button className="btn btn-warning btn-sm" onClick={e => { editar(row) }}><i className="fas fa-pencil-alt" />Alterar</button>
        <button className="btn btn-danger btn-sm ml-2" onClick={e => { excluir(row) }}><i className="far fa-trash-alt" />Excluir</button>

    </>)
      },
    },
  ];

   useEffect(() => {
    getListar();
  }, []);

  function getListar() { 
    setCaProcessa(true); 
  
    Api.get('site_infraestrutura/listar').then((rps) => {
      
      setInfraestrutura(rps.data.obj);
      setCaProcessa(false);
    })
  }
  
  function adicionar(){
    setFormInfraestrutura({});
    setModal(true);
    setAcao('Adicionar');
  }

  function editar(id){  
    var ca = JSON.parse(JSON.stringify(id));

    setAcao('Alterar');
    setFormInfraestrutura(ca);
    setModal(true);            
  }

  function excluir(id){
    var ca = JSON.parse(JSON.stringify(id));

    setCaProcessa(true);

    Swal.fire({
        icon: "question",
        title: 'Confirmação',
        html: 'Deseja excluir o registro?',
        showCancelButton: true,
        confirmButtonText: `Sim, Confirmar!`,
        cancelButtonText: 'Não, Cancelar.'
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Api.post('site_infraestrutura/excluir', ca).then(rps => {
          
          if(rps.data.status==true){
            addToast(rps.data.mensagem, {
              appearance: "success",
              autoDismiss: true,
              autoDismissTimeout: 2000
            });
            getListar();
          } 
        })
      } 
      setCaProcessa(false);
    }) 
  }

  function salvar(){
    setCaProcessa(true);

    Api.post('site_infraestrutura/adicionar', formInfraestrutura).then(rps => {
      if (rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
        getListar();
        setAcao('Alterar');
      } else {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: rps.data.erros,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
      setCaProcessa(false);
    });
  }

  function filtrar() {
    setCaProcessa(true); 
      var data = {
        nome: buscaNome,
        status: buscaStatus,
        eventos: buscaExEventos,
        lazer: buscaExLazer,
      };
    
      Api.post('site_infraestrutura/listar', data).then((rps) => {

        if(rps.data.status==false){
          Swal.fire({
            title: "Atenção!",
            icon: "info",
            html: rps.data.erro,
            showCloseButton: true,
            showCancelButton: false,
          });
        } else {
          setInfraestrutura(rps.data.obj);
        }
      setCaProcessa(false); 
    });
  }

  function alterarFoto(){  
    
    setFotoModal(true);            
  }


  const inputRef = useState();
  const cropperRef = useState();

  const [image, setImage] = useState("");

  const onUpload = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };
  const onCrop = () => {
    const cropper = cropperRef.current;
    if (cropper) {
      const canvas = cropper.getCanvas();
      //console.log(canvas.toDataURL())

      var data = {
        imagem: canvas.toDataURL(),
        id_infraestrutura: formInfraestrutura.id
      }
      
      Api.post('site_infraestrutura/foto2', data).then(rps => {
        if (rps.data.status === true) {
          addToast(rps.data.mensagem, {
            appearance: "success",
            autoDismiss: true,
            autoDismissTimeout: 2000
          });
          getListar();
          setAcao('Alterar');
        } else {
          Swal.fire({
            title: "Erro!",
            icon: "error",
            html: rps.data.erros,
            showCloseButton: true,
            showCancelButton: false,
          });
        }
      });
    }
  };

  const onLoadImage = (event) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      setImage(URL.createObjectURL(file));
    }
    event.target.value = "";
  };

  useEffect(() => {
    // Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
    return () => {
      if (image) {
        URL.revokeObjectURL(image);
      }
    };
  }, [image]);

  const [ coordenadas, setCoordenadas ] = useState(null);
  const [ canvas, setCanvas ] = useState(null);

  function onChangeCrop(cropper){
    console.log(cropper.getCoordinates(), cropper.getCanvas());
    setCoordenadas(cropper.getCoordinates())
    setCanvas(cropper.getCanvas())
  }
  
  async function handleCapaChange(e) {
    const form_data = new FormData();
    form_data.append('arquivo', e.target.files[0]);
    form_data.append('id', formInfraestrutura.id);
      
    const cf = {
        baseURL: Api.defaults.baseURL,
        headers: {
          'content-type': 'multipart/form-data',
          'Authorization' : Api.defaults.headers['Authorization']
      }
    }
    const response = await Axios.post('site_infraestrutura/foto', form_data, cf);
    if (response.data.status !== undefined && response.data.status === true) {
      addToast(response.data.mensagem, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 5000
      });
      setFormInfraestrutura({...formInfraestrutura, capa: response.data.url_imagem});
    } else {
      Swal.fire({
        title: "Atenção!",
        icon: "info",
        html: response.data.erros,
        showCloseButton: true,
        showCancelButton: false
      });
    }
  }
    return (
        <>
<div>

<div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mt-7 mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mr-5">Site</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mr-5 bg-gray-200" />
              {/*end::Separator*/}
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mr-5">Infraestrutura</h5>
              {/*end::Title*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center">
              <button className="btn btn-md btn-success mt-6" onClick={e => {adicionar()}}><i className="fas fa-plus" />Adicionar</button>
            </div>
            {/*end::Toolbar*/}
          </div>
        </div>
        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">

            <div className="card card-custom">
              <div className="ml-10 mr-10 mt-10">    
                <div className="row">

                <div className="form-group col-md-4">
                  <label>Nome</label>
                  <input type="text" value={buscaNome} onChange={e => {setBuscaNome(e.target.value)}} className="form-control" />
                </div>

                <div className="form-group col-md-2">
                  <label>Exibir em Evento</label>
                  <select className="form-control" value={buscaExEventos} 
                    onChange={(e) => {setBuscaExEventos(e.target.value)}}>
                      <option value="" selected>Selecione</option>    
                      <option value="S">Sim</option>    
                      <option value="N">Não</option>     
                  </select>
                </div>

                <div className="form-group col-md-2">
                  <label>Exibir em Lazer</label>
                  <select className="form-control" value={buscaExLazer} 
                    onChange={(e) => {setBuscaExLazer(e.target.value)}}>
                      <option value="" selected>Selecione</option>    
                      <option value="S">Sim</option>    
                      <option value="N">Não</option>     
                  </select>
                </div>

                <div className="form-group col-md-2">
                  <label>Ativo</label>
                  <select className="form-control" value={buscaStatus} 
                    onChange={(e) => {setBuscaStatus(e.target.value)}}>
                      <option value="" selected>Selecione</option>    
                      <option value="S">Sim</option>    
                      <option value="N">Não</option>     
                  </select>
                </div>

                <div className="col-md-2 form-group">
                  <label>&nbsp;</label><br />
                    <button className="btn btn-primary btn-block" onClick={e=>{filtrar()}}><i className="flaticon2-search-1" />Pesquisar</button>
                  </div> 
                </div> 
              </div>

              <LoadingOverlay
                active={caProcessa}
                spinner
                text='Carregando...'
              >     
              
              <DataTable
                  title="Infraestruturas"
                  columns={columns}
                  data={data}
                  striped="true" 
                  pagination="true"
                />
              </LoadingOverlay>                    
      
                  </div>
                  </div>
                  </div>
                  </div>
                  
      <Modal size={'xl'} show={modal} onHide={() => fecharModal()}>
      <Modal.Header>
      <Modal.Title>{acao} Infraestrutura</Modal.Title>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => {fecharModal()}}>
            <i aria-hidden="true" className="ki ki-close" />
          </button>
      </Modal.Header>
      <div className="row mt-5 ml-5 mr-5">
        <div className="form-group col-md-6">
          <label>Nome <span class="text-danger">*</span></label>
          <input type="text" value={formInfraestrutura?.nome} onChange={e => {setFormInfraestrutura({...formInfraestrutura, nome: e.target.value})}} className="form-control" />
        </div>

        <div className="form-group col-md-2">
          <label>Ativo <span class="text-danger">*</span></label>
          <select className="form-control" value={formInfraestrutura.ativo} 
            onChange={(e) => { setFormInfraestrutura({...formInfraestrutura, ativo: e.target.value}) }}>
              <option value="" selected>Selecione</option>    
              <option value="S">Sim</option>    
              <option value="N">Não</option>     
          </select>
        </div>

        <div className="form-group col-md-2">
          <label>Exibir em Evento <span class="text-danger">*</span></label>
          <select className="form-control" value={formInfraestrutura.exibir_eventos} 
            onChange={(e) => { setFormInfraestrutura({...formInfraestrutura, exibir_eventos: e.target.value}) }}>
              <option value="" selected>Selecione</option>    
              <option value="S">Sim</option>    
              <option value="N">Não</option>     
          </select>
        </div>

        <div className="form-group col-md-2">
          <label>Exibir em Lazer <span class="text-danger">*</span></label>
          <select className="form-control" value={formInfraestrutura.exibir_lazer} 
            onChange={(e) => { setFormInfraestrutura({...formInfraestrutura, exibir_lazer: e.target.value}) }}>
              <option value="" selected>Selecione</option>    
              <option value="S">Sim</option>    
              <option value="N">Não</option>     
          </select>
        </div>
      </div>

      <div className="row ml-5 mr-5">
        <div className="form-group col-md-12">
          <label>Descrição</label>
          <CKEditor
            editor={ ClassicEditor }
            data={formInfraestrutura.descricao}
            onChange={( event, editor ) => { setFormInfraestrutura({...formInfraestrutura, descricao: editor.getData()}) }}            
          />
        </div>
      </div>

      { acao == 'Alterar' && <>
      <div className="row ml-5 mr-5">
        <div className="form-group col-md-6">
          <label>Foto</label>
          <img className="img-fluid" src={formInfraestrutura?.imagem ? "https://motor.hotelfazendasaojoao.com.br/assets/img/infraestrutura/"+formInfraestrutura?.imagem : "/img/no-image.jpg"} alt="" />
        </div>

        <div className='form-group text-center col-md-6'>
          <button type="button" onClick={e => { alterarFoto(); }} className="btn btn-lg btn-secondary font-weight-bold"><i className="fas fa-save" />Alterar Foto</button>
        </div>
      </div>
      </>
       }   
      
      <Modal.Footer>
        <button type="button" onClick={e => { fecharModal(); }} className="btn btn-secondary font-weight-bold" data-dismiss="modal"><i className="fas fa-door-open" />Fechar</button>
        <button type="button" onClick={e => { salvar(); }} className="btn btn-success font-weight-bold"><i className="fas fa-save" />Salvar</button>
      </Modal.Footer>
    </Modal>

    <Modal size={'xl'} show={fotoModal} onHide={() => fecharFotoModal()}>
      <Modal.Header>
      <Modal.Title>{acao} Infraestrutura</Modal.Title>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => {fecharFotoModal()}}>
            <i aria-hidden="true" className="ki ki-close" />
          </button>
      </Modal.Header>
      <div className="row mt-5 ml-5 mr-5">
      
      <div className="container">
      <div className="example__cropper-wrapper">
        <Cropper
          ref={cropperRef}
          className="example__cropper"
          backgroundClassName="example__cropper-background"
          src={image}
          maxHeight={480}
          minHeight={480}
          minWidth={640}
          maxWidth={640}
        />
      </div>
      <div className="example__buttons-wrapper">
        <button className="btn btn-secondary" onClick={onUpload}>
          <input
            ref={inputRef}
            type="file"
            accept="image/*"
            onChange={onLoadImage}
          />
        </button>
      </div>
    </div>


      </div>      
      <Modal.Footer>
        <button type="button" onClick={e => { fecharFotoModal(); }} className="btn btn-secondary font-weight-bold" data-dismiss="modal"><i className="fas fa-door-open" />Fechar</button>
        {image && (
          <button type="button" onClick={e => { onCrop(); }} className="btn btn-success font-weight-bold"><i className="fas fa-save" />Salvar</button>
        )}
      </Modal.Footer>
    </Modal>        
                 
        </>
    );
}

export default withRouter(SInfraestrutura);                  