import React, { useState, useEffect } from 'react';
import Api from '../../../services/api';
import { withRouter } from "react-router";
import Axios from 'axios';

import Swal from "sweetalert2";
import { useToasts } from 'react-toast-notifications';
import LoadingOverlay from 'react-loading-overlay';
import Moment from 'moment';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import DataTable from 'react-data-table-component';
import { Modal } from "react-bootstrap";
import 'react-advanced-cropper/dist/style.css';
import { Cropper, CropperState, CoreSettings } from 'react-advanced-cropper';

import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import InputMask from 'react-input-mask';
import pt from "date-fns/locale/pt-BR"; 
registerLocale("pt-BR", pt);

const SProgramacao = () => {
  const { addToast } = useToasts();
  const refImgBanner = React.useRef(null);
  const refImgHome = React.useRef(null);
  const refImgEmail = React.useRef(null);
  const [ programacao, setProgramacao ] = useState([]);
  const [ caProcessa, setCaProcessa ] = useState(false);
  const [ buscaNome, setBuscaNome ] = useState();
  const [ buscaStatus, setBuscaStatus ] = useState();
  const [ buscaDataInicial, setBuscaDataInicial ] = useState();
  const [ buscaDataFinal, setBuscaDataFinal ] = useState();
  const [ modal, setModal ] = useState(false);
  const [ fotoModal, setFotoModal ] = useState(false);
  const [ formProgramacao, setFormProgramacao ] = useState({});
  const [ acao, setAcao ] = useState();
  const [ dataInicial, setDataInicial ] = useState();
  const [ dataFinal, setDataFinal ] = useState();

  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
 
  function fecharModal(){
    setModal(false);
    getListar();
  }

  function fecharFotoModal(){
    setFotoModal(false);
    setModal(false);
  }

  const data = programacao;
  const columns = [
    {
      name: <th>Capa</th>,
      width: '10%',
      center: true,
      cell: row => <img src={"https://motor.hotelfazendasaojoao.com.br/assets/img/programacoes/"+row.imgHome} width="150px" />,
    },
    {
      name: <th>Nome</th>,
      selector: 'nome',
      sortable: true,
      width: '35%',
    },
    {
      name: <th>Data Inícial</th>,
      selector: 'dtInicio',
      width: '14%',
      center: true,
      sortable: true,
      cell: row => { return ( <label>{Moment(row.dtInicio).format('DD/MM/YYYY')}</label> )}
    },
    {
      name: <th>Data Final</th>,
      selector: 'dtFim',
      width: '14%',
      sortable: true,
      center: true,
      cell: row => { return ( <label>{Moment(row.dtFim).format('DD/MM/YYYY')}</label> )}
    },
    {
      name: <th>Status</th>,
      selector: 'ativo',
      width: '10%',
      center: true,
      sortable: true,
      cell: row => { if (row.ativo == "S") {return <span class="label label-primary label-inline mr-2">Sim</span>}
        else {return <span class="label label-secondary label-inline mr-2">Não</span>}}
    },
    {
      name: <th>Ações</th>,
      cell: (row) => 
      { return (<>
        <button className="btn btn-warning btn-sm" onClick={e => { editar(row) }}><i className="fas fa-pencil-alt" />Alterar</button>
        <button className="btn btn-danger btn-sm ml-2" onClick={e => { excluir(row) }}><i className="far fa-trash-alt" />Excluir</button>

    </>)
      },
    },
  ];

   useEffect(() => {
    getListar();
  }, []);

  function getListar() { 
    setCaProcessa(true); 
  
    Api.get('site_programacao/listar').then((rps) => {
      
      setProgramacao(rps.data.obj);
      setCaProcessa(false);
    })
  }
  
  function adicionar(){
    setFormProgramacao({});
    setModal(true);
    setAcao('Adicionar');
  }

  function editar(id){  
    var ca = JSON.parse(JSON.stringify(id));
    setDataInicial(new Date(ca.dtInicio + ' 12:00:00'));
    setDataFinal(new Date(ca.dtFim + ' 12:00:00'));
    console.log(new Date(ca.dtFim + ' 12:00:00'));

    setAcao('Alterar');
    setFormProgramacao(ca);
    setModal(true);            
  }

  function excluir(id){
    var ca = JSON.parse(JSON.stringify(id));

    setCaProcessa(true);

    Swal.fire({
        icon: "question",
        title: 'Confirmação',
        html: 'Deseja excluir o registro?',
        showCancelButton: true,
        confirmButtonText: `Sim, Confirmar!`,
        cancelButtonText: 'Não, Cancelar.'
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Api.post('site_programacao/excluir', ca).then(rps => {
          
          if(rps.data.status==true){
            addToast(rps.data.mensagem, {
              appearance: "success",
              autoDismiss: true,
              autoDismissTimeout: 2000
            });
            getListar();
          } 
        })
      } 
      setCaProcessa(false);
    }) 
  }

  function salvar(){
    setCaProcessa(true);

    formProgramacao.dtInicio = dataInicial;
    formProgramacao.dtFim = dataFinal;

    Api.post('site_programacao/adicionar', formProgramacao).then(rps => {
      if (rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
        filtrar();
        setAcao('Alterar');
      } else {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: rps.data.erros,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
      setCaProcessa(false);
    });
  }

  function filtrar() {
    setCaProcessa(true); 
      var data = {
        nome: buscaNome,
        status: buscaStatus,
        dataInicial: buscaDataInicial,
        dataFinal: buscaDataFinal,
      };
    
      Api.post('site_programacao/listar', data).then((rps) => {

        if(rps.data.status==false){
          Swal.fire({
            title: "Atenção!",
            icon: "info",
            html: rps.data.erro,
            showCloseButton: true,
            showCancelButton: false,
          });
        } else {
          setProgramacao(rps.data.obj);
        }
      setCaProcessa(false); 
    });
  }

  function alterarFoto(){  
    
    setFotoModal(true);            
  }


  const inputRef = useState();
  const cropperRef = useState();

  const [image, setImage] = useState("");

  const onUpload = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };
  const onCrop = () => {
    const cropper = cropperRef.current;
    if (cropper) {
      const canvas = cropper.getCanvas();
      //console.log(canvas.toDataURL())

      var data = {
        imagem: canvas.toDataURL(),
        id_programacao: formProgramacao.id
      }
      
      Api.post('site_programacao/foto2', data).then(rps => {
        if (rps.data.status === true) {
          addToast(rps.data.mensagem, {
            appearance: "success",
            autoDismiss: true,
            autoDismissTimeout: 2000
          });
          getListar();
          setAcao('Alterar');
        } else {
          Swal.fire({
            title: "Erro!",
            icon: "error",
            html: rps.data.erros,
            showCloseButton: true,
            showCancelButton: false,
          });
        }
      });
    }
  };

  const onLoadImage = (event) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      setImage(URL.createObjectURL(file));
    }
    event.target.value = "";
  };

  useEffect(() => {
    // Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
    return () => {
      if (image) {
        URL.revokeObjectURL(image);
      }
    };
  }, [image]);

  const [ coordenadas, setCoordenadas ] = useState(null);
  const [ canvas, setCanvas ] = useState(null);

  function onChangeCrop(cropper){
    console.log(cropper.getCoordinates(), cropper.getCanvas());
    setCoordenadas(cropper.getCoordinates())
    setCanvas(cropper.getCanvas())
  }
  
  async function handleImgBannerChange(e) {
    const form_data = new FormData();
    form_data.append('arquivo', e.target.files[0]);
    form_data.append('id', formProgramacao.id);
      
    const cf = {
        baseURL: Api.defaults.baseURL,
        headers: {
          'content-type': 'multipart/form-data',
          'Authorization' : Api.defaults.headers['Authorization']
      }
    }
    
    const response = await Axios.post('site_programacao/imgbanner', form_data, cf);
    if (response.data.status !== undefined && response.data.status === true) {
      addToast(response.data.mensagem, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 5000
      });
      setFormProgramacao({...formProgramacao, imgBanner: response.data.url_imagem});
    } else {
      Swal.fire({
        title: "Atenção!",
        icon: "info",
        html: response.data.erros,
        showCloseButton: true,
        showCancelButton: false
      });
    }
  }

  async function handleImgHomeChange(e) {
    const form_data = new FormData();
    form_data.append('arquivo', e.target.files[0]);
    form_data.append('id', formProgramacao.id);
      
    const cf = {
        baseURL: Api.defaults.baseURL,
        headers: {
          'content-type': 'multipart/form-data',
          'Authorization' : Api.defaults.headers['Authorization']
      }
    }
    
    const response = await Axios.post('site_programacao/imghome', form_data, cf);
    if (response.data.status !== undefined && response.data.status === true) {
      addToast(response.data.mensagem, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 5000
      });
      setFormProgramacao({...formProgramacao, imgHome: response.data.url_imagem});
    } else {
      Swal.fire({
        title: "Atenção!",
        icon: "info",
        html: response.data.erros,
        showCloseButton: true,
        showCancelButton: false
      });
    }
  }

  async function handleImgEmailChange(e) {
    const form_data = new FormData();
    form_data.append('arquivo', e.target.files[0]);
    form_data.append('id', formProgramacao.id);
      
    const cf = {
        baseURL: Api.defaults.baseURL,
        headers: {
          'content-type': 'multipart/form-data',
          'Authorization' : Api.defaults.headers['Authorization']
      }
    }
    
    const response = await Axios.post('site_programacao/imgemail', form_data, cf);
    if (response.data.status !== undefined && response.data.status === true) {
      addToast(response.data.mensagem, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 5000
      });
      setFormProgramacao({...formProgramacao, imgEmail: response.data.url_imagem});
    } else {
      Swal.fire({
        title: "Atenção!",
        icon: "info",
        html: response.data.erros,
        showCloseButton: true,
        showCancelButton: false
      });
    }
  }

    return (
        <>
<div>

<div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mt-7 mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mr-5">Site</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mr-5 bg-gray-200" />
              {/*end::Separator*/}
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mr-5">Programação</h5>
              {/*end::Title*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center">
              <button className="btn btn-md btn-success mt-6" onClick={e => {adicionar()}}><i className="fas fa-plus" />Adicionar</button>
            </div>
            {/*end::Toolbar*/}
          </div>
        </div>
        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">

            <div className="card card-custom">
              <div className="ml-10 mr-10 mt-10">    
                <div className="row">

                <div className="form-group col-md-4">
                  <label>Nome</label>
                  <input type="text" value={buscaNome} onChange={e => {setBuscaNome(e.target.value)}} className="form-control" />
                </div>

                <div className="form-group col-md-2">
                  <label>Data Inícial</label>
                  <DatePicker 
                    name="dt_inicio"
                    onChange={setBuscaDataInicial}
                    isClearable
                    selectsStart
                    locale="pt-BR"
                    className="form-control"
                    placeholderText="dd/mm/aaaa" 
                    selected ={buscaDataInicial}
                    dateFormat="dd/MM/y"
                    startDate={buscaDataInicial}
                    endDate={buscaDataFinal}
                    customInput={
                      <InputMask mask="99/99/9999" />}/>
                </div>

                <div className="form-group col-md-2">
                  <label>Data Final</label>
                  <DatePicker 
                    name="dt_final"
                    onChange={setBuscaDataFinal}
                    isClearable
                    locale="pt-BR"
                    className="form-control"
                    placeholderText="dd/mm/aaaa" 
                    selected ={buscaDataFinal}
                    dateFormat="dd/MM/y"
                    selectsEnd
                    startDate={buscaDataInicial}
                    endDate={buscaDataFinal}
                    minDate={buscaDataInicial} 
                    customInput={
                      <InputMask mask="99/99/9999" />}/>
                </div>

                <div className="form-group col-md-2">
                  <label>Ativo</label>
                  <select className="form-control" value={buscaStatus} 
                    onChange={(e) => {setBuscaStatus(e.target.value)}}>
                      <option value="" selected>Selecione</option>    
                      <option value="S">Sim</option>    
                      <option value="N">Não</option>     
                  </select>
                </div>

                <div className="col-md-2 form-group">
                  <label>&nbsp;</label><br />
                    <button className="btn btn-primary btn-block" onClick={e=>{filtrar()}}><i className="flaticon2-search-1" />Pesquisar</button>
                  </div> 
                </div> 
              </div>

              <LoadingOverlay
                active={caProcessa}
                spinner
                text='Carregando...'
              >     
              
              <DataTable
                  title="Programação"
                  columns={columns}
                  data={data}
                  striped="true" 
                  pagination="true"
                />
              </LoadingOverlay>                    
      
                  </div>
                  </div>
                  </div>
                  </div>
                  
      <Modal size={'xl'} show={modal} onHide={() => fecharModal()}>
      <Modal.Header>
      <Modal.Title>{acao} Programação</Modal.Title>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => {fecharModal()}}>
            <i aria-hidden="true" className="ki ki-close" />
          </button>
      </Modal.Header>
      <div className="row mt-5 ml-5 mr-5">
        <div className="form-group col-md-6">
          <label>Nome <span class="text-danger">*</span></label>
          <input type="text" value={formProgramacao?.nome} onChange={e => {setFormProgramacao({...formProgramacao, nome: e.target.value})}} className="form-control" />
        </div>

        <div className="form-group col-md-6">
          <label>Url <span class="text-danger">*</span></label>
          <input type="text" value={formProgramacao?.url} onChange={e => {setFormProgramacao({...formProgramacao, url: e.target.value})}} className="form-control" />
        </div>
      </div>

      <div className="row ml-5 mr-5">
        <div className="col-md-2 form-group">
          <label>Data Início</label>
          <div className="input-group">
          <DatePicker 
            onChange={setDataInicial}
            isClearable
            selectsStart
            locale="pt-BR"
            className="form-control"
            placeholderText="dd/mm/aaaa" 
            selected ={dataInicial}
            dateFormat="dd/MM/y"
            startDate={dataInicial}
            endDate={dataFinal}
            customInput={
              <InputMask mask="99/99/9999" />}/>
          </div>
        </div>

        <div className="col-md-2 form-group">
          <label>Data Final</label>
          <div className="input-group">
          <DatePicker 
            onChange={setDataFinal}
            isClearable
            locale="pt-BR"
            className="form-control"
            placeholderText="dd/mm/aaaa" 
            selected ={dataFinal}
            dateFormat="dd/MM/y"
            selectsEnd
            startDate={dataInicial}
            endDate={dataFinal}
            minDate={dataInicial} 
            customInput={
              <InputMask mask="99/99/9999" />}/>
          </div>
        </div>

        <div className="form-group col">
          <label>Site <span class="text-danger">*</span></label>
          <select className="form-control" value={formProgramacao.blSite} 
            onChange={(e) => { setFormProgramacao({...formProgramacao, blSite: e.target.value}) }}>
              <option value="" selected>Selecione</option>    
              <option value="S">Sim</option>    
              <option value="N">Não</option>     
          </select>
        </div>

        <div className="form-group col">
          <label>Pacote <span class="text-danger">*</span></label>
          <select className="form-control" value={formProgramacao.blPacote} 
            onChange={(e) => { setFormProgramacao({...formProgramacao, blPacote: e.target.value}) }}>
              <option value="" selected>Selecione</option>    
              <option value="S">Sim</option>    
              <option value="N">Não</option>     
          </select>
        </div>

        <div className="form-group col">
          <label>Reserva <span class="text-danger">*</span></label>
          <select className="form-control" value={formProgramacao.blReserva} 
            onChange={(e) => { setFormProgramacao({...formProgramacao, blReserva: e.target.value}) }}>
              <option value="" selected>Selecione</option>    
              <option value="S">Sim</option>    
              <option value="N">Não</option>     
          </select>
        </div>

        <div className="form-group col">
          <label>Cotação <span class="text-danger">*</span></label>
          <select className="form-control" value={formProgramacao.blCotacaoOnline} 
            onChange={(e) => { setFormProgramacao({...formProgramacao, blCotacaoOnline: e.target.value}) }}>
              <option value="" selected>Selecione</option>    
              <option value="S">Sim</option>    
              <option value="N">Não</option>     
          </select>
        </div>

        <div className="form-group col">
          <label>Ativa <span class="text-danger">*</span></label>
          <select className="form-control" value={formProgramacao.ativo} 
            onChange={(e) => { setFormProgramacao({...formProgramacao, ativo: e.target.value}) }}>
              <option value="" selected>Selecione</option>    
              <option value="S">Sim</option>    
              <option value="N">Não</option>     
          </select>
        </div>

        <div className="form-group col">
          <label>Ocultar <span class="text-danger">*</span></label>
          <select className="form-control" value={formProgramacao.blOcultar} 
            onChange={(e) => { setFormProgramacao({...formProgramacao, blOcultar: e.target.value}) }}>
              <option value="" selected>Selecione</option>    
              <option value="S">Sim</option>    
              <option value="N">Não</option>     
          </select>
        </div>

        <div className="form-group col">
          <label>Meta <span class="text-danger">*</span></label>
          <input type="text" value={formProgramacao?.meta} onChange={e => {setFormProgramacao({...formProgramacao, meta: e.target.value})}} className="form-control" />
        </div>

        <div className="form-group col">
          <label>Free <span class="text-danger">*</span></label>
          <input type="text" value={formProgramacao?.chdFree} onChange={e => {setFormProgramacao({...formProgramacao, chdFree: e.target.value})}} className="form-control" />
        </div>
      </div>

      <div className="row ml-5 mr-5">
        <div className="form-group col-md-6">
          <label>Frase (Página Principal)</label>
          <input type="text" value={formProgramacao?.frase} onChange={e => {setFormProgramacao({...formProgramacao, frase: e.target.value})}} className="form-control" />
        </div>

        <div className="form-group col-md-6">
          <label>Video</label>
          <input type="text" value={formProgramacao?.video} onChange={e => {setFormProgramacao({...formProgramacao, video: e.target.value})}} className="form-control" />
        </div>
      </div>


      <div className="row ml-5 mr-5">
        <div className="form-group col-md-12">
          <label>Descrição Site</label>
          <CKEditor
            editor={ ClassicEditor }
            data={formProgramacao.descricaoSite}
            onChange={( event, editor ) => { setFormProgramacao({...formProgramacao, descricaoSite: editor.getData()}) }}            
          />
        </div>
      </div>

      <div className="row ml-5 mr-5">
        <div className="form-group col-md-12">
          <label>Descrição E-mail</label>
          <CKEditor
            editor={ ClassicEditor }
            data={formProgramacao.descricaoEmail}
            onChange={( event, editor ) => { setFormProgramacao({...formProgramacao, descricaoEmail: editor.getData()}) }}            
          />
        </div>
      </div>

      <div className="row ml-5 mr-5">
        <div className="form-group col-md-12">
          <label>Turminha do Bem</label>
          <CKEditor
            editor={ ClassicEditor }
            data={formProgramacao.txtTurminha}
            onChange={( event, editor ) => { setFormProgramacao({...formProgramacao, txtTurminha: editor.getData()}) }}            
          />
        </div>
      </div>

      <div className="row ml-5 mr-5">
        <div className="form-group col-md-12">
          <label>Condições de Pagamento</label>
          <CKEditor
            editor={ ClassicEditor }
            data={formProgramacao.txtCondicoes}
            onChange={( event, editor ) => { setFormProgramacao({...formProgramacao, txtCondicoes: editor.getData()}) }}            
          />
        </div>
      </div>

      { acao == 'Alterar' && <>
      <div className="row ml-5 mr-5">
        <div className="from-group col-md-6">
          <label>Banner Programação <small className='text-danger'>*Formato jpg*</small></label>
          <div className="area-imagem dropzone--squared menu-category-drawer-item-details-tab__image">
            <div className="dropzone__area" aria-disabled="false" style={{ position: 'relative' }}
              onClick={e => { refImgBanner.current.click() }} >
              <div className="dropzone-preview dropzone__preview">
                <img className="dropzone-preview__image" src={formProgramacao?.imgBanner ? "https://motor.hotelfazendasaojoao.com.br/assets/img/programacoes/"+formProgramacao?.imgBanner : "/img/no-image.jpg"} alt="" />
              </div>
              <div className="dropzone-placeholder dropzone__placeholder">
                <span>Trocar imagem</span>
              </div>
              <input type="file" ref={refImgBanner} name="imgBanner" onChange={(e) => handleImgBannerChange(e)} accept=".png, .jpg, .jpeg" className="classeInputImage" />
            </div>
          </div>
        </div>
        
        <div className="from-group col-md-3">
          <label>Home - (Avatar) <small className='text-danger'>*Formato jpg*</small></label>
          <div className="area-imagem dropzone--squared menu-category-drawer-item-details-tab__image">
            <div className="dropzone__area" aria-disabled="false" style={{ position: 'relative' }}
              onClick={e => { refImgHome.current.click() }} >
              <div className="dropzone-preview dropzone__preview">
                <img className="dropzone-preview__image" src={formProgramacao?.imgHome ? "https://motor.hotelfazendasaojoao.com.br/assets/img/programacoes/"+formProgramacao?.imgHome : "/img/no-image.jpg"} alt="" />
              </div>
              <div className="dropzone-placeholder dropzone__placeholder">
                <span>Trocar imagem</span>
              </div>
              <input type="file" ref={refImgHome} name="imgHome" onChange={(e) => handleImgHomeChange(e)} accept=".png, .jpg, .jpeg" className="classeInputImage" />
            </div>
          </div>
        </div>

        <div className="from-group col-md-3">
          <label>E-mail <small className='text-danger'>*Formato jpg*</small></label>
          <div className="area-imagem dropzone--squared menu-category-drawer-item-details-tab__image">
            <div className="dropzone__area" aria-disabled="false" style={{ position: 'relative' }}
              onClick={e => { refImgEmail.current.click() }} >
              <div className="dropzone-preview dropzone__preview">
                <img className="dropzone-preview__image" src={formProgramacao?.imgEmail ? "https://motor.hotelfazendasaojoao.com.br/assets/img/programacoes/email/"+formProgramacao?.imgEmail : "/img/no-image.jpg"} alt="" />
              </div>
              <div className="dropzone-placeholder dropzone__placeholder">
                <span>Trocar imagem</span>
              </div>
              <input type="file" ref={refImgEmail} name="imgEmail" onChange={(e) => handleImgEmailChange(e)} accept=".png, .jpg, .jpeg" className="classeInputImage" />
            </div>
          </div>
        </div>
                
      </div>
      </>
       }   
      
      <Modal.Footer>
        <button type="button" onClick={e => { fecharModal(); }} className="btn btn-secondary font-weight-bold" data-dismiss="modal"><i className="fas fa-door-open" />Fechar</button>
        <button type="button" onClick={e => { salvar(); }} className="btn btn-success font-weight-bold"><i className="fas fa-save" />Salvar</button>
      </Modal.Footer>
    </Modal>

    <Modal size={'xl'} show={fotoModal} onHide={() => fecharFotoModal()}>
      <Modal.Header>
      <Modal.Title>{acao} Programação</Modal.Title>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => {fecharFotoModal()}}>
            <i aria-hidden="true" className="ki ki-close" />
          </button>
      </Modal.Header>
      <div className="row mt-5 ml-5 mr-5">
      
      <div className="container">
      <div className="example__cropper-wrapper">
        <Cropper
          ref={cropperRef}
          className="example__cropper"
          backgroundClassName="example__cropper-background"
          src={image}
          maxHeight={480}
          minHeight={480}
          minWidth={640}
          maxWidth={640}
        />
      </div>
      <div className="example__buttons-wrapper">
        <button className="btn btn-secondary" onClick={onUpload}>
          <input
            ref={inputRef}
            type="file"
            accept="image/*"
            onChange={onLoadImage}
          />
        </button>
      </div>
    </div>


      </div>      
      <Modal.Footer>
        <button type="button" onClick={e => { fecharFotoModal(); }} className="btn btn-secondary font-weight-bold" data-dismiss="modal"><i className="fas fa-door-open" />Fechar</button>
        {image && (
          <button type="button" onClick={e => { onCrop(); }} className="btn btn-success font-weight-bold"><i className="fas fa-save" />Salvar</button>
        )}
      </Modal.Footer>
    </Modal>        
                 
        </>
    );
}

export default withRouter(SProgramacao);                  