import React, { useState, useEffect } from 'react';
import Api from '../../../services/api';
import { withRouter } from "react-router";

import Swal from "sweetalert2";
import { useToasts } from 'react-toast-notifications';
import DataTable from 'react-data-table-component';
import LoadingOverlay from 'react-loading-overlay';
import { Link } from 'react-router-dom';

import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import InputMask from 'react-input-mask';
import pt from "date-fns/locale/pt-BR"; 
registerLocale("pt-BR", pt);

const CotacaoAndamento = () => {
  const { addToast } = useToasts();

  const [ cotacoes, setCotacoes ] = useState([]);
  const [ programacoes, setProgramacoes ] = useState([]);
  const [ carregando, setCarregando ] = useState(false);
  const [ dataEnvioInicial, setDataEnvioInicial ] = useState();
  const [ dataEnvioFinal, setDataEnvioFinal ] = useState();
  const [ dataPeriodoInicial, setDataPeriodoInicial ] = useState();
  const [ dataPeriodoFinal, setDataPeriodoFinal ] = useState();
  const [ pacote, setPacote ] = useState();
  const [ proposta, setProposta ] = useState();
  const [ nome, setNome ] = useState();
  const [ email, setEmail ] = useState();
  const [ diaria, setDiaria ] = useState();

  const data = cotacoes;
  const columns = [
    {
      name: <th>Cotação</th>,
      selector: 'id',
      sortable: true,
      width: '6%',
      center: true,
    },
    {
      name: <th>Data</th>,
      selector: 'dataEnvio',
      sortable: true,
      width: '9%',
    },
    {
      name: <th>Programação</th>,
      selector: 'nProgramacao',
      sortable: true,
      width: '12%',
    },
    {
      name: <th>Nome</th>,
      selector: 'nome',
      sortable: true,
      width: '15%',
    },
    {
      name: <th>Diárias</th>,
      selector: 'diarias',
      sortable: true,
      width: '7%',
      center: 'true',
    },
    {
      name: <th>Adultos</th>,
      selector: 'adultos',
      sortable: true,
      width: '7%',
      center: 'true',
    },
    {
      name: <th>Ch 12</th>,
      selector: 'chd_12',
      sortable: true,
      width: '7%',
      center: 'true',
    },
    {
      name: <th>Ch 14</th>,
      selector: 'chd_14',
      sortable: true,
      width: '7%',
      center: 'true',
    },
    {
      name: <th>Pet</th>,
      selector: 'pet',
      sortable: true,
      width: '7%',
      center: 'true',
    },
    {
      name: <th>Situação</th>,
      selector: 'status',
      sortable: true,
      width: '7%',
      center: 'true',
    },
    {
      name: <th>Leituras</th>,
      selector: 'leituras',
      sortable: true,
      width: '7%',
      center: 'true',
    },
    {
      name: <th>Ações</th>,
      selector: 'nome_campo',
      width: '8%',
      sortable: true,
      cell: (row) => 
      { return (<>
        <Link to='/cotacoes/andamento/detalhe/' className="btn btn-success btn-sm mr-2" onClick={e => { sesaoCotacao(row.id) }}>Abrir</Link>
      </>)
      }
    },

  ];

  useEffect(() => {
    getListar();
    cotacaoAndamento();
  }, []);

  function sesaoCotacao($id) {
    sessionStorage.setItem('cotacao', $id);
  }

  function getListar() {
    Api.get("cotacoes/listaprogramacoes").then(rps => {    
      setProgramacoes(rps.data.prog);
    })
  }

  function cotacaoAndamento() {
    setCarregando(true);
    var data = {
      cotacao: proposta,
      dtInicial: dataEnvioInicial,
      dtFinal: dataEnvioFinal,
      checkin: dataPeriodoInicial,
      checkout: dataPeriodoFinal,
      diaria: diaria,
      nome: nome,
      email: email,
      pacote: pacote,
    }

    Api.post('cotacoes/cotacaoAndamento', data).then(rps => {
      if(rps.data.status===true){
        setCotacoes(rps.data.obj);
        } else {
            Swal.fire({
                title: "Atenção!",
                icon: "error",
                html: rps.data.mensagem,
                showCloseButton: true,
                showCancelButton: false,
            });
        }
        setCarregando(false);
    })
}
  
    return (
      <>
      {/*begin::Entry*/}
      <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
  
          <div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
            <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
              {/*begin::Details*/}
              <div className="d-flex align-items-center flex-wrap mr-2">
                {/*begin::Title*/}
                <h5 className="text-dark font-weight-bold mt-6 mb-2 mr-5">Cotações em Andamento</h5>
                {/*end::Title*/}
                {/*begin::Separator*/}
                <div className="subheader-separator subheader-separator-ver mt-6 mb-2 mr-5 bg-gray-200" />
                {/*end::Separator*/}
              </div>
              {/*end::Details*/}
            </div>
          </div>
          {/*begin::Entry*/}
          {/*begin card de pesquisa de vendas */}
            <div className="card card-custom">
              <div className="card-header">
                <h3 className="card-title">Pesquisar cotações</h3>
              </div>
            
              <div className="card-body">
                <div className="row">
                  <div className="col-md-1">
                    <label>Cotação</label>
                    <input type="text" className="form-control" value={proposta} onChange={(e)=> setProposta(e.target.value)}/>
                  </div>

                        <div className="col-md-2 form-group">
                            <label>Data Envio Inicial</label>
                            <div className="input-group">
                            <DatePicker 
                              onChange={setDataEnvioInicial}
                              isClearable
                              selectsStart
                              locale="pt-BR"
                              className="form-control"
                              placeholderText="dd/mm/aaaa" 
                              selected ={dataEnvioInicial}
                              dateFormat="dd/MM/y"
                              startDate={dataEnvioInicial}
                              endDate={dataEnvioFinal}
                              customInput={
                                <InputMask mask="99/99/9999" />}/>
                            </div>
                        </div>
                        <div className="col-md-2 form-group">
                            <label>Data Envio Final</label>
                            <div className="input-group">
                              <DatePicker 
                                onChange={setDataEnvioFinal}
                                isClearable
                                locale="pt-BR"
                                className="form-control"
                                placeholderText="dd/mm/aaaa" 
                                selected ={dataEnvioFinal}
                                dateFormat="dd/MM/y"
                                selectsEnd
                                startDate={dataEnvioInicial}
                                endDate={dataEnvioFinal}
                                minDate={dataEnvioInicial} 
                                customInput={
                                  <InputMask mask="99/99/9999" />}/>
                              </div>
                        </div>
                        <div className="col-md-2 form-group">
                            <label>Data Check In</label>
                            <div className="input-group">
                            <DatePicker 
                              onChange={setDataPeriodoInicial}
                              isClearable
                              selectsStart
                              locale="pt-BR"
                              className="form-control"
                              placeholderText="dd/mm/aaaa" 
                              selected ={dataPeriodoInicial}
                              dateFormat="dd/MM/y"
                              startDate={dataPeriodoInicial}
                              endDate={dataPeriodoFinal}
                              customInput={
                                <InputMask mask="99/99/9999" />}/>
                            </div>
                        </div>
                        <div className="col-md-2 form-group">
                            <label>Data Check Out</label>
                            <div className="input-group">
                              <DatePicker 
                                onChange={setDataPeriodoFinal}
                                isClearable
                                locale="pt-BR"
                                className="form-control"
                                placeholderText="dd/mm/aaaa" 
                                selected ={dataPeriodoFinal}
                                dateFormat="dd/MM/y"
                                selectsEnd
                                startDate={dataPeriodoInicial}
                                endDate={dataPeriodoFinal}
                                minDate={dataPeriodoInicial} 
                                customInput={
                                  <InputMask mask="99/99/9999" />}/>
                              </div>
                        </div>

                        <div className="col-md-3 form-group">
                            <label>Programação</label>
                            <select className="form-control" value={pacote} onChange={(e)=> setPacote(e.target.value)}>
                                <option value="">Selecione...</option>
                                {programacoes.map((item) =>{ return(
                                <option value={item.id}>{item.nome}</option>
                            )})}
                            </select>
                        </div>
                    </div>
                  
                  <div className="row">
                    <div className="col-md-4">
                      <label>Nome</label>
                      <input type="text" className="form-control" value={nome} onChange={(e)=> setNome(e.target.value)}/>
                    </div>

                    <div className="col-md-4">
                      <label>E-mail</label>
                      <input type="email" className="form-control" value={email} onChange={(e)=> setEmail(e.target.value)}/>
                    </div>

                    <div className="col-md-4">
                      <label>Diária</label>
                      <input type="text" className="form-control" value={diaria} onChange={(e)=> setDiaria(e.target.value)}/>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-12 text-right">
                      <button className="btn btn-primary" onClick={e=>{cotacaoAndamento()}}><i class="flaticon2-search-1" />Pesquisar</button>
                    </div>
                  </div>
                </div> 
            </div>                         
          <br />

          <LoadingOverlay
            active={carregando}
            spinner
            text='Carregando...'
          >
            <DataTable
              title="Lista das Cotações em Andamento"
              columns={columns}
              data={data}
              striped="true"
              pagination="true"
            />    
          </LoadingOverlay>
          </div>
        </div>   
          
               
        </>
    );

}



export default withRouter(CotacaoAndamento);                  