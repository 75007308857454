const setSessionInStorage = (data) => {
    try {
        sessionStorage.setItem('id_estabelecimento', '1')//data.usuario.id_estabelecimento)
        sessionStorage.setItem('id_usuario', data.usuario.id_usuario)
        sessionStorage.setItem('id_perfil', data.usuario.id_perfil)
        sessionStorage.setItem('nome', data.usuario.nome)
        sessionStorage.setItem('hash', '1')//data.usuario.sessao.hash)
        sessionStorage.setItem('id_sessao', '2')//data.usuario.sessao.id_sessao)
        //sessionStorage.setItem('url_socket', data.usuario.socket.url)
        //sessionStorage.setItem('canal_socket', data.usuario.socket.canal)
        return true;
    } catch (error) {
        console.log('Erro ao armazenar session 1')
        return error
    }
}

const setLocalStorage = (data) => {
    try {
        localStorage.setItem('id_estabelecimento', '1')//data.usuario.id_estabelecimento)
        localStorage.setItem('id_usuario', data.usuario.id_usuario)
        localStorage.setItem('id_perfil', data.usuario.id_perfil)
        localStorage.setItem('nome', data.usuario.nome)
        localStorage.setItem('hash', '1')//data.usuario.sessao.hash)
        localStorage.setItem('id_sessao', '2')//data.usuario.sessao.id_sessao)
        //localStorage.setItem('url_socket', data.usuario.socket.url)
        //localStorage.setItem('canal_socket', data.usuario.socket.canal)

        return true;
    } catch (error) {
        console.log('Erro ao armazenar session 2')
        return error
    }
}

export { setSessionInStorage, setLocalStorage} 