import React, { useState, useEffect } from 'react';
import Api from '../../../services/api';
import { withRouter } from "react-router";

import Swal from "sweetalert2";
import LoadingOverlay from 'react-loading-overlay';

import DataTable from 'react-data-table-component';

import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import InputMask from 'react-input-mask';
import pt from "date-fns/locale/pt-BR"; 
import { Link } from 'react-router-dom';
registerLocale("pt-BR", pt);

const Interesse = () => {

  const [ pessoas, setPessoas ] = useState([]);
  const [ totalRegistros, setTotalRegistros ] = useState();
  const [ programacoes, setProgramacoes ] = useState([]);
  const [ dataEnvioInicial, setDataEnvioInicial ] = useState(null);
  const [ dataEnvioFinal, setDataEnvioFinal ] = useState(null);
  const [ dataPeriodoInicial, setDataPeriodoInicial ] = useState(null);
  const [ dataPeriodoFinal, setDataPeriodoFinal ] = useState(null);
  const [ dataLigacaoInicial, setDataLigacaoInicial ] = useState(null);
  const [ dataLigacaoFinal, setDataLigacaoFinal ] = useState(null);
  const [ buscaNome, setBuscaNome ] = useState('');
  const [ buscaPacote, setBuscaPacote ] = useState('');
  const [ buscaEmail, setBuscaEmail ] = useState('');
  const [ buscaStatus, setBuscaStatus ] = useState('N');
  const [ buscaCidade, setBuscaCidade ] = useState('');
  const [ caProcessa, setCaProcessa ] = useState(false);
  const [ proposta, setProposta ] = useState('');
  const [ ordenacao, setOrdenacao ] = useState('id');
  const [ ordenacaoSet, setOrdenacaoSet ] = useState();
  const [ currentPage, setCurrentPage ] = useState(1);
  
  const PER_PAGE = 10;
  const pageCount = Math.ceil(totalRegistros / PER_PAGE);

  const data = pessoas;
  const columns = [
    {
      name: <th>Cotação</th>,
      bold: true,
      selector: 'id',
      sortable: true,
      width: '8%',
      center: true
    },
    {
      name: <th>Nome</th>,
      bold: true,
      selector: 'nome',
      sortable: true,
      width: '20%',
    },
    {
      name: <th>Cidade</th>,
      selector: 'nCidade',
      width: '12%',
      sortable: true,
    },
    {
      name: <th>Cotações</th>,
      selector: 'numeroCotacoesAndamento',
      width: '8%',
      sortable: true,
      center: true,
    },
    {
      name: <th>Ultima Cotação</th>,
      selector: 'nome_campo',
      width: '12%',
      sortable: true,
     },
    {
      name: <th>Leituras</th>,
      selector: 'leituras',
      width: '8%',
      sortable: true,
      center: true,
    },
    {
      name: <th>Ultima Ligação</th>,
      selector: 'dataUltimaLigacao',
      width: '12%',
      sortable: true,
      center: true,
    },
    {
      name: <th>Ligação Positiva</th>,
      selector: 'dataUltimaLigacaoPositiva',
      width: '12%',
      sortable: true,
      center: true,
    },
    {
      name: <th>Ações</th>,
      selector: 'nome_campo',
      width: '8%',
      sortable: true,
      cell: (row) => 
      { return (<>
        <Link to='/cotacoes/andamento/detalhe/' className="btn btn-success btn-sm mr-2" onClick={e => { sesaoCotacao(row.id) }}>Abrir</Link>
      </>)
      }
    },
  ];

  useEffect(() => {
    buscar();
    getProgramacoes();
  }, []);

  function sesaoCotacao($id) {  
    sessionStorage.setItem('cotacao', $id);
    sessionStorage.setItem('voltar','interesse');
  }

  function buscar(clicou) {
    
    if (sessionStorage.getItem('voltar') !== 'voltou' || clicou == 'Sim') {
      sessionStorage.setItem('interesse_buscaProposta', proposta !== undefined ? proposta : '');
      sessionStorage.setItem('interesse_dataEnvioInicial', dataEnvioInicial !== undefined ? dataEnvioInicial : '');
      sessionStorage.setItem('interesse_dataEnvioFinal', dataEnvioFinal !== undefined ? dataEnvioFinal : '');
      sessionStorage.setItem('interesse_buscaNome', buscaNome !== undefined ? buscaNome : '');
      sessionStorage.setItem('interesse_buscaPacote', buscaPacote !== undefined ? buscaPacote : '');
      sessionStorage.setItem('interesse_dataPeriodoInicial', dataPeriodoInicial !== undefined ? dataPeriodoInicial : '');
      sessionStorage.setItem('interesse_dataPeriodoFinal', dataPeriodoFinal !== undefined ? dataPeriodoFinal : '');
      sessionStorage.setItem('interesse_buscaEmail', buscaEmail !== undefined ? buscaEmail : '');
      sessionStorage.setItem('interesse_buscaStatus', buscaStatus !== undefined ? buscaStatus : '');
      sessionStorage.setItem('interesse_dataLigacaoInicial', dataLigacaoInicial !== undefined ? dataLigacaoInicial : '');
      sessionStorage.setItem('interesse_dataLigacaoFinal', dataLigacaoFinal !== undefined ? dataLigacaoFinal : '');
      sessionStorage.setItem('interesse_buscaCidade', buscaCidade !== undefined ? buscaCidade : '');   
      setCurrentPage(1);
    } else {
      setProposta(sessionStorage.getItem('interesse_buscaProposta'));
      setDataEnvioInicial(sessionStorage.getItem('interesse_dataEnvioInicial') !== null && sessionStorage.getItem('interesse_dataEnvioInicial') !== "null" && sessionStorage.getItem('interesse_dataEnvioInicial') !== "" ? new Date(sessionStorage.getItem('interesse_dataEnvioInicial')) : null);
      setDataEnvioFinal(sessionStorage.getItem('interesse_dataEnvioFinal') !== null && sessionStorage.getItem('interesse_dataEnvioFinal') !== "null" && sessionStorage.getItem('interesse_dataEnvioFinal') !== "" ? new Date(sessionStorage.getItem('interesse_dataEnvioFinal')) : null);
      setBuscaNome(sessionStorage.getItem('interesse_buscaNome'));
      setBuscaPacote(sessionStorage.getItem('interesse_buscaPacote'));
      setDataPeriodoInicial(sessionStorage.getItem('interesse_dataPeriodoInicial') !== null && sessionStorage.getItem('interesse_dataPeriodoInicial') !== "null" && sessionStorage.getItem('interesse_dataPeriodoInicial') !== "" ? new Date(sessionStorage.getItem('interesse_dataPeriodoInicial')) : null);
      setDataPeriodoFinal(sessionStorage.getItem('interesse_dataPeriodoFinal') !== null && sessionStorage.getItem('interesse_dataPeriodoFinal') !== "null" && sessionStorage.getItem('interesse_dataPeriodoFinal') !== "" ? new Date(sessionStorage.getItem('interesse_dataPeriodoFinal')) : null);
      setBuscaEmail(sessionStorage.getItem('interesse_buscaEmail'));
      setBuscaStatus(sessionStorage.getItem('interesse_buscaStatus'));
      setDataLigacaoInicial(sessionStorage.getItem('interesse_dataLigacaoInicial') !== null && sessionStorage.getItem('interesse_dataLigacaoInicial') !== "null" && sessionStorage.getItem('interesse_dataLigacaoInicial') !== "" ? new Date(sessionStorage.getItem('interesse_dataLigacaoInicial')) : null);
      setDataLigacaoFinal(sessionStorage.getItem('interesse_dataLigacaoFinal') !== null && sessionStorage.getItem('interesse_dataLigacaoFinal') !== "null" && sessionStorage.getItem('interesse_dataLigacaoFinal') !== "" ? new Date(sessionStorage.getItem('interesse_dataLigacaoFinal')) : null);
      setBuscaCidade(sessionStorage.getItem('interesse_buscaCidade'));
    }
    getListar();
  }

  function getListar(order) {

    setCaProcessa(true); 
    
    var sentido = 'DESC';

    if (order != null) {
      setOrdenacao(order);
    } else {
      //Valor padrão para a primeira vez
      setOrdenacao('id');
      order = 'id';
    }

    if (order === ordenacao) {
      //Caso a order atual seja igual a anterior
      if (ordenacaoSet === 'DESC' || ordenacaoSet === null) {
        //Verifico se estou em ordem DESC e inverto para ASC
        sentido = 'ASC';
        setOrdenacaoSet('ASC');
      } else {
        sentido = 'DESC';
        setOrdenacaoSet('DESC');
      }
    } else {
      //Caso a ordem atual não seja igual a anterior ASC
      setOrdenacaoSet('ASC');
    }

    if (sessionStorage.getItem('interesse_buscaProposta') == '') {
      var Rproposta = '';
    } else { 
      var Rproposta = sessionStorage.getItem('interesse_buscaProposta'); 
      //setBuscaNome(sessionStorage.getItem('interesse_buscaNome'));
    }

    if (sessionStorage.getItem('interesse_dataEnvioInicial') == 'null' || sessionStorage.getItem('interesse_dataEnvioInicial') == 'undefined') {
      var RdataEnvioInicial = '';
    } else { 
      var RdataEnvioInicial = new Date(sessionStorage.getItem('interesse_dataEnvioInicial'));
      //setDataEnvioInicial(new Date(sessionStorage.getItem('interesse_dataEnvioInicial'))); 
    }

    if (sessionStorage.getItem('interesse_dataEnvioFinal') == 'null' || sessionStorage.getItem('interesse_dataEnvioFinal') == 'undefined') {
      var RdataEnvioFinal = '';
    } else { 
      var RdataEnvioFinal = new Date(sessionStorage.getItem('interesse_dataEnvioFinal')); 
      //setDataEnvioFinal(new Date(sessionStorage.getItem('interesse_dataEnvioFinal')));
    }

    if (sessionStorage.getItem('interesse_buscaNome') == '') {
      var Rnome = '';
    } else { 
      var Rnome = sessionStorage.getItem('interesse_buscaNome'); 
      //setBuscaNome(sessionStorage.getItem('interesse_buscaNome'));
    }

    if (sessionStorage.getItem('interesse_buscaPacote') == '') {
      var Rpacote = '';
    } else { 
      var Rpacote = sessionStorage.getItem('interesse_buscaPacote');
      //setBuscaPacote(sessionStorage.getItem('interesse_buscaPacote'));  
    }

    if (sessionStorage.getItem('interesse_dataPeriodoInicial') == 'null' || sessionStorage.getItem('interesse_dataPeriodoInicial') == 'undefined') {
      var RdataPeriodoInicial = '';
    } else {
      var RdataPeriodoInicial = new Date(sessionStorage.getItem('interesse_dataPeriodoInicial'));
      //setDataPeriodoInicial(new Date(sessionStorage.getItem('interesse_dataPeriodoInicial'))); 
    }

    if (sessionStorage.getItem('interesse_dataPeriodoFinal') == 'null' || sessionStorage.getItem('interesse_dataPeriodoFinal') == 'undefined') {
      var RdataPeriodoFinal = '';
    } else { 
      var RdataPeriodoFinal = new Date(sessionStorage.getItem('interesse_dataPeriodoFinal')); 
      //setDataPeriodoFinal(new Date(sessionStorage.getItem('interesse_dataPeriodoFinal')));
    }

    if (sessionStorage.getItem('interesse_buscaEmail') == '') {
      var Remail = '';
    } else { 
      var Remail = sessionStorage.getItem('interesse_buscaEmail');
      //setBuscaEmail(sessionStorage.getItem('interesse_buscaEmail')); 
    }

    if (sessionStorage.getItem('interesse_buscaStatus') == '') {
      var Rstatus = '';
    } else { 
      var Rstatus = sessionStorage.getItem('interesse_buscaStatus');
      //setBuscaStatus(sessionStorage.getItem('interesse_buscaStatus')); 
    }

    if (sessionStorage.getItem('interesse_dataLigacaoInicial') == 'null' || sessionStorage.getItem('interesse_dataLigacaoInicial') == 'undefined') {
      var RdataLigacaoInicial = '';
    } else { 
      var RdataLigacaoInicial = new Date(sessionStorage.getItem('interesse_dataLigacaoInicial'));
      //setDataLigacaoInicial(new Date(sessionStorage.getItem('interesse_dataLigacaoInicial'))); 
    }

    if (sessionStorage.getItem('interesse_dataLigacaoFinal') == 'null' || sessionStorage.getItem('interesse_dataLigacaoFinal') == 'undefined') {
      var RdataLigacaoFinal = '';
    } else { 
      var RdataLigacaoFinal = new Date(sessionStorage.getItem('interesse_dataLigacaoFinal')); 
      //setDataLigacaoFinal(new Date(sessionStorage.getItem('interesse_dataLigacaoFinal')));
    }

    if (sessionStorage.getItem('interesse_buscaCidade') == '') {
      var Rcidade = '';
    } else { 
      var Rcidade = sessionStorage.getItem('interesse_buscaCidade');
      //setBuscaCidade(sessionStorage.getItem('interesse_buscaCidade')); 
    }

    var data = {
      cotacao: Rproposta,
      dataEnvioInicial: RdataEnvioInicial,
      dataEnvioFinal: RdataEnvioFinal,
      nome: Rnome,
      pacote: Rpacote,
      dataPeriodoInicial: RdataPeriodoInicial,
      dataPeriodoFinal: RdataPeriodoFinal,
      email: Remail,
      status: Rstatus,
      dataLigacaoInicial: RdataLigacaoInicial,
      dataLigacaoFinal: RdataLigacaoFinal,
      cidade: Rcidade,
      limite: PER_PAGE, 
      start: 0,
      ordenacao: order,
      sentido: sentido,
    };

    console.log(data);
    
    Api.post("pessoas/interesse", data).then(rps => {   
      setPessoas(rps.data.obj);
      setTotalRegistros(rps.data.totalRegistros);
      
      setCaProcessa(false);
    })
  }

  function getProgramacoes() {
    Api.post("programacao/programacao", data).then(rps => {    
      setProgramacoes(rps.data.obj);
    })
  }

  function handlePageClick(pagina) {
    setCaProcessa(true);
    setCurrentPage(pagina);
    var data = {
      cotacao: proposta,
      dataEnvioInicial: dataEnvioInicial,
      dataEnvioFinal: dataEnvioFinal,
      nome: buscaNome,
      pacote: buscaPacote,
      dataPeriodoInicial: dataPeriodoInicial,
      dataPeriodoFinal: dataPeriodoFinal,
      email: buscaEmail,
      status: buscaStatus,
      dataLigacaoInicial: dataLigacaoInicial,
      dataLigacaoFinal: dataLigacaoFinal,
      cidade: buscaCidade,
      limite: PER_PAGE, 
      start: (pagina-1) * PER_PAGE,
      ordenacao: ordenacao,
      sentido: ordenacaoSet,
    }
    
    Api.post("pessoas/interesse", data).then(rps => { 
      setPessoas(rps.data.obj);
      setCaProcessa(false);
    })
  }

  class Paginacao extends React.Component{
    renderRow(row){
      return (<span>{row}</span>)
    }
    render(){
      let rows = []

      //Registro Anterior, caso pagina correta seja diferente de 1 o botão fica ativo
      if (currentPage != 1) {
        rows.push(<a onClick={() => handlePageClick(currentPage-1)} class="btn btn-icon btn-sm btn-light-info mr-2 my-1">
                    <i class="ki ki-bold-arrow-back icon-xs"></i>
                  </a>);
      } else {
        rows.push(<span class="btn-icon btn-sm btn-secondary disabled mr-2 my-1">
                    <i class="ki ki-bold-arrow-back icon-xs"></i>
                  </span>)
      }

      //Quanto existe mais de 3 páginas estou exibindo o botão [1] e [...], envia para a página 1.
      if (currentPage > 3) {
        rows.push(<>
                    <a onClick={() => handlePageClick(1)} class="btn btn-icon btn-sm btn-light-info mr-2 my-1">
                      1
                    </a>
                    <span class="btn-icon btn-sm btn-secondary disabled mr-2 my-1">
                      ...
                    </span>
                  </>
                );
      }

      //Executo um laço de repetição para exibir os numeros de páginas, limitando a memos e mais 3, tendo como base a página atual
      for(let i = currentPage-2; i < currentPage+3; i++) {
        if (i > 0 && i <= pageCount) {
          if (i == currentPage) {
            rows.push(<a onClick={() => handlePageClick(i)} className="btn btn-icon btn-sm border-0 btn-hover-primary active mr-2 my-1">{i}</a>)
          } else {
            rows.push(<a onClick={() => handlePageClick(i)} className="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1">{i}</a>)
          }
        }
      }

       //Quanto existe mais de 6 páginas estou exibindo o botão [...] e [Maior Numero], envia para a página MaiorNumero.
      if (currentPage+3 <= pageCount) {
        rows.push(<>
                    <span class="btn-icon btn-sm btn-secondary disabled mr-2 my-1">
                      ...
                    </span>
                    <a onClick={() => handlePageClick(pageCount)} class="btn btn-icon btn-sm btn-light-info mr-2 my-1">
                      {pageCount}
                    </a>
                  </>
                );
      }

      //Próximo registro, caso pagina correta seja diferente de pageCount o botão fica ativo
      if (currentPage != pageCount) {
        rows.push(<a onClick={() => handlePageClick(currentPage+1)} class="btn btn-icon btn-sm btn-light-info mr-2 my-1">
                    <i class="ki ki-bold-arrow-next icon-xs"></i>
                  </a>);
      } else {
        rows.push(<span class="btn btn-icon btn-sm btn-secondary disabled mr-2 my-1">
                    <i class="ki ki-bold-arrow-next icon-xs"></i>
                  </span>)
      }

      if(rows.length==0){
        return(<p>Nenhum item</p>)
      }
      return (
        <div>
          {rows.map(this.renderRow)}
        </div>
      )
    }
  }
    
    return (
        <>
<div>

<div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-5 mb-2 mr-5">Pessoas Interessadas</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-5 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center">

            </div>
            {/*end::Toolbar*/}
          </div>
        </div>
        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
            <div className="card card-custom">
              <div className="tab-content">
                <div className="tab-pane fade active show" id="kt_tab_pane_1_4" role="tabpanel" aria-labelledby="kt_tab_pane_1_4">
                  <div className="mt-5">
                   
                    <div className="row mr-5 ml-5">
                      <div className="col-md-1">
                        <label>Cotação</label>
                        <input type="text" className="form-control" value={proposta} onChange={(e)=> setProposta(e.target.value)}/>
                      </div>

                        <div className="col-md-2 form-group">
                            <label>Data do envio de</label>
                            <DatePicker 
                              name="dt_inicio"
                              onChange={setDataEnvioInicial}
                              isClearable
                              selectsStart
                              locale="pt-BR"
                              className="form-control"
                              placeholderText="dd/mm/aaaa" 
                              selected ={dataEnvioInicial}
                              dateFormat="dd/MM/y"
                              startDate={dataEnvioInicial}
                              endDate={dataEnvioFinal}
                              customInput={
                                <InputMask mask="99/99/9999" />}/>      
                        </div>
                        <div className="col-md-2 form-group">
                            <label>Data do envio até</label>
                            <DatePicker 
                              name="dt_final"
                              onChange={setDataEnvioFinal}
                              isClearable
                              locale="pt-BR"
                              className="form-control"
                              placeholderText="dd/mm/aaaa" 
                              selected ={dataEnvioFinal}
                              dateFormat="dd/MM/y"
                              selectsEnd
                              startDate={dataEnvioInicial}
                              endDate={dataEnvioFinal}
                              minDate={dataEnvioInicial} 
                              customInput={
                                <InputMask mask="99/99/9999" />}/>
                        </div>
                        
                        <div className="col-md-4 form-group">
                            <label>Nome</label>
                            <input className="form-control" 
                                   placeholder="Pesquise pelo nome ou por parte do nome"
                                   value={buscaNome} 
                                   onChange={e => {setBuscaNome(e.target.value)}} />
                        </div>

                        <div className="col-md-3 form-group">
                            <label>Programação</label>
                            <select className="form-control"
                                    value={buscaPacote} 
                                    onChange={e => {setBuscaPacote(e.target.value)}}>
                              <option value=""></option>
                              {programacoes.map((item) =>{ return(
                                <option value={item.id}>{item.nome}</option>
                              )})}
                            </select>
                        </div>
                    </div>

                    <div className="row mr-5 ml-5">
                        <div className="col-md-2 form-group">
                            <label>Data Check In</label>
                            <DatePicker 
                              name="dt_inicio"
                              onChange={setDataPeriodoInicial}
                              isClearable
                              selectsStart
                              locale="pt-BR"
                              className="form-control"
                              placeholderText="dd/mm/aaaa" 
                              selected ={dataPeriodoInicial}
                              dateFormat="dd/MM/y"
                              startDate={dataPeriodoInicial}
                              endDate={dataPeriodoFinal}
                              customInput={
                                <InputMask mask="99/99/9999" />}/>      
                        </div>
                        <div className="col-md-2 form-group">
                            <label>Data Check Out</label>
                            <DatePicker 
                              name="dt_final"
                              onChange={setDataPeriodoFinal}
                              isClearable
                              locale="pt-BR"
                              className="form-control"
                              placeholderText="dd/mm/aaaa" 
                              selected ={dataPeriodoFinal}
                              dateFormat="dd/MM/y"
                              selectsEnd
                              startDate={dataPeriodoInicial}
                              endDate={dataPeriodoFinal}
                              minDate={dataPeriodoInicial} 
                              customInput={
                                <InputMask mask="99/99/9999" />}/>
                        </div>
                        <div className="col-md-4 form-group">
                            <label>E-mail</label>
                            <input className="form-control" 
                                   placeholder="Pesquise pelo e-mail"
                                   value={buscaEmail} 
                                   onChange={e => {setBuscaEmail(e.target.value)}}/>
                        </div>
                        <div className="col-md-4 form-group">
                            <label>Status</label>
                            <select className="form-control"
                                    value={buscaStatus} 
                                    onChange={e => {setBuscaStatus(e.target.value)}}>
                              <option value="N">Andamento</option>
                              <option value="S">Arquivadas</option>
                              <option value="T">Todos</option>
                            </select>
                        </div>
                      </div>
                      
                      <div className="row mr-5 ml-5">
                        <div className="col-md-2 form-group">
                            <label>Ligação de</label>
                            <DatePicker 
                              name="dt_inicio"
                              onChange={setDataLigacaoInicial}
                              isClearable
                              selectsStart
                              locale="pt-BR"
                              className="form-control"
                              placeholderText="dd/mm/aaaa" 
                              selected ={dataLigacaoInicial}
                              dateFormat="dd/MM/y"
                              startDate={dataLigacaoInicial}
                              endDate={dataLigacaoFinal}
                              customInput={
                                <InputMask mask="99/99/9999" />}/>      
                        </div>
                        <div className="col-md-2 form-group">
                            <label>Ligação até</label>
                            <DatePicker 
                              name="dt_final"
                              onChange={setDataLigacaoFinal}
                              isClearable
                              locale="pt-BR"
                              className="form-control"
                              placeholderText="dd/mm/aaaa" 
                              selected ={dataLigacaoFinal}
                              dateFormat="dd/MM/y"
                              selectsEnd
                              startDate={dataLigacaoInicial}
                              endDate={dataLigacaoFinal}
                              minDate={dataLigacaoInicial} 
                              customInput={
                                <InputMask mask="99/99/9999" />}/>
                        </div>
                        <div className="col-md-4 form-group">
                            <label>Cidade</label>
                            <input className="form-control" 
                                   placeholder="Pesquise pela cidade"
                                   value={buscaCidade} 
                                   onChange={e => {setBuscaCidade(e.target.value)}} />
                        </div>
                        <div className="col-md-4 form-group">
                            <label>&nbsp;</label>
                            <button className="btn btn-block btn-primary" onClick={e => { buscar('Sim') }}><i class="flaticon2-search-1" />Pesquisar</button>
                        </div>
                      </div>
                   
                   </div> 

                  <LoadingOverlay
                            active={caProcessa}
                            spinner
                            text='Carregando...'
                          > 
                  
                  <table className="table table-striped">
                    <thead className="bg-light-info">
                      <tr>
                        <th className="text-primary text-center" width="8%">
                          <a className="text-hover-dark" onClick={e => { getListar('id'); }}>
                            Cotação {ordenacao === 'id' ? <i className="fas fa-sort-down text-primary" /> : <i className="fas fa-sort text-primary" />}
                          </a>
                        </th>
                        <th width="20%">Nome</th>  
                        <th width="12%">Cidade</th>
                        <th className="text-center" width="8%">Cotações</th>  
                        <th className="text-center">Ultima Cotação</th>  
                        <th className="text-primary text-center" width="8%">
                          <a className="text-hover-dark" onClick={e => { getListar('leituras'); }}>
                            Leituras {ordenacao === 'leituras' ? <i className="fas fa-sort-down text-primary" /> : <i className="fas fa-sort text-primary" />}
                          </a>
                        </th>
                        <th className="text-center" width="12%">Última Ligação</th>  
                        <th className="text-center" width="12%">Ligação Positiva</th>  
                        <th className="text-center">Ações</th>  

                      </tr>
                    </thead>
                    <tbody>  
                      { 
                      pessoas !== undefined ? 
                        pessoas.map( (item) => { 
                          return (
                          <> 
                            <tr>
                              <td className="text-center">{item.id}</td>
                              <td>{item.nome}</td>
                              <td>{item.nCidade}</td>
                              <td className="text-center">{item.numeroCotacoesAndamento}</td>
                              <td className="text-center">{item.uso_maximo != null ? 
                                <div>{item.quantidade_uso} de {item.uso_maximo}</div> :
                                <div>{item.quantidade_uso}</div>}
                              </td>
                              <td className="text-center">
                                {item.leituras < 3 &&<span className="label label-secondary label-inline"><strong>{item.leituras}</strong></span>}
                                {item.leituras >= 3 && item.leituras <= 5 &&<span className="label label-success label-inline">{item.leituras}</span>}
                                {item.leituras >= 6 && item.leituras <= 10 &&<span className="label label-warning label-inline">{item.leituras}</span>}
                                {item.leituras > 10 &&<span className="label label-danger label-inline"><strong>{item.leituras}</strong></span>}
                              </td>
                              <td className="text-center">{item.dataUltimaLigacao}</td>
                              <td className="text-center">{item.dataUltimaLigacaoPositiva}</td>
                              <td className="text-center">
                                <Link to='/cotacoes/andamento/detalhe/' className="btn btn-success btn-sm mr-2" onClick={e => { sesaoCotacao(item.id) }}>Abrir</Link>
                              </td>
                            </tr> 
                          </>
                          )
                        }) 
                        : 
                        <tr>
                          <td colspan="9" className="bg-light-warning">
                            Sua pesquisa não encontrou nenhum resultado.<br />
                            <strong>Dicas</strong><br />
                            - Tente palavras menos específicas.<br />
                            - Tente palavras chaves diferentes.<br />
                            - Confira a ortografia das palavras e se elas foram acentuadas corretamente.<br />
                            - Confira as datas selecionadas.
                          </td>
                        </tr>}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colspan="9" className="text-right">
                          <Paginacao />
                        </td>
                      </tr>
                    </tfoot>
                  </table>

                  </LoadingOverlay>

      
                  </div>
                  </div>
                  </div>
                  </div>
                  </div>
                  </div>
        </>
    );

}

export default withRouter(Interesse);                  