import React, { useState, useEffect } from 'react';
import Api from '../../../../services/api';
import { withRouter } from "react-router";

import Swal from "sweetalert2";
import { useToasts } from 'react-toast-notifications';
import LoadingOverlay from 'react-loading-overlay';
import { Redirect  } from 'react-router-dom';

const STOProgramacoes = () => {
  const { addToast } = useToasts();

  const [ carregando, setCarregando ] = useState(false);
  const [ redirecionar, setRedirecionar ] = useState(false);  
  const [ texto , setTexto ] = useState([]);

  useEffect(() => {
    getOHotel();
  }, []);

  function getOHotel() {
    setCarregando(true);
          
      Api.get("site_texto/getprogramacoes").then(rps => {
        
        setTexto(rps.data.obj);        
        })      
        setCarregando(false);
  } 

  function salvar() {
    setCarregando(true);

    Api.post('site_texto/postprogramacoes', texto).then(rps => {
      if (rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
      } else {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: rps.data.erros,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
      setCarregando(false);
    });
  }

  function sair() {
    
    setRedirecionar(true);
  }

  if (redirecionar === true) {
      return <Redirect to="/painel" />
  } 

    return (
      <>
      {/*begin::Entry*/}
      <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
  
          <div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
            <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
              {/*begin::Details*/}
              <div className="d-flex align-items-center flex-wrap mt-5 mr-2">
                {/*begin::Title*/}
                <h5 className="text-dark font-weight-bold mb-2 mr-5">Site</h5>
                {/*end::Title*/}
                {/*begin::Separator*/}
                <div className="subheader-separator subheader-separator-ver mb-2 mr-5 bg-gray-200" />
                {/*end::Separator*/}
                <h5 className="text-dark font-weight-bold mb-2 mr-5">Texto Página</h5>
                {/*end::Title*/}
                {/*begin::Separator*/}
                <div className="subheader-separator subheader-separator-ver mb-2 mr-5 bg-gray-200" />
                Programações
              </div>
              {/*end::Details*/}
            </div>
          </div>
          {/*begin::Entry*/}
          

          <LoadingOverlay
            active={carregando}
            spinner
            text='Carregando...'
          >
          <div className="row">
            <div className="col-md-12">
              <div className="card card-custom">
                <div className="card-body">
                  
                  <div className='row'>
                    <div className="form-group col-md-12">
                      <label>Título</label>
                      <input type="text" value={texto?.programacao_frase_p} onChange={e => {setTexto({...texto, programacao_frase_p: e.target.value})}} className="form-control" />
                    </div>  
                  </div>

                  <div className='row'>
                    <div className="form-group col-md-12">
                    <label>Sub-Título</label>
                    <input type="text" value={texto?.programacao} onChange={e => {setTexto({...texto, programacao: e.target.value})}} className="form-control" />
                    </div>  
                  </div>                   
                </div>
                <div className='card-footer text-right'>
                  <button type="button" onClick={e => { sair(); }} className="btn btn-secondary font-weight-bold mr-5"><i className="fas fa-door-open" />Sair</button>
                  <button type="button" onClick={e => { salvar(); }} className="btn btn-success font-weight-bold"><i className="fas fa-save" />Salvar</button>
                </div>
              </div>
            </div>
          </div>

          </LoadingOverlay>
            </div>
          </div>        
        </>
    );

}



export default withRouter(STOProgramacoes);                  