import React, { useState, useEffect } from 'react';
import Api from '../../services/api';
import { withRouter } from "react-router";

import Swal from "sweetalert2";
import { useToasts } from 'react-toast-notifications';
import DataTable from 'react-data-table-component';
import LoadingOverlay from 'react-loading-overlay';
import { Link } from 'react-router-dom';

import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import InputMask from 'react-input-mask';
import pt from "date-fns/locale/pt-BR"; 
registerLocale("pt-BR", pt);

const Fechadas = () => {
  const { addToast } = useToasts();

  const [ cotacoes, setCotacoes ] = useState([]);
  const [ programacoes, setProgramacoes ] = useState([]);
  const [ operadorSelect, setOperadorSelect ] = useState([]);
  const [ carregando, setCarregando ] = useState(false);
  const [ dataEnvioInicial, setDataEnvioInicial ] = useState();
  const [ dataEnvioFinal, setDataEnvioFinal ] = useState();
  const [ dataPeriodoInicial, setDataPeriodoInicial ] = useState();
  const [ dataPeriodoFinal, setDataPeriodoFinal ] = useState();
  const [ pacote, setPacote ] = useState();
  const [ operador, setOperador ] = useState();
  const [ nome, setNome ] = useState();
  const [ email, setEmail ] = useState();
  const [ diaria, setDiaria ] = useState();
  const [ proposta, setProposta ] = useState();

  const data = cotacoes;
  const columns = [
    {
      name: <th>Cotação</th>,
      selector: 'idCotacao',
      sortable: true,
      width: '6%',
      center: true,
    },
    {
      name: <th>Data</th>,
      selector: 'dataFechamento',
      sortable: true,
      width: '8%',
    },
    {
      name: <th>Programação</th>,
      selector: 'nProgramacao',
      sortable: true,
      width: '12%',
    },
    {
      name: <th>Nome</th>,
      selector: 'nome',
      sortable: true,
      width: '15%',
    },
    {
      name: <th>Chekin</th>,
      selector: 'checkin',
      sortable: true,
      width: '8%',
      center: 'true',
    },
    {
      name: <th>Chekout</th>,
      selector: 'checkout',
      sortable: true,
      width: '8%',
      center: 'true',
    },
    {
      name: <th>Diárias</th>,
      selector: 'diarias',
      sortable: true,
      width: '6%',
      center: 'true',
    },
    {
      name: <th>Adultos</th>,
      selector: 'adultos',
      sortable: true,
      width: '6%',
      center: 'true',
    },
    {
      name: <th>Ch12</th>,
      selector: 'chd_12',
      sortable: true,
      width: '6%',
      center: 'true',
    },
    {
      name: <th>Ch14</th>,
      selector: 'chd_14',
      sortable: true,
      width: '5%',
      center: 'true',
    },
    {
      name: <th>Pet</th>,
      selector: 'pet',
      sortable: true,
      width: '4%',
      center: 'true',
    },
    {
      name: <th>Status</th>,
      selector: 'status',
      sortable: true,
      width: '9%',
      center: 'true',
      cell: row => { if (row.status === "Confirmada") 
          {return <span class="label label-success label-inline mr-2">Confirmada</span>}
        else if (row.status === "Espera") 
          {return <span class="label label-info label-inline mr-2">Espera</span>}
        else
          {return <span class="label label-danger label-inline mr-2">Cancelada</span>}
      }
    },
    {
      name: <th>Ações</th>,
      width: '7%',
      cell: (row) => 
      { return (<>
      <div className="dropdown dropdown-inline">
      <button type="button" className="btn btn-light-info btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i className="ki ki-bold-more-ver"></i>
      </button>
      <div className="dropdown-menu">
        <a className="dropdown-item" data-toggle="modal" data-target="#kt_chat_modalR" onClick={e => { iniciaLigacao(row.idCotacao) }}>Ligar</a>
        <div class="dropdown-divider mt-5"></div>
        <a className="dropdown-item" onClick={e => { confirmarReserva(row.idCotacao) }}>Confirmar</a>
        <div class="dropdown-divider"></div>
        <a className="dropdown-item" onClick={e => { cancelarReserva(row.idCotacao) }}>Cancelar</a>
      </div>
    </div>
    </>)
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      right: true,
    },
  ];

  useEffect(() => {
    getListar();
    getOperador();
    fechadasAndamento();
  }, []);

  function sesaoCotacao($id) {
    sessionStorage.setItem('cotacao', $id);
  }

  function getListar() {
    Api.get("cotacoes/listaprogramacoes").then(rps => {    
      setProgramacoes(rps.data.prog);
    })
  }

  function getOperador() {
    Api.get("usuarios/listaroperador").then(rps => {    
      setOperadorSelect(rps.data.obj);
    })
  }

  function iniciaLigacao() {
    // data e hora atual a região
    let data = new Date();
    // Fri Nov 16 2018 18:36:40 GMT-0200 (Horário de Verão de Brasília)

    let data2 = new Date(data.valueOf() - data.getTimezoneOffset() * 60000);
    var dataBase = data2.toISOString().replace(/\.\d{3}Z$/, '');
    
    sessionStorage.setItem('inicioLigacao', dataBase);
  }

  function confirmarReserva($idCotacao) {
    var data = {
      idCotacao: $idCotacao,
      status: 'Confirmada',
      id_usuario: localStorage.getItem('id_usuario'),
    }

    Api.post("fechadas/confirmarReserva", data).then(rps => {
      addToast(rps.data.mensagem, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 2000
        });
      fechadasAndamento();
    })
  }

  function cancelarReserva($idCotacao) {
    var data = {
      idCotacao: $idCotacao,
      status: 'Cancelada',
      id_usuario: localStorage.getItem('id_usuario')
    }

    Api.post("fechadas/cancelarReserva", data).then(rps => {
      addToast(rps.data.mensagem, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 2000
        });
      fechadasAndamento();
    })
  }

  function fechadasAndamento() {
    setCarregando(true);
    var data = {
      cotacao: proposta,
      dtInicial: dataEnvioInicial,
      dtFinal: dataEnvioFinal,
      checkin: dataPeriodoInicial,
      checkout: dataPeriodoFinal,
      diaria: diaria,
      nome: nome,
      email: email,
      pacote: pacote,
      operador: operador,
    }

    Api.post('fechadas/fechadasAndamento', data).then(rps => {
      if(rps.data.status === true){
        setCotacoes(rps.data.obj);
        } else {
            Swal.fire({
                title: "Atenção!",
                icon: "error",
                html: rps.data.mensagem,
                showCloseButton: true,
                showCancelButton: false,
            });
            setCotacoes([]);
        }
        setCarregando(false);
    })
}
  
    return (
      <>
      {/*begin::Entry*/}
      <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
  
          <div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
            <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
              {/*begin::Details*/}
              <div className="d-flex align-items-center flex-wrap mr-2">
                {/*begin::Title*/}
                <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Cotações Fechadas</h5>
                {/*end::Title*/}
                {/*begin::Separator*/}
                <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
                {/*end::Separator*/}
              </div>
              {/*end::Details*/}
            </div>
          </div>
          {/*begin::Entry*/}
          {/*begin card de pesquisa de vendas */}
            <div className="card card-custom">
              <div className="card-header">
                <h3 className="card-title">Pesquisar cotações fechadas</h3>
              </div>
            
              <div className="card-body">
                <div className="row">
                  <div className="col-md-1">
                    <label>Cotação</label>
                    <input type="text" className="form-control" value={proposta} onChange={(e)=> setProposta(e.target.value)}/>
                  </div>

                        <div className="col-md-2 form-group">
                            <label>Data Fechamento Inicial</label>
                            <div className="input-group">
                            <DatePicker 
                              onChange={setDataEnvioInicial}
                              isClearable
                              selectsStart
                              locale="pt-BR"
                              className="form-control"
                              placeholderText="dd/mm/aaaa" 
                              selected ={dataEnvioInicial}
                              dateFormat="dd/MM/y"
                              startDate={dataEnvioInicial}
                              endDate={dataEnvioFinal}
                              customInput={
                                <InputMask mask="99/99/9999" />}/>
                            </div>
                        </div>
                        <div className="col-md-2 form-group">
                            <label>Data Fechamento Final</label>
                            <div className="input-group">
                              <DatePicker 
                                onChange={setDataEnvioFinal}
                                isClearable
                                locale="pt-BR"
                                className="form-control"
                                placeholderText="dd/mm/aaaa" 
                                selected ={dataEnvioFinal}
                                dateFormat="dd/MM/y"
                                selectsEnd
                                startDate={dataEnvioInicial}
                                endDate={dataEnvioFinal}
                                minDate={dataEnvioInicial} 
                                customInput={
                                  <InputMask mask="99/99/9999" />}/>
                              </div>
                        </div>
                        <div className="col-md-2 form-group">
                            <label>Data Check In</label>
                            <div className="input-group">
                            <DatePicker 
                              onChange={setDataPeriodoInicial}
                              isClearable
                              selectsStart
                              locale="pt-BR"
                              className="form-control"
                              placeholderText="dd/mm/aaaa" 
                              selected ={dataPeriodoInicial}
                              dateFormat="dd/MM/y"
                              startDate={dataPeriodoInicial}
                              endDate={dataPeriodoFinal}
                              customInput={
                                <InputMask mask="99/99/9999" />}/>
                            </div>
                        </div>
                        <div className="col-md-2 form-group">
                            <label>Data Check Out</label>
                            <div className="input-group">
                              <DatePicker 
                                onChange={setDataPeriodoFinal}
                                isClearable
                                locale="pt-BR"
                                className="form-control"
                                placeholderText="dd/mm/aaaa" 
                                selected ={dataPeriodoFinal}
                                dateFormat="dd/MM/y"
                                selectsEnd
                                startDate={dataPeriodoInicial}
                                endDate={dataPeriodoFinal}
                                minDate={dataPeriodoInicial} 
                                customInput={
                                  <InputMask mask="99/99/9999" />}/>
                              </div>
                        </div>

                        <div className="col-md-3 form-group">
                            <label>Programação</label>
                            <select className="form-control" value={pacote} onChange={(e)=> setPacote(e.target.value)}>
                                <option value="">Selecione...</option>
                                {programacoes.map((item) =>{ return(
                                <option value={item.id}>{item.nome}</option>
                            )})}
                            </select>
                        </div>
                    </div>
                  
                  <div className="row">
                    <div className="col-md-4">
                      <label>Nome</label>
                      <input type="text" className="form-control" value={nome} onChange={(e)=> setNome(e.target.value)}/>
                    </div>

                    <div className="col-md-4">
                      <label>E-mail</label>
                      <input type="email" className="form-control" value={email} onChange={(e)=> setEmail(e.target.value)}/>
                    </div>

                    <div className="col-md-1">
                      <label>Diária</label>
                      <input type="text" className="form-control" value={diaria} onChange={(e)=> setDiaria(e.target.value)}/>
                    </div>

                    <div className="col-md-3">
                      <label>Operador</label>
                      <select className="form-control" value={operador} onChange={(e)=> setOperador(e.target.value)}>
                        <option value="">Selecione...</option>
                        {operadorSelect.map((item) =>{ return(
                        <option value={item.id}>{item.name}</option>
                      )})}
                      </select>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-12 text-right">
                      <button className="btn btn-primary" onClick={e=>{fechadasAndamento()}}><i class="flaticon2-search-1" />Pesquisar</button>
                    </div>
                  </div>
                </div> 
            </div>                         
          <br />

          <LoadingOverlay
            active={carregando}
            spinner
            text='Carregando...'
          >
            <DataTable
              title="Lista das Cotações Fechadas"
              columns={columns}
              data={data}
              striped="true"
              pagination="true"
            />    
          </LoadingOverlay>
          </div>
        </div>   
          
               
        </>
    );

}



export default withRouter(Fechadas);                  