import React, { useState, useEffect } from 'react';
import Api from '../../../services/api';
import { withRouter } from "react-router";

import Swal from "sweetalert2";
import LoadingOverlay from 'react-loading-overlay';
import Select from 'react-select';
import { Modal } from "react-bootstrap";
import { useToasts } from 'react-toast-notifications';

const Pessoa = () => {
  const { addToast } = useToasts();
  const [ pessoas, setPessoas ] = useState([]);
  const [ formPessoa, setFormPessoa ] = useState([]);
  const [ totalRegistros, setTotalRegistros ] = useState();
  const [ estados, setEstados ] = useState([]);
  const [ modal, setModal ] = useState(false);
  const [ buscaNome, setBuscaNome ] = useState();
  const [ buscaCidade, setBuscaCidade ] = useState();
  const [ buscaEstado, setBuscaEstado ] = useState();
  const [ buscaEmail, setBuscaEmail ] = useState();
  const [ buscaCategoria, setBuscaCategoria ] = useState();
  const [ caProcessa, setCaProcessa ] = useState(false);
  const [ ordenacao, setOrdenacao ] = useState('qtd_hosp');
  const [ ordenacaoSet, setOrdenacaoSet ] = useState();
  const [ currentPage, setCurrentPage ] = useState(1);
  const [ acao, setAcao ] = useState();
  const PER_PAGE = 10;
  const pageCount = Math.ceil(totalRegistros / PER_PAGE);

  useEffect(() => {
    getListar();
  }, []);

  function adicionarPessoa() {
    setFormPessoa({});
    setAcao('Adicionar');
    setModal(true);
  }

  function verPessoa(idPessoa) {
    var ca = JSON.parse(JSON.stringify(idPessoa));
    
    setFormPessoa(ca);
    setAcao('Ver');            
    setModal(true);
  }

  function editarPessoa(idPessoa) {
    var ca = JSON.parse(JSON.stringify(idPessoa));
    
    setFormPessoa(ca);
    setAcao('Alterar');            
    setModal(true);
  }

  function fecharModal(){
    setModal(false);
  }

  function salvarPessoa() {
    setCaProcessa(true);
    Api.post('pessoas/adicionar', formPessoa).then(rps => {
      if (rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
        setModal(false);
        setFormPessoa({});
        getListar();
      } else {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: rps.data.erros,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
      setCaProcessa(false);
    });
  }
  
  function getListar() {
    setCaProcessa(true);

    var data = {
      limite: PER_PAGE, 
      start: 0,
      ordenacao: 'qtd_hosp',
      sentido: 'ASC',
    }

    Api.post("pessoas/listar", data).then(rps => {    
      setPessoas(rps.data.obj);
      setTotalRegistros(rps.data.totalRegistros);
      setCurrentPage(1);
      setCaProcessa(false);
    });
  }

  function getBuscaLetra($letra) {
    setCaProcessa(true);
    Api.get("pessoas/pesquisaLetra/" + $letra).then(rps => {
      setPessoas(rps.data.obj);
      setCaProcessa(false);
    });
  }
    
  function filtrar(order) {

    var sentido = 'ASC';

    if (order != null) {
      setOrdenacao(order);
    } else {
      //Valor padrão para a primeira vez
      setOrdenacao('qtd_hosp');
      order = 'qtd_hosp';
    }

    if (order === ordenacao) {
      //Caso a orden atual seja igual a anterior
      if (ordenacaoSet === 'DESC' || ordenacaoSet === null) {
        //Verifico se estou em ordem DESC e inverto para ASC
        sentido = 'ASC';
        setOrdenacaoSet('ASC');
      } else {
        sentido = 'DESC';
        setOrdenacaoSet('DESC');
      }
    } else {
      //Caso a ordem atual não seja igual a anterior ASC
      setOrdenacaoSet('ASC');
    }

    setCaProcessa(true); 
      var data = {
        limite: PER_PAGE, 
        start: 0,
        nome: buscaNome,
        cidade: buscaCidade,
        estado: buscaEstado,
        email: buscaEmail,
        categoria: buscaCategoria, 
        ordenacao: order,
        sentido: sentido,
      };

      Api.post("pessoas/listar", data).then(rps => {

        if(rps.data.status==false){
          Swal.fire({
            title: "Atenção!",
            icon: "info",
            html: rps.data.erro,
            showCloseButton: true,
            showCancelButton: false,
          });
        } else {
          setPessoas(rps.data.obj);
          setTotalRegistros(rps.data.totalRegistros);
          setCurrentPage(1);
        }
      setCaProcessa(false); 
    });
  }

  function handlePageClick(pagina) {
    setCaProcessa(true);
    setCurrentPage(pagina);
    var data = {
      limite: PER_PAGE, 
      start: (pagina-1) * PER_PAGE,
      nome: buscaNome,
      cidade: buscaCidade,
      estado: buscaEstado,
      email: buscaEmail,
      categoria: buscaCategoria, 
      ordenacao: ordenacao,
      sentido: ordenacaoSet,
    }
    
    Api.post("pessoas/listar", data).then(rps => { 
      setPessoas(rps.data.obj);
      setCaProcessa(false);
    })
  }

  class Paginacao extends React.Component{
    renderRow(row){
      return (<span>{row}</span>)
    }
    render(){
      let rows = []

      //Registro Anterior, caso pagina correta seja diferente de 1 o botão fica ativo
      if (currentPage != 1) {
        rows.push(<a onClick={() => handlePageClick(currentPage-1)} class="btn btn-icon btn-sm btn-light-info mr-2 my-1">
                    <i class="ki ki-bold-arrow-back icon-xs"></i>
                  </a>);
      } else {
        rows.push(<span class="btn-icon btn-sm btn-secondary disabled mr-2 my-1">
                    <i class="ki ki-bold-arrow-back icon-xs"></i>
                  </span>)
      }

      //Quanto existe mais de 3 páginas estou exibindo o botão [1] e [...], envia para a página 1.
      if (currentPage > 3) {
        rows.push(<>
                    <a onClick={() => handlePageClick(1)} class="btn btn-icon btn-sm btn-light-info mr-2 my-1">
                      1
                    </a>
                    <span class="btn-icon btn-sm btn-secondary disabled mr-2 my-1">
                      ...
                    </span>
                  </>
                );
      }

      //Executo um laço de repetição para exibir os numeros de páginas, limitando a memos e mais 3, tendo como base a página atual
      for(let i = currentPage-2; i < currentPage+3; i++) {
        if (i > 0 && i <= pageCount) {
          if (i == currentPage) {
            rows.push(<a onClick={() => handlePageClick(i)} className="btn btn-icon btn-sm border-0 btn-hover-primary active mr-2 my-1">{i}</a>)
          } else {
            rows.push(<a onClick={() => handlePageClick(i)} className="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1">{i}</a>)
          }
        }
      }

       //Quanto existe mais de 6 páginas estou exibindo o botão [...] e [Maior Numero], envia para a página MaiorNumero.
      if (currentPage+3 <= pageCount) {
        rows.push(<>
                    <span class="btn-icon btn-sm btn-secondary disabled mr-2 my-1">
                      ...
                    </span>
                    <a onClick={() => handlePageClick(pageCount)} class="btn btn-icon btn-sm btn-light-info mr-2 my-1">
                      {pageCount}
                    </a>
                  </>
                );
      }

      //Próximo registro, caso pagina correta seja diferente de pageCount o botão fica ativo
      if (currentPage != pageCount) {
        rows.push(<a onClick={() => handlePageClick(currentPage+1)} class="btn btn-icon btn-sm btn-light-info mr-2 my-1">
                    <i class="ki ki-bold-arrow-next icon-xs"></i>
                  </a>);
      } else {
        rows.push(<span class="btn btn-icon btn-sm btn-secondary disabled mr-2 my-1">
                    <i class="ki ki-bold-arrow-next icon-xs"></i>
                  </span>)
      }

      if(rows.length==0){
        return(<p>Nenhum item</p>)
      }
      return (
        <div>
          {rows.map(this.renderRow)}
        </div>
      )
    }
  }

    return (
        <>
<div>

<div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Pessoas Interessadas</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center">
              <button className="btn btn-lg btn-success mt-6" onClick={e => {adicionarPessoa()}}><i className="fas fa-plus" />Adicionar</button>
            </div>
            {/*end::Toolbar*/}
          </div>
        </div>
        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">          
            <div className="card card-custom">
              <div className="tab-content">
                <div className="tab-pane fade active show" id="kt_tab_pane_1_4" role="tabpanel" aria-labelledby="kt_tab_pane_1_4">
                  
                  {/*begin::Accordion*/}
    <div className="accordion accordion-solid accordion-toggle-plus" id="accordionExample6">
      <div className="card">
        <div className="card-header" id="headingOne6">
          <div className="card-title" data-toggle="collapse" data-target="#collapseOne6">
            <i className="flaticon-price-tag" />Busca por Cadastros</div>
        </div>
        <div id="collapseOne6" className="collapse show" data-parent="#accordionExample6">
          <div className="card-body">
            <div className="row">
              <div className="col-md-12 form-group">
                <label>Nome</label>
                <input className="form-control" 
                   placeholder="Pesquise pelo nome ou por parte do nome"
                   value={buscaNome} 
                   onChange={e => {setBuscaNome(e.target.value)}} />
              </div>  
            </div>
            <div className="row">
              <div className="col-md-4 form-group">
                <label>Cidade</label>
                <input className="form-control" 
                   placeholder="Cidade"
                   value={buscaCidade} 
                   onChange={e => {setBuscaCidade(e.target.value)}} />
              </div>

              <div className="col-md-6 form-group">
                <label>E-mail</label>
                <input className="form-control" 
                   placeholder="Pesquise pelo email ou por parte"
                   value={buscaEmail} 
                   onChange={e => {setBuscaEmail(e.target.value)}} />
              </div>

              <div className="col-md-2 form-group">
                <label>Categoria</label>
                <select className="form-control" value={buscaCategoria} onChange={e => {setBuscaCategoria(e.target.value)}}>
                  <option value="">Selecione...</option>
                  <option value="Diamante">Diamante</option>
                  <option value="Ouro">Ouro</option>
                  <option value="Prata">Prata</option>
                  <option value="Hóspede">Hóspede</option>
                  <option value="Cotação">Cotação</option>
                </select>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 text-right">
                <button className="btn btn-primary" onClick={e => filtrar() }><i className="flaticon2-search-1" />Pesquisar</button>
              </div>
            </div>
            {/*<br />
            <div className="row">
              <div className="col-md-12 text-center">
                <button className="btn btn-info" onClick={e => {getBuscaLetra("A")}}>A</button>&nbsp;
                <button className="btn btn-info" onClick={e => {getBuscaLetra("B")}}>B</button>&nbsp;
                <button className="btn btn-info" onClick={e => {getBuscaLetra("C")}}>C</button>&nbsp;
                <button className="btn btn-info" onClick={e => {getBuscaLetra("D")}}>D</button>&nbsp;
                <button className="btn btn-info" onClick={e => {getBuscaLetra("E")}}>E</button>&nbsp;
                <button className="btn btn-info" onClick={e => {getBuscaLetra("F")}}>F</button>&nbsp;
                <button className="btn btn-info" onClick={e => {getBuscaLetra("G")}}>G</button>&nbsp;
                <button className="btn btn-info" onClick={e => {getBuscaLetra("H")}}>H</button>&nbsp;
                <button className="btn btn-info" onClick={e => {getBuscaLetra("I")}}>I</button>&nbsp;
                <button className="btn btn-info" onClick={e => {getBuscaLetra("J")}}>J</button>&nbsp;
                <button className="btn btn-info" onClick={e => {getBuscaLetra("K")}}>K</button>&nbsp;
                <button className="btn btn-info" onClick={e => {getBuscaLetra("L")}}>L</button>&nbsp;
                <button className="btn btn-info" onClick={e => {getBuscaLetra("M")}}>M</button>&nbsp;
                <button className="btn btn-info" onClick={e => {getBuscaLetra("N")}}>N</button>&nbsp;
                <button className="btn btn-info" onClick={e => {getBuscaLetra("O")}}>O</button>&nbsp;
                <button className="btn btn-info" onClick={e => {getBuscaLetra("P")}}>P</button>&nbsp;
                <button className="btn btn-info" onClick={e => {getBuscaLetra("Q")}}>Q</button>&nbsp;
                <button className="btn btn-info" onClick={e => {getBuscaLetra("R")}}>R</button>&nbsp;
                <button className="btn btn-info" onClick={e => {getBuscaLetra("S")}}>S</button>&nbsp;
                <button className="btn btn-info" onClick={e => {getBuscaLetra("T")}}>T</button>&nbsp;
                <button className="btn btn-info" onClick={e => {getBuscaLetra("U")}}>U</button>&nbsp;
                <button className="btn btn-info" onClick={e => {getBuscaLetra("V")}}>V</button>&nbsp;
                <button className="btn btn-info" onClick={e => {getBuscaLetra("W")}}>W</button>&nbsp;
                <button className="btn btn-info" onClick={e => {getBuscaLetra("X")}}>X</button>&nbsp;
                <button className="btn btn-info" onClick={e => {getBuscaLetra("Y")}}>Y</button>&nbsp;
                <button className="btn btn-info" onClick={e => {getBuscaLetra("Z")}}>Z</button>&nbsp;
                <button className="btn btn-secondary" onClick={e => {getListar()}}>Todos</button>
              </div>
            </div>
            */}
          </div>
        </div>
      </div>
      </div>
           
           <br /><br />

                  <LoadingOverlay
                            active={caProcessa}
                            spinner
                            text='Carregando...'
                          > 

              <table className="table table-striped">
                <thead className="bg-light-info">
                  <tr>
                    <th className="text-primary text-center">
                      <a className="text-hover-dark" onClick={e => { filtrar('qtd_hosp'); }}>
                        Hospedagem {ordenacao === 'qtd_hosp' ? <i className="fas fa-sort-down text-primary" /> : <i className="fas fa-sort text-primary" />}
                      </a>
                    </th>
                    <th className="text-primary">
                      <a className="text-hover-dark" onClick={e => { filtrar('nome'); }}>
                        Nome {ordenacao === 'nome' ? <i className="fas fa-sort-down text-primary" /> : <i className="fas fa-sort text-primary" />}
                      </a>
                    </th>  
                    <th className="text-primary">
                      <a className="text-hover-dark" onClick={e => { filtrar('cidade'); }}>
                        Cidade {ordenacao === 'cidade' ? <i className="fas fa-sort-down text-primary" /> : <i className="fas fa-sort text-primary" />}
                      </a>
                    </th>
                    <th className="text-primary">
                      <a className="text-hover-dark" onClick={e => { filtrar('email'); }}>
                        E-mail {ordenacao === 'email' && ordenacaoSet === 'ASC' ? <i className="fas fa-sort-down text-primary" /> : <i className="fas fa-sort text-primary" />}
                      </a>
                    </th>  
                    <th className="text-primary text-center">
                      <a className="text-hover-dark" onClick={e => { filtrar('status'); }}>
                        Status {ordenacao === 'status' ? <i className="fas fa-sort-down text-primary" /> : <i className="fas fa-sort text-primary" />}
                      </a>
                    </th>  
                    <th className="text-center">Ações</th>  

                  </tr>
                </thead>
                <tbody>  
                  { pessoas.map( (item) => { 
                      return (
                      <> 
                        <tr>
                          <td className="text-center">{item.qtd_hosp}</td>
                          <td>{item.nome}</td>
                          <td>{item.cidade}</td>
                          <td>{item.email}</td>
                          <td className="text-center">
                            {item.status === "S" && <>          
                              <span class="label label-secondary label-inline mr-2">Cotação</span>
                            </>}
                            {item.status === "F" && item.fidelidade_cat === "Diamante" && <>          
                              <span class="label label-info label-inline mr-2">Fidelidade</span>
                              <img src='https://expertpluss.hotelfazendasaojoao.com.br/img/planotarifario/e78719a5b185465391bb539f7a9b6262.jpg' height='30' width='30' />
                            </>}
                            {item.status === "F" && item.fidelidade_cat === "Ouro" && <>          
                              <span class="label label-info label-inline mr-2">Fidelidade</span>
                              <img src='https://expertpluss.hotelfazendasaojoao.com.br/img/planotarifario/f66108a77248fcd04c52ba088de7836c.png' height='30' width='30' />
                            </>}
                            {item.status === "F" && item.fidelidade_cat === "Prata" && <>          
                              <span class="label label-info label-inline mr-2">Fidelidade</span>
                              <img src='https://expertpluss.hotelfazendasaojoao.com.br/img/planotarifario/c6809da742f8caa509ed9c88dbe56387.jpg' height='30' width='30' />
                            </>}
                            {item.status === "H" && <>          
                              <span class="label label-success label-inline mr-2">Hóspede</span>
                            </>}
                            {item.status === "N" && <>          
                              <span class="label label-danger label-inline mr-2">Bloqueado</span>
                            </>}
                          </td>
                          <td className="text-center">
                            <button className="btn btn-success btn-sm mr-2" onClick={e => { verPessoa(item) }}><i className="fas fa-eye" />Ver</button>
                            <button className="btn btn-warning btn-sm" onClick={e => { editarPessoa(item) }}><i className="fas fa-pencil-alt" />Alterar</button>
                          </td>
                        </tr> 
                      </>
                      )
                    })
                    }
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="9" className="text-right">
                       <Paginacao />
                    </td>
                  </tr>
                </tfoot>
              </table>

                  </LoadingOverlay>

      
                  </div>
                  </div>
                  </div>
                  </div>
                  </div>
                  </div>

        <Modal size={'xl'} show={modal} onHide={() => fecharModal()}>
          <Modal.Header>
          <Modal.Title>{acao} Cadastro de Pessoa</Modal.Title>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => {fecharModal()}}>
                <i aria-hidden="true" className="ki ki-close" />
              </button>
          </Modal.Header>
          <div className="row mt-5 ml-5 mr-5">
            <div class="col-md-2 offset-md-10 form-group" align="right"> 
                { acao === 'Ver' ?
                  <>
                    <h4>Qtd. Hospedagem:
                    <b>{formPessoa.qtd_hosp}</b></h4>
                  </> : 
                  <>
                    <label><b>Qtd. Hospedagem</b></label>
                    <input className="form-control" placeholder="Qtde. Hospedagem"
                      value={formPessoa?.qtd_hosp} 
                      onChange={e => {setFormPessoa({...formPessoa, qtd_hosp: e.target.value})} } />
                  </>
                }
            </div>
          </div>

            <div className="row ml-5 mr-5">
              <div className="col-md-8 form-group">
                <label>Nome</label>
                <input className="form-control" placeholder="Nome"
                   value={formPessoa?.nome} 
                   onChange={e => {setFormPessoa({...formPessoa, nome: e.target.value})} } />
              </div>

              <div className="col-md-2 form-group">
                <label>Status</label>
                <select className="form-control" value={formPessoa?.status} onChange={e => {setFormPessoa({...formPessoa, status: e.target.value})}}>
                  <option value="" selected>Selecione</option>    
                  <option value="C">Cotação</option>    
                  <option value="F">Fidelidade</option>    
                  <option value="H">Hóspede</option>
                  <option value="N">Bloqueado</option>    
                </select>
              </div>

              <div className="col-md-2 form-group">
                <label>Fidelidade</label>
                <select className="form-control" value={formPessoa?.fidelidade_cat} onChange={e => {setFormPessoa({...formPessoa, fidelidade_cat: e.target.value})}}>
                  <option value="" selected>Selecione</option>    
                  <option value="Diamante">Diamante</option>    
                  <option value="Ouro">Ouro</option>    
                  <option value="Prata">Prata</option>
                </select>
              </div>
      			</div>
          
            <div className="row ml-5 mr-5">
              <div className="col-md-9 form-group">
                <label>E-mail</label>
                <input className="form-control" placeholder="E-mail"
                   value={formPessoa?.email} 
                   onChange={e => {setFormPessoa({...formPessoa, email: e.target.value})} } />
              </div>

              <div className="col-md-3 form-group">
                <label>Nascimento</label>
                <input className="form-control" placeholder="Nascimento"
                   value={formPessoa?.nascimento} 
                   onChange={e => {setFormPessoa({...formPessoa, nascimento: e.target.value})} } />
              </div>
            </div>
            
            <div className="row ml-5 mr-5">
              <div className="col-md-3 form-group">
                <label>CPF</label>
                <input className="form-control" placeholder="CPF"
                   value={formPessoa?.cpf} 
                   onChange={e => {setFormPessoa({...formPessoa, cpf: e.target.value})} } />
              </div>

              <div className="col-md-3 form-group">
                <label>CEP</label>
                <input className="form-control" placeholder="CEP"
                   value={formPessoa?.cep} 
                   onChange={e => {setFormPessoa({...formPessoa, cep: e.target.value})} } />
              </div>
            </div>

            <div className="row ml-5 mr-5">
              <div className="col-md-8 form-group">
                <label>Endereço</label>
                <input className="form-control" placeholder="Endereço"
                   value={formPessoa?.endereco} 
                   onChange={e => {setFormPessoa({...formPessoa, endereco: e.target.value})} } />
              </div>

              <div className="col-md-4 form-group">
                <label>Bairro</label>
                <input className="form-control" placeholder="Bairro"
                   value={formPessoa?.bairro} 
                   onChange={e => {setFormPessoa({...formPessoa, bairro: e.target.value})} } />
              </div>
            </div>

            <div className="row ml-5 mr-5">
              <div className="col-md-6 form-group">
                <label>Cidade</label>
                <input className="form-control" placeholder="Cidade"
                   value={formPessoa?.cidade} 
                   onChange={e => {setFormPessoa({...formPessoa, cidade: e.target.value})} } />
              </div>

              <div className="col-md-2 form-group">
                <label>Telefone</label>
                <input className="form-control" placeholder="Telefone"
                   value={formPessoa?.telefone1} 
                   onChange={e => {setFormPessoa({...formPessoa, telefone1: e.target.value})} } />
              </div>
				
              <div className="col-md-2 form-group">
                <label>Celular(1)</label>
                <input className="form-control" placeholder="Celular(1)"
                   value={formPessoa?.celular} 
                   onChange={e => {setFormPessoa({...formPessoa, celular: e.target.value})} } />
              </div>

              <div className="col-md-2 form-group">
                <label>Celular(2)</label>
                <input className="form-control" placeholder="Celular(2)"
                   value={formPessoa?.celular2} 
                   onChange={e => {setFormPessoa({...formPessoa, celular2: e.target.value})} } />
              </div>
      			</div>

            <div className="row ml-5 mr-5">
              <div className="col-md-12 form-group">
                <label>Observação</label>
                <textarea className="form-control" onChange={e => {setFormPessoa({...formPessoa, observacao: e.target.value})} }>
                  {formPessoa?.observacao}
                </textarea>
              </div>
            </div>
			
          <Modal.Footer>
            <button type="button" onClick={e => { fecharModal(); }} className="btn btn-secondary font-weight-bold" data-dismiss="modal"><i className="fas fa-door-open" />Fechar</button>
            { acao !== 'Ver' &&
              <button type="button" onClick={e => { salvarPessoa(); }} className="btn btn-success font-weight-bold"><i className="fas fa-save" />Salvar</button>
            }        
          </Modal.Footer>
        </Modal>
        </>
    );

}

export default withRouter(Pessoa);                  