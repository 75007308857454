import React, { useState, useEffect } from 'react';
import Api from '../../services/api';
import { withRouter } from "react-router";

import LoadingOverlay from 'react-loading-overlay';

import { Bar } from 'react-chartjs-2';

import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import InputMask from 'react-input-mask';
import pt from "date-fns/locale/pt-BR"; 
registerLocale("pt-BR", pt);

const Painel = () => {
  const [ periodo, setPeriodo ] = useState(0);
  const [ dataInicial, setDataInicial ] = useState();
  const [ dataFinal, setDataFinal ] = useState(new Date());

  //Cotações Enviadas
  const [ somaCotacoesEnviadasAtual, setSomaCotacoesEnviadasAtual ] = useState();
  const [ somaCotacoesEnviadasAnterior, setSomaCotacoesEnviadasAnterior ] = useState();
  const [ porCotacoesEnviadasAtual, setPorCotacoesEnviadasAtual ] = useState();
  const [ corCotacoesEnviadasAtual, setCorCotacoesEnviadasAtual ] = useState();
  //Retornos Realizados
  const [ somaRetornosRealizadosAtual, setSomaRetornosRealizadosAtual ] = useState(0);
  const [ somaRetornosRealizadosAnterior, setSomaRetornosRealizadosAnterior ] = useState(0);
  const [ porRetornosRealizadosAtual, setPorRetornosRealizadosAtual ] = useState(0);
  const [ corRetornosRealizadosAtual, setCorRetornosRealizadosAtual ] = useState();
  //Ligacoes Recebidas
  const [ somaLigacoesRecebidasAtual, setSomaLigacoesRecebidasAtual ] = useState(0);
  const [ somaLigacoesRecebidasAnterior, setSomaLigacoesRecebidasAnterior ] = useState(0);
  const [ porLigacoesRecebidasAtual, setPorLigacoesRecebidasAtual ] = useState(0);
  const [ corLigacoesRecebidasAtual, setCorLigacoesRecebidasAtual ] = useState();
  //Reservas Confirmadas
  const [ somaReservasConfirmadasAtual, setSomaReservasConfirmadasAtual ] = useState(0);
  const [ somaReservasConfirmadasAnterior, setSomaReservasConfirmadasAnterior ] = useState(0);
  const [ porReservasConfirmadasAtual, setPorReservasConfirmadasAtual ] = useState(0);
  const [ corReservasConfirmadasAtual, setCorReservasConfirmadasAtual ] = useState();
  //Diarias Confirmadas
  const [ somaDiariasConfirmadasAtual, setSomaDiariasConfirmadasAtual ] = useState(0);
  const [ somaDiariasConfirmadasAnterior, setSomaDiariasConfirmadasAnterior ] = useState(0);
  //Reservas Espera
  const [ somaReservasEsperaAtual, setSomaReservasEsperaAtual ] = useState(0);
  const [ somaReservasEsperaAnterior, setSomaReservasEsperaAnterior ] = useState(0);
  const [ porReservasEsperaAtual, setPorReservasEsperaAtual ] = useState(0);
  const [ corReservasEsperaAtual, setCorReservasEsperaAtual ] = useState();
  //Diarias Espera
  const [ somaDiariasEsperaAtual, setSomaDiariasEsperaAtual ] = useState(0);
  const [ somaDiariasEsperaAnterior, setSomaDiariasEsperaAnterior ] = useState(0);
  //Conversão Cotação
  const [ somaConversaoCotacaoAtual, setSomaConversaoCotacaoAtual ] = useState(0);
  const [ somaConversaoCotacaoAnterior, setSomaConversaoCotacaoAnterior ] = useState(0);
  const [ porConversaoCotacaoAtual, setPorConversaoCotacaoAtual ] = useState(0);
  const [ corConversaoCotacaoAtual, setCorConversaoCotacaoAtual ] = useState();
  
  //Atualização
  const [ caLigacao, setCaLigacao ] = useState(false);
  const [ caGrafico, setCaGrafico ] = useState(false);
  const [ caCotacoes, setCaCotacoes ] = useState(false);
  const [ caRetornos, setCaRetornos ] = useState(false);
  const [ caLigacoes, setCaLigacoes ] = useState(false);
  const [ caConfirmadas, setCaConfirmadas ] = useState(false);
  const [ caEspera, setCaEspera ] = useState(false);
  const [ caConversao, setCaConversao ] = useState(false);
  const [ caFechamento, setCaFechamento ] = useState(false);

  //Painel de Fechamento
  const [ fechamentoPainel , setFechamentoPainel ] = useState([]);

  //Painel de Ligaçoes
  const [ ligacoesPainel , setLigacoesPainel ] = useState([]);

  //Grafico das Programações
  const [ programacaoLabel , setProgramacaoLabel ] = useState([]);
  const [ programacaoValor , setProgramacaoValor ] = useState([]);
  const dataGrafico = {
    labels: programacaoLabel,
    datasets: [
        {
            label: 'Qtde de Cotações por Programação',
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)',
              'rgba(220, 20, 60, 0.2)',
              'rgba(127, 255, 0, 0.2)',
              'rgba(189, 183, 107, 0.2)',
              'rgba(255, 215, 0, 0.2)',
              'rgba(255, 255, 0, 0.2)',
              'rgba(128, 0, 128, 0.2)',
              'rgba(175, 238, 238, 0.2)',
              'rgba(0, 0, 128, 0.2)',
              'rgba(178, 34, 34, 0.2)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
              'rgba(220, 20, 60, 1)',
              'rgba(127, 255, 0, 1)',
              'rgba(189, 183, 107, 1)',
              'rgba(255, 215, 0, 1)',
              'rgba(255, 255, 0, 1)',
              'rgba(128, 0, 128, 1)',
              'rgba(175, 238, 238, 1)',
              'rgba(0, 0, 128, 1)',
              'rgba(178, 34, 34, 1)',
            ],
            borderWidth: 1,
            data: programacaoValor
        }
    ]
};

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
}

  useEffect(() => {
    getSomaCotacoesEnviadas();
    getSomaLigacaoesRecebidas();
    getSomaLigacaoesEfetuadas();
    getSomaReservasConfirmadas();
    getSomaDiariasConfirmadas();
    getSomaReservasEspera();
    getSomaDiariasEspera();
    getGraficoPainel();
    getLigacoesPainel();
    getFechamentoPainel();
  }, []);

  useEffect(() => {
    getConversao();
  }, [getConversao]);

  function getGerar() {      
      var data = {
        periodo: periodo,
        data_inicio: dataInicial,
        data_final: dataFinal,
      };
    
      getSomaCotacoesEnviadas(data);
      getSomaLigacaoesEfetuadas(data);
      getSomaLigacaoesRecebidas(data);
      getSomaReservasConfirmadas(data);
      getSomaDiariasConfirmadas(data);
      getSomaReservasEspera(data);
      getSomaDiariasEspera(data);
      getGraficoPainel(data);
      getLigacoesPainel(data);
      getFechamentoPainel(data);
      getConversao();
  }

  function getSomaCotacoesEnviadas(data) {
    setCaCotacoes(true);

    Api.post("painel/somaCotacoesEnviadas", data).then(rps => {    
      
      setSomaCotacoesEnviadasAtual(rps.data.atual);
      setPorCotacoesEnviadasAtual(rps.data.porcetagem.toFixed(0));
      setCorCotacoesEnviadasAtual(rps.data.cor);
      setSomaCotacoesEnviadasAnterior(rps.data.anterior);

      setCaCotacoes(false);
    })
  }

  function getSomaLigacaoesEfetuadas(data) {
    setCaRetornos(true);

    Api.post("painel/somaLigacoesEfetuadas", data).then(rps => {    
      
      setSomaRetornosRealizadosAtual(rps.data.atual);
      setPorRetornosRealizadosAtual(rps.data.porcetagem.toFixed(0));
      setCorRetornosRealizadosAtual(rps.data.cor);
      setSomaRetornosRealizadosAnterior(rps.data.anterior);

      setCaRetornos(false);
    })
  }

  function getSomaLigacaoesRecebidas(data) {
    setCaLigacoes(true);

    Api.post("painel/somaLigacoesRecebidas", data).then(rps => {    
      
      setSomaLigacoesRecebidasAtual(rps.data.atual);
      setPorLigacoesRecebidasAtual(rps.data.porcetagem.toFixed(0));
      setCorLigacoesRecebidasAtual(rps.data.cor);
      setSomaLigacoesRecebidasAnterior(rps.data.anterior);

      setCaLigacoes(false);
    })
  }

  function getSomaReservasConfirmadas(data) {
    setCaConfirmadas(true);

    Api.post("painel/somaReservasConfirmadas", data).then(rps => {    
      
      setSomaReservasConfirmadasAtual(rps.data.atual);
      setPorReservasConfirmadasAtual(rps.data.porcetagem.toFixed(0));
      setCorReservasConfirmadasAtual(rps.data.cor);
      setSomaReservasConfirmadasAnterior(rps.data.anterior);

      setCaConfirmadas(false);
    })
  }

  function getSomaDiariasConfirmadas(data) {
    Api.post("painel/somaDiariasConfirmadas", data).then(rps => {    
      
      setSomaDiariasConfirmadasAtual(rps.data.atual);
      setSomaDiariasConfirmadasAnterior(rps.data.anterior);
    })
  }

  function getSomaReservasEspera(data) {
    setCaEspera(true);

    Api.post("painel/somaReservasEspera", data).then(rps => {    
      
      setSomaReservasEsperaAtual(rps.data.atual);
      setPorReservasEsperaAtual(rps.data.porcetagem.toFixed(0));
      setCorReservasEsperaAtual(rps.data.cor);
      setSomaReservasEsperaAnterior(rps.data.anterior);

      setCaEspera(false);
    })
  }

  function getSomaDiariasEspera(data) {
    Api.post("painel/somaDiariasEspera", data).then(rps => {    
      
      setSomaDiariasEsperaAtual(rps.data.atual);
      setSomaDiariasEsperaAnterior(rps.data.anterior);
    })
  }

  function getConversao(){

    setCaConversao(true);

    var ConversaoAtual = parseFloat(parseInt(somaDiariasConfirmadasAtual) / parseInt(somaCotacoesEnviadasAtual) * 100);
    var ConversaoAnterior = parseFloat(parseInt(somaDiariasConfirmadasAnterior) / parseInt(somaCotacoesEnviadasAnterior) * 100);
    var cor = '';
    var valor = 0;

    if (ConversaoAnterior > 0 && ConversaoAtual > 0) {
      if (ConversaoAnterior > ConversaoAtual) {
          valor = ((ConversaoAtual - ConversaoAnterior) / ConversaoAtual)*100;
          cor = 'danger';
      } else {
          valor = ((ConversaoAtual - ConversaoAnterior) / ConversaoAnterior)*100;
          cor = 'success';
      } 
  } else {
      valor = 0;
      cor = 'success';
  }
    
    setSomaConversaoCotacaoAtual(ConversaoAtual);
    setSomaConversaoCotacaoAnterior(ConversaoAnterior);
    setCorConversaoCotacaoAtual(cor);
    setPorConversaoCotacaoAtual(valor.toFixed(0));

    setCaConversao(false);
  }

  function getGraficoPainel(data) {
    setCaGrafico(true);
    Api.post("painel/graficoProgramacao", data).then(rps => {
      //***Inicio do valor Grafico Programacao
      var formataProgramacaoTexto = [];
      var formataProgramacaoValor = [];

      rps.data.obj.map( item => {
          formataProgramacaoTexto.push(item.nome);
          formataProgramacaoValor.push(item.qtdeCotacoes);
      })

      setProgramacaoLabel(formataProgramacaoTexto);
      setProgramacaoValor(formataProgramacaoValor);

      setCaGrafico(false);
    })
  }

  function getLigacoesPainel(data) {
    setCaLigacao(true);

    Api.post("painel/ligacoesPainel", data).then(rps => {    
      
      setLigacoesPainel(rps.data.obj);
      
      setCaLigacao(false);
    })
  }

  function getFechamentoPainel(data) {
    setCaFechamento(true);

    Api.post("painel/cotacaoPessoaPainel", data).then(rps => {    
      
      setFechamentoPainel(rps.data.obj);

      setCaFechamento(false);
    })
  }
    
    return (
        <>
{/*begin::Content*/}
<div className="content d-flex flex-column flex-column-fluid" id="kt_content">
  {/*begin::Entry*/}
  <div className="d-flex flex-column-fluid">
  {/*begin::Container*/}
    <div className="container">
    <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Período</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
              <div className="d-flex align-items-center mr-5">
                <select onChange={(e) => { setPeriodo(e.target.value)}} className="form-control">
                  <option value="0">Dia atual</option>
                  <option value="7">Últimos 7 dias</option>
                  <option value="30">Últimos 30 dias</option>
                  <option value="60">Últimos 60 dias</option>
                  <option value="90">Últimos 90 dias</option>
                  <option value="PP">Período Personalizado</option>
                </select>
              </div>
              {/*begin::Search Form*/}
              { periodo === "PP" &&
              <div className="d-flex align-items-center" id="kt_subheader_search">
                <div className="input-group" id="kt_daterangepicker_2">
                <DatePicker
                    name="dt_inicio"
                    onChange={setDataInicial}
                    isClearable
                    selectsStart
                    locale="pt-BR"
                    className="form-control"
                    placeholderText="dd/mm/aaaa"
                    selected ={dataInicial}
                    dateFormat="dd/MM/y"
                    startDate={dataInicial}
                    endDate={dataFinal}
                    customInput={
                        <InputMask mask="99/99/9999" />}/>
                &nbsp;&nbsp;
                <DatePicker
                    name="dt_final"
                    onChange={setDataFinal}
                    isClearable
                    locale="pt-BR"
                    className="form-control"
                    placeholderText="dd/mm/aaaa"
                    selected ={dataFinal}
                    dateFormat="dd/MM/y"
                    selectsEnd
                    startDate={dataInicial}
                    endDate={dataFinal}
                    minDate={dataInicial}
                    customInput={
                        <InputMask mask="99/99/9999" />}/>

                </div>
              </div>
              }
              {/*end::Search Form*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center">
              {/*begin::Button*/}
              <button onClick={e => { getGerar(); }} className="btn btn-info btn-block pl-10 pr-10 btn-md">Filtrar</button>
              {/*end::Button*/}
              {/*begin::Button*/}
            </div>
            {/*end::Toolbar*/}
          </div>
        
          <br />

    {/*begin::Dashboard*/}
      {/*begin::Row*/}
      <div className="row">
        {/*Inicio do Painel*/}
        <div class="col-lg-2 mb-10">
          <LoadingOverlay
            active={caCotacoes}
            spinner
            text='Carregando...'
          >
          <div class={corCotacoesEnviadasAtual === "success" ? 
              "card card-custom bg-light-success card-body" : 
              "card card-custom bg-light-danger card-body"}>
            <div class="row">
                <div class="col-sm-12 text-center">
            <span class="font-weight-bolder text-dark-75 font-size-h5">Cotações Enviadas</span>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-sm-6">
              <span class="text-dark-75 font-weight-bolder font-size-h2 mr-2">{somaCotacoesEnviadasAtual}</span>
            </div>
            <div class="col-sm-6 text-right">
              {corCotacoesEnviadasAtual === "success" ?
                <span className="svg-icon svg-icon-success svg-icon-2x">{/*begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo5\dist/../src/media/svg/icons\Navigation\Angle-double-up.svg*/}<svg xmlns="https://www.w3.org/2000/svg" xmlnsXlink="https://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                    <polygon points="0 0 24 0 24 24 0 24" />
                    <path d="M8.2928955,10.2071068 C7.90237121,9.81658249 7.90237121,9.18341751 8.2928955,8.79289322 C8.6834198,8.40236893 9.31658478,8.40236893 9.70710907,8.79289322 L15.7071091,14.7928932 C16.085688,15.1714722 16.0989336,15.7810586 15.7371564,16.1757246 L10.2371564,22.1757246 C9.86396402,22.5828436 9.23139665,22.6103465 8.82427766,22.2371541 C8.41715867,21.8639617 8.38965574,21.2313944 8.76284815,20.8242754 L13.6158645,15.5300757 L8.2928955,10.2071068 Z" fill="#000000" fillRule="nonzero" transform="translate(12.000003, 15.500003) scale(-1, 1) rotate(-90.000000) translate(-12.000003, -15.500003) " />
                    <path d="M6.70710678,12.2071104 C6.31658249,12.5976347 5.68341751,12.5976347 5.29289322,12.2071104 C4.90236893,11.8165861 4.90236893,11.1834211 5.29289322,10.7928968 L11.2928932,4.79289682 C11.6714722,4.41431789 12.2810586,4.40107226 12.6757246,4.76284946 L18.6757246,10.2628495 C19.0828436,10.6360419 19.1103465,11.2686092 18.7371541,11.6757282 C18.3639617,12.0828472 17.7313944,12.1103502 17.3242754,11.7371577 L12.0300757,6.88414142 L6.70710678,12.2071104 Z" fill="#000000" fillRule="nonzero" opacity="0.3" transform="translate(12.000003, 8.500003) scale(-1, 1) rotate(-360.000000) translate(-12.000003, -8.500003) " />
                  </g>
                  </svg>{/*end::Svg Icon*/}
                </span>
              :
              <span className="svg-icon svg-icon-danger svg-icon-2x">{/*begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo5\dist/../src/media/svg/icons\Navigation\Angle-double-down.svg*/}<svg xmlns="https://www.w3.org/2000/svg" xmlnsXlink="https://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                  <polygon points="0 0 24 0 24 24 0 24" />
                  <path d="M8.2928955,3.20710089 C7.90237121,2.8165766 7.90237121,2.18341162 8.2928955,1.79288733 C8.6834198,1.40236304 9.31658478,1.40236304 9.70710907,1.79288733 L15.7071091,7.79288733 C16.085688,8.17146626 16.0989336,8.7810527 15.7371564,9.17571874 L10.2371564,15.1757187 C9.86396402,15.5828377 9.23139665,15.6103407 8.82427766,15.2371482 C8.41715867,14.8639558 8.38965574,14.2313885 8.76284815,13.8242695 L13.6158645,8.53006986 L8.2928955,3.20710089 Z" fill="#000000" fillRule="nonzero" transform="translate(12.000003, 8.499997) scale(-1, -1) rotate(-90.000000) translate(-12.000003, -8.499997) " />
                  <path d="M6.70710678,19.2071045 C6.31658249,19.5976288 5.68341751,19.5976288 5.29289322,19.2071045 C4.90236893,18.8165802 4.90236893,18.1834152 5.29289322,17.7928909 L11.2928932,11.7928909 C11.6714722,11.414312 12.2810586,11.4010664 12.6757246,11.7628436 L18.6757246,17.2628436 C19.0828436,17.636036 19.1103465,18.2686034 18.7371541,18.6757223 C18.3639617,19.0828413 17.7313944,19.1103443 17.3242754,18.7371519 L12.0300757,13.8841355 L6.70710678,19.2071045 Z" fill="#000000" fillRule="nonzero" opacity="0.3" transform="translate(12.000003, 15.499997) scale(-1, -1) rotate(-360.000000) translate(-12.000003, -15.499997) " />
                </g>
                </svg>{/*end::Svg Icon*/}
              </span>
              }
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <span class="d-block text-dark-50 mt-2 font-size-sm">{somaCotacoesEnviadasAnterior}</span>
            </div>
            <div class="col-sm-6 text-right">
              <span class="text-dark-75 font-weight-bolder font-size-h6 mr-2">{porCotacoesEnviadasAtual}%</span>
            </div>
          </div>
        </div>
        {/*Fim Painel*/}
        </LoadingOverlay>
      </div>

        {/*Inicio do Painel*/}
        <div class="col-lg-2 mb-10">
          <LoadingOverlay
            active={caRetornos}
            spinner
            text='Carregando...'
          >
          <div class={corRetornosRealizadosAtual === "success" ? 
              "card card-custom bg-light-success card-body" : 
              "card card-custom bg-light-danger card-body"}>
            <div class="row">
                <div class="col-sm-12 text-center">
            <span class="font-weight-bolder text-dark-75 font-size-h5">Retornos Realizados</span>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-sm-6">
              <span class="text-dark-75 font-weight-bolder font-size-h2 mr-2">{somaRetornosRealizadosAtual}</span>
            </div>
            <div class="col-sm-6 text-right">
              {corRetornosRealizadosAtual === "success" ?
                <span className="svg-icon svg-icon-success svg-icon-2x">{/*begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo5\dist/../src/media/svg/icons\Navigation\Angle-double-up.svg*/}<svg xmlns="https://www.w3.org/2000/svg" xmlnsXlink="https://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                    <polygon points="0 0 24 0 24 24 0 24" />
                    <path d="M8.2928955,10.2071068 C7.90237121,9.81658249 7.90237121,9.18341751 8.2928955,8.79289322 C8.6834198,8.40236893 9.31658478,8.40236893 9.70710907,8.79289322 L15.7071091,14.7928932 C16.085688,15.1714722 16.0989336,15.7810586 15.7371564,16.1757246 L10.2371564,22.1757246 C9.86396402,22.5828436 9.23139665,22.6103465 8.82427766,22.2371541 C8.41715867,21.8639617 8.38965574,21.2313944 8.76284815,20.8242754 L13.6158645,15.5300757 L8.2928955,10.2071068 Z" fill="#000000" fillRule="nonzero" transform="translate(12.000003, 15.500003) scale(-1, 1) rotate(-90.000000) translate(-12.000003, -15.500003) " />
                    <path d="M6.70710678,12.2071104 C6.31658249,12.5976347 5.68341751,12.5976347 5.29289322,12.2071104 C4.90236893,11.8165861 4.90236893,11.1834211 5.29289322,10.7928968 L11.2928932,4.79289682 C11.6714722,4.41431789 12.2810586,4.40107226 12.6757246,4.76284946 L18.6757246,10.2628495 C19.0828436,10.6360419 19.1103465,11.2686092 18.7371541,11.6757282 C18.3639617,12.0828472 17.7313944,12.1103502 17.3242754,11.7371577 L12.0300757,6.88414142 L6.70710678,12.2071104 Z" fill="#000000" fillRule="nonzero" opacity="0.3" transform="translate(12.000003, 8.500003) scale(-1, 1) rotate(-360.000000) translate(-12.000003, -8.500003) " />
                  </g>
                  </svg>{/*end::Svg Icon*/}
                </span>
              :
              <span className="svg-icon svg-icon-danger svg-icon-2x">{/*begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo5\dist/../src/media/svg/icons\Navigation\Angle-double-down.svg*/}<svg xmlns="https://www.w3.org/2000/svg" xmlnsXlink="https://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                  <polygon points="0 0 24 0 24 24 0 24" />
                  <path d="M8.2928955,3.20710089 C7.90237121,2.8165766 7.90237121,2.18341162 8.2928955,1.79288733 C8.6834198,1.40236304 9.31658478,1.40236304 9.70710907,1.79288733 L15.7071091,7.79288733 C16.085688,8.17146626 16.0989336,8.7810527 15.7371564,9.17571874 L10.2371564,15.1757187 C9.86396402,15.5828377 9.23139665,15.6103407 8.82427766,15.2371482 C8.41715867,14.8639558 8.38965574,14.2313885 8.76284815,13.8242695 L13.6158645,8.53006986 L8.2928955,3.20710089 Z" fill="#000000" fillRule="nonzero" transform="translate(12.000003, 8.499997) scale(-1, -1) rotate(-90.000000) translate(-12.000003, -8.499997) " />
                  <path d="M6.70710678,19.2071045 C6.31658249,19.5976288 5.68341751,19.5976288 5.29289322,19.2071045 C4.90236893,18.8165802 4.90236893,18.1834152 5.29289322,17.7928909 L11.2928932,11.7928909 C11.6714722,11.414312 12.2810586,11.4010664 12.6757246,11.7628436 L18.6757246,17.2628436 C19.0828436,17.636036 19.1103465,18.2686034 18.7371541,18.6757223 C18.3639617,19.0828413 17.7313944,19.1103443 17.3242754,18.7371519 L12.0300757,13.8841355 L6.70710678,19.2071045 Z" fill="#000000" fillRule="nonzero" opacity="0.3" transform="translate(12.000003, 15.499997) scale(-1, -1) rotate(-360.000000) translate(-12.000003, -15.499997) " />
                </g>
                </svg>{/*end::Svg Icon*/}
              </span>
              }
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <span class="d-block text-dark-50 mt-2 font-size-sm">{somaRetornosRealizadosAnterior}</span>
            </div>
            <div class="col-sm-6 text-right">
              <span class="text-dark-75 font-weight-bolder font-size-h6 mr-2">{porRetornosRealizadosAtual}%</span>
            </div>
          </div>
        </div>
        {/*Fim Painel*/}
        </LoadingOverlay>
      </div>
        
        {/*Inicio do Painel*/}
        <div class="col-lg-2 mb-10">
          <LoadingOverlay
            active={caLigacoes}
            spinner
            text='Carregando...'
          >
          <div class={corLigacoesRecebidasAtual === "success" ? 
              "card card-custom bg-light-success card-body" : 
              "card card-custom bg-light-danger card-body"}>
            <div class="row">
                <div class="col-sm-12 text-center">
            <span class="font-weight-bolder text-dark-75 font-size-h5">Ligações Recebidas</span>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-sm-6">
              <span class="text-dark-75 font-weight-bolder font-size-h2 mr-2">{somaLigacoesRecebidasAtual}</span>
            </div>
            <div class="col-sm-6 text-right">
              {corLigacoesRecebidasAtual === "success" ?
                <span className="svg-icon svg-icon-success svg-icon-2x">{/*begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo5\dist/../src/media/svg/icons\Navigation\Angle-double-up.svg*/}<svg xmlns="https://www.w3.org/2000/svg" xmlnsXlink="https://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                    <polygon points="0 0 24 0 24 24 0 24" />
                    <path d="M8.2928955,10.2071068 C7.90237121,9.81658249 7.90237121,9.18341751 8.2928955,8.79289322 C8.6834198,8.40236893 9.31658478,8.40236893 9.70710907,8.79289322 L15.7071091,14.7928932 C16.085688,15.1714722 16.0989336,15.7810586 15.7371564,16.1757246 L10.2371564,22.1757246 C9.86396402,22.5828436 9.23139665,22.6103465 8.82427766,22.2371541 C8.41715867,21.8639617 8.38965574,21.2313944 8.76284815,20.8242754 L13.6158645,15.5300757 L8.2928955,10.2071068 Z" fill="#000000" fillRule="nonzero" transform="translate(12.000003, 15.500003) scale(-1, 1) rotate(-90.000000) translate(-12.000003, -15.500003) " />
                    <path d="M6.70710678,12.2071104 C6.31658249,12.5976347 5.68341751,12.5976347 5.29289322,12.2071104 C4.90236893,11.8165861 4.90236893,11.1834211 5.29289322,10.7928968 L11.2928932,4.79289682 C11.6714722,4.41431789 12.2810586,4.40107226 12.6757246,4.76284946 L18.6757246,10.2628495 C19.0828436,10.6360419 19.1103465,11.2686092 18.7371541,11.6757282 C18.3639617,12.0828472 17.7313944,12.1103502 17.3242754,11.7371577 L12.0300757,6.88414142 L6.70710678,12.2071104 Z" fill="#000000" fillRule="nonzero" opacity="0.3" transform="translate(12.000003, 8.500003) scale(-1, 1) rotate(-360.000000) translate(-12.000003, -8.500003) " />
                  </g>
                  </svg>{/*end::Svg Icon*/}
                </span>
              :
              <span className="svg-icon svg-icon-danger svg-icon-2x">{/*begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo5\dist/../src/media/svg/icons\Navigation\Angle-double-down.svg*/}<svg xmlns="https://www.w3.org/2000/svg" xmlnsXlink="https://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                  <polygon points="0 0 24 0 24 24 0 24" />
                  <path d="M8.2928955,3.20710089 C7.90237121,2.8165766 7.90237121,2.18341162 8.2928955,1.79288733 C8.6834198,1.40236304 9.31658478,1.40236304 9.70710907,1.79288733 L15.7071091,7.79288733 C16.085688,8.17146626 16.0989336,8.7810527 15.7371564,9.17571874 L10.2371564,15.1757187 C9.86396402,15.5828377 9.23139665,15.6103407 8.82427766,15.2371482 C8.41715867,14.8639558 8.38965574,14.2313885 8.76284815,13.8242695 L13.6158645,8.53006986 L8.2928955,3.20710089 Z" fill="#000000" fillRule="nonzero" transform="translate(12.000003, 8.499997) scale(-1, -1) rotate(-90.000000) translate(-12.000003, -8.499997) " />
                  <path d="M6.70710678,19.2071045 C6.31658249,19.5976288 5.68341751,19.5976288 5.29289322,19.2071045 C4.90236893,18.8165802 4.90236893,18.1834152 5.29289322,17.7928909 L11.2928932,11.7928909 C11.6714722,11.414312 12.2810586,11.4010664 12.6757246,11.7628436 L18.6757246,17.2628436 C19.0828436,17.636036 19.1103465,18.2686034 18.7371541,18.6757223 C18.3639617,19.0828413 17.7313944,19.1103443 17.3242754,18.7371519 L12.0300757,13.8841355 L6.70710678,19.2071045 Z" fill="#000000" fillRule="nonzero" opacity="0.3" transform="translate(12.000003, 15.499997) scale(-1, -1) rotate(-360.000000) translate(-12.000003, -15.499997) " />
                </g>
                </svg>{/*end::Svg Icon*/}
              </span>
              }
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <span class="d-block text-dark-50 mt-2 font-size-sm">{somaLigacoesRecebidasAnterior}</span>
            </div>
            <div class="col-sm-6 text-right">
              <span class="text-dark-75 font-weight-bolder font-size-h6 mr-2">{porLigacoesRecebidasAtual}%</span>
            </div>
          </div>
        </div>
        {/*Fim Painel*/}
        </LoadingOverlay>
      </div>

      {/*Inicio do Painel*/}
      <div class="col-lg-2 mb-10">
          <LoadingOverlay
            active={caConfirmadas}
            spinner
            text='Carregando...'
          >
          <div class={corReservasConfirmadasAtual === "success" ? 
              "card card-custom bg-light-success card-body" : 
              "card card-custom bg-light-danger card-body"}>
            <div class="row">
                <div class="col-sm-12 text-center">
            <span class="font-weight-bolder text-dark-75 font-size-h5">Reservas Confirmadas</span>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-sm-6">
              <span class="text-dark-75 font-weight-bolder font-size-h2 mr-2">{somaReservasConfirmadasAtual}<small className="font-size-h6"> ({somaDiariasConfirmadasAtual})</small></span>
            </div>
            <div class="col-sm-6 text-right">
              {corReservasConfirmadasAtual === "success" ?
                <span className="svg-icon svg-icon-success svg-icon-2x">{/*begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo5\dist/../src/media/svg/icons\Navigation\Angle-double-up.svg*/}<svg xmlns="https://www.w3.org/2000/svg" xmlnsXlink="https://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                    <polygon points="0 0 24 0 24 24 0 24" />
                    <path d="M8.2928955,10.2071068 C7.90237121,9.81658249 7.90237121,9.18341751 8.2928955,8.79289322 C8.6834198,8.40236893 9.31658478,8.40236893 9.70710907,8.79289322 L15.7071091,14.7928932 C16.085688,15.1714722 16.0989336,15.7810586 15.7371564,16.1757246 L10.2371564,22.1757246 C9.86396402,22.5828436 9.23139665,22.6103465 8.82427766,22.2371541 C8.41715867,21.8639617 8.38965574,21.2313944 8.76284815,20.8242754 L13.6158645,15.5300757 L8.2928955,10.2071068 Z" fill="#000000" fillRule="nonzero" transform="translate(12.000003, 15.500003) scale(-1, 1) rotate(-90.000000) translate(-12.000003, -15.500003) " />
                    <path d="M6.70710678,12.2071104 C6.31658249,12.5976347 5.68341751,12.5976347 5.29289322,12.2071104 C4.90236893,11.8165861 4.90236893,11.1834211 5.29289322,10.7928968 L11.2928932,4.79289682 C11.6714722,4.41431789 12.2810586,4.40107226 12.6757246,4.76284946 L18.6757246,10.2628495 C19.0828436,10.6360419 19.1103465,11.2686092 18.7371541,11.6757282 C18.3639617,12.0828472 17.7313944,12.1103502 17.3242754,11.7371577 L12.0300757,6.88414142 L6.70710678,12.2071104 Z" fill="#000000" fillRule="nonzero" opacity="0.3" transform="translate(12.000003, 8.500003) scale(-1, 1) rotate(-360.000000) translate(-12.000003, -8.500003) " />
                  </g>
                  </svg>{/*end::Svg Icon*/}
                </span>
              :
              <span className="svg-icon svg-icon-danger svg-icon-2x">{/*begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo5\dist/../src/media/svg/icons\Navigation\Angle-double-down.svg*/}<svg xmlns="https://www.w3.org/2000/svg" xmlnsXlink="https://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                  <polygon points="0 0 24 0 24 24 0 24" />
                  <path d="M8.2928955,3.20710089 C7.90237121,2.8165766 7.90237121,2.18341162 8.2928955,1.79288733 C8.6834198,1.40236304 9.31658478,1.40236304 9.70710907,1.79288733 L15.7071091,7.79288733 C16.085688,8.17146626 16.0989336,8.7810527 15.7371564,9.17571874 L10.2371564,15.1757187 C9.86396402,15.5828377 9.23139665,15.6103407 8.82427766,15.2371482 C8.41715867,14.8639558 8.38965574,14.2313885 8.76284815,13.8242695 L13.6158645,8.53006986 L8.2928955,3.20710089 Z" fill="#000000" fillRule="nonzero" transform="translate(12.000003, 8.499997) scale(-1, -1) rotate(-90.000000) translate(-12.000003, -8.499997) " />
                  <path d="M6.70710678,19.2071045 C6.31658249,19.5976288 5.68341751,19.5976288 5.29289322,19.2071045 C4.90236893,18.8165802 4.90236893,18.1834152 5.29289322,17.7928909 L11.2928932,11.7928909 C11.6714722,11.414312 12.2810586,11.4010664 12.6757246,11.7628436 L18.6757246,17.2628436 C19.0828436,17.636036 19.1103465,18.2686034 18.7371541,18.6757223 C18.3639617,19.0828413 17.7313944,19.1103443 17.3242754,18.7371519 L12.0300757,13.8841355 L6.70710678,19.2071045 Z" fill="#000000" fillRule="nonzero" opacity="0.3" transform="translate(12.000003, 15.499997) scale(-1, -1) rotate(-360.000000) translate(-12.000003, -15.499997) " />
                </g>
                </svg>{/*end::Svg Icon*/}
              </span>
              }
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <span class="d-block text-dark-50 mt-2 font-size-sm">{somaReservasConfirmadasAnterior} <small> ({somaDiariasConfirmadasAnterior})</small></span>
            </div>
            <div class="col-sm-6 text-right">
              <span class="text-dark-75 font-weight-bolder font-size-h6 mr-2">{porReservasConfirmadasAtual}%</span>
            </div>
          </div>
        </div>
        {/*Fim Painel*/}
        </LoadingOverlay>
      </div>

      {/*Inicio do Painel*/}
      <div class="col-lg-2 mb-10">
        <LoadingOverlay
            active={caEspera}
            spinner
            text='Carregando...'
          >
          <div class={corReservasEsperaAtual === "success" ? 
              "card card-custom bg-light-success card-body" : 
              "card card-custom bg-light-danger card-body"}>
            <div class="row">
                <div class="col-sm-12 text-center">
            <span class="font-weight-bolder text-dark-75 font-size-h5">Reservas em Espera</span>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-sm-6">
              <span class="text-dark-75 font-weight-bolder font-size-h2 mr-2">{somaReservasEsperaAtual}<small className="font-size-h6"> ({somaDiariasEsperaAtual})</small></span>
            </div>
            <div class="col-sm-6 text-right">
              {corReservasEsperaAtual === "success" ?
                <span className="svg-icon svg-icon-success svg-icon-2x">{/*begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo5\dist/../src/media/svg/icons\Navigation\Angle-double-up.svg*/}<svg xmlns="https://www.w3.org/2000/svg" xmlnsXlink="https://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                    <polygon points="0 0 24 0 24 24 0 24" />
                    <path d="M8.2928955,10.2071068 C7.90237121,9.81658249 7.90237121,9.18341751 8.2928955,8.79289322 C8.6834198,8.40236893 9.31658478,8.40236893 9.70710907,8.79289322 L15.7071091,14.7928932 C16.085688,15.1714722 16.0989336,15.7810586 15.7371564,16.1757246 L10.2371564,22.1757246 C9.86396402,22.5828436 9.23139665,22.6103465 8.82427766,22.2371541 C8.41715867,21.8639617 8.38965574,21.2313944 8.76284815,20.8242754 L13.6158645,15.5300757 L8.2928955,10.2071068 Z" fill="#000000" fillRule="nonzero" transform="translate(12.000003, 15.500003) scale(-1, 1) rotate(-90.000000) translate(-12.000003, -15.500003) " />
                    <path d="M6.70710678,12.2071104 C6.31658249,12.5976347 5.68341751,12.5976347 5.29289322,12.2071104 C4.90236893,11.8165861 4.90236893,11.1834211 5.29289322,10.7928968 L11.2928932,4.79289682 C11.6714722,4.41431789 12.2810586,4.40107226 12.6757246,4.76284946 L18.6757246,10.2628495 C19.0828436,10.6360419 19.1103465,11.2686092 18.7371541,11.6757282 C18.3639617,12.0828472 17.7313944,12.1103502 17.3242754,11.7371577 L12.0300757,6.88414142 L6.70710678,12.2071104 Z" fill="#000000" fillRule="nonzero" opacity="0.3" transform="translate(12.000003, 8.500003) scale(-1, 1) rotate(-360.000000) translate(-12.000003, -8.500003) " />
                  </g>
                  </svg>{/*end::Svg Icon*/}
                </span>
              :
              <span className="svg-icon svg-icon-danger svg-icon-2x">{/*begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo5\dist/../src/media/svg/icons\Navigation\Angle-double-down.svg*/}<svg xmlns="https://www.w3.org/2000/svg" xmlnsXlink="https://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                  <polygon points="0 0 24 0 24 24 0 24" />
                  <path d="M8.2928955,3.20710089 C7.90237121,2.8165766 7.90237121,2.18341162 8.2928955,1.79288733 C8.6834198,1.40236304 9.31658478,1.40236304 9.70710907,1.79288733 L15.7071091,7.79288733 C16.085688,8.17146626 16.0989336,8.7810527 15.7371564,9.17571874 L10.2371564,15.1757187 C9.86396402,15.5828377 9.23139665,15.6103407 8.82427766,15.2371482 C8.41715867,14.8639558 8.38965574,14.2313885 8.76284815,13.8242695 L13.6158645,8.53006986 L8.2928955,3.20710089 Z" fill="#000000" fillRule="nonzero" transform="translate(12.000003, 8.499997) scale(-1, -1) rotate(-90.000000) translate(-12.000003, -8.499997) " />
                  <path d="M6.70710678,19.2071045 C6.31658249,19.5976288 5.68341751,19.5976288 5.29289322,19.2071045 C4.90236893,18.8165802 4.90236893,18.1834152 5.29289322,17.7928909 L11.2928932,11.7928909 C11.6714722,11.414312 12.2810586,11.4010664 12.6757246,11.7628436 L18.6757246,17.2628436 C19.0828436,17.636036 19.1103465,18.2686034 18.7371541,18.6757223 C18.3639617,19.0828413 17.7313944,19.1103443 17.3242754,18.7371519 L12.0300757,13.8841355 L6.70710678,19.2071045 Z" fill="#000000" fillRule="nonzero" opacity="0.3" transform="translate(12.000003, 15.499997) scale(-1, -1) rotate(-360.000000) translate(-12.000003, -15.499997) " />
                </g>
                </svg>{/*end::Svg Icon*/}
              </span>
              }
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <span class="d-block text-dark-50 mt-2 font-size-sm">{somaReservasEsperaAnterior}<small> ({somaDiariasEsperaAnterior})</small></span>
            </div>
            <div class="col-sm-6 text-right">
              <span class="text-dark-75 font-weight-bolder font-size-h6 mr-2">{porReservasEsperaAtual}%</span>
            </div>
          </div>
        </div>
        {/*Fim Painel*/}
        </LoadingOverlay>
      </div>

      {/*Inicio do Painel*/}
      <div class="col-lg-2 mb-10">
        <LoadingOverlay
            active={caEspera}
            spinner
            text='Carregando...'
          >
          <div class={corConversaoCotacaoAtual === "success" ? 
              "card card-custom bg-light-success card-body" : 
              "card card-custom bg-light-danger card-body"}>
            <div class="row">
                <div class="col-sm-12 text-center">
            <span class="font-weight-bolder text-dark-75 font-size-h5">Conversão Cotação</span>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-sm-6">
              <span class="text-dark-75 font-weight-bolder font-size-h2 mr-2">{somaConversaoCotacaoAtual.toFixed(2)}</span>
            </div>
            <div class="col-sm-6 text-right">
              {corConversaoCotacaoAtual === "success" ?
                <span className="svg-icon svg-icon-success svg-icon-2x">{/*begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo5\dist/../src/media/svg/icons\Navigation\Angle-double-up.svg*/}<svg xmlns="https://www.w3.org/2000/svg" xmlnsXlink="https://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                    <polygon points="0 0 24 0 24 24 0 24" />
                    <path d="M8.2928955,10.2071068 C7.90237121,9.81658249 7.90237121,9.18341751 8.2928955,8.79289322 C8.6834198,8.40236893 9.31658478,8.40236893 9.70710907,8.79289322 L15.7071091,14.7928932 C16.085688,15.1714722 16.0989336,15.7810586 15.7371564,16.1757246 L10.2371564,22.1757246 C9.86396402,22.5828436 9.23139665,22.6103465 8.82427766,22.2371541 C8.41715867,21.8639617 8.38965574,21.2313944 8.76284815,20.8242754 L13.6158645,15.5300757 L8.2928955,10.2071068 Z" fill="#000000" fillRule="nonzero" transform="translate(12.000003, 15.500003) scale(-1, 1) rotate(-90.000000) translate(-12.000003, -15.500003) " />
                    <path d="M6.70710678,12.2071104 C6.31658249,12.5976347 5.68341751,12.5976347 5.29289322,12.2071104 C4.90236893,11.8165861 4.90236893,11.1834211 5.29289322,10.7928968 L11.2928932,4.79289682 C11.6714722,4.41431789 12.2810586,4.40107226 12.6757246,4.76284946 L18.6757246,10.2628495 C19.0828436,10.6360419 19.1103465,11.2686092 18.7371541,11.6757282 C18.3639617,12.0828472 17.7313944,12.1103502 17.3242754,11.7371577 L12.0300757,6.88414142 L6.70710678,12.2071104 Z" fill="#000000" fillRule="nonzero" opacity="0.3" transform="translate(12.000003, 8.500003) scale(-1, 1) rotate(-360.000000) translate(-12.000003, -8.500003) " />
                  </g>
                  </svg>{/*end::Svg Icon*/}
                </span>
              :
              <span className="svg-icon svg-icon-danger svg-icon-2x">{/*begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo5\dist/../src/media/svg/icons\Navigation\Angle-double-down.svg*/}<svg xmlns="https://www.w3.org/2000/svg" xmlnsXlink="https://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                  <polygon points="0 0 24 0 24 24 0 24" />
                  <path d="M8.2928955,3.20710089 C7.90237121,2.8165766 7.90237121,2.18341162 8.2928955,1.79288733 C8.6834198,1.40236304 9.31658478,1.40236304 9.70710907,1.79288733 L15.7071091,7.79288733 C16.085688,8.17146626 16.0989336,8.7810527 15.7371564,9.17571874 L10.2371564,15.1757187 C9.86396402,15.5828377 9.23139665,15.6103407 8.82427766,15.2371482 C8.41715867,14.8639558 8.38965574,14.2313885 8.76284815,13.8242695 L13.6158645,8.53006986 L8.2928955,3.20710089 Z" fill="#000000" fillRule="nonzero" transform="translate(12.000003, 8.499997) scale(-1, -1) rotate(-90.000000) translate(-12.000003, -8.499997) " />
                  <path d="M6.70710678,19.2071045 C6.31658249,19.5976288 5.68341751,19.5976288 5.29289322,19.2071045 C4.90236893,18.8165802 4.90236893,18.1834152 5.29289322,17.7928909 L11.2928932,11.7928909 C11.6714722,11.414312 12.2810586,11.4010664 12.6757246,11.7628436 L18.6757246,17.2628436 C19.0828436,17.636036 19.1103465,18.2686034 18.7371541,18.6757223 C18.3639617,19.0828413 17.7313944,19.1103443 17.3242754,18.7371519 L12.0300757,13.8841355 L6.70710678,19.2071045 Z" fill="#000000" fillRule="nonzero" opacity="0.3" transform="translate(12.000003, 15.499997) scale(-1, -1) rotate(-360.000000) translate(-12.000003, -15.499997) " />
                </g>
                </svg>{/*end::Svg Icon*/}
              </span>
              }
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <span class="d-block text-dark-50 mt-2 font-size-sm">{somaConversaoCotacaoAnterior.toFixed(2)}</span>
            </div>
            <div class="col-sm-6 text-right">
              <span class="text-dark-75 font-weight-bolder font-size-h6 mr-2">{porConversaoCotacaoAtual}%</span>
            </div>
          </div>
        </div>
        {/*Fim Painel*/}
      </LoadingOverlay>
    </div>
                
                                
                
              </div>
              {/*end::Row*/}
              {/*begin::Row*/}
              <div className="row">
                <div className="col-xl-8">
                  <LoadingOverlay
                    active={caGrafico}
                    spinner
                    text='Carregando...'
                  >
                  {/*begin::Charts Widget 2*/}
                  <div className="card card-custom bg-gray-100 gutter-b card-stretch card-shadowless">
                    {/*begin::Header*/}
                    <div className="card-header h-auto border-0">
                      {/*begin::Title*/}
                      <div className="card-title py-5">
                        <h3 className="card-label">
                          <span className="d-block text-dark font-weight-bolder">Cotações</span>
                        </h3>
                      </div>
                      {/*end::Title*/}
                      {/*begin::Toolbar*/}
                      <div className="card-toolbar">
                        <ul className="nav nav-pills nav-pills-sm nav-dark-75" role="tablist">
                          <li className="nav-item">
                            <span className="nav-link py-2 px-4 active" data-toggle="tab" href="#kt_charts_widget_2_chart_tab_3">
                              <span className="nav-text font-size-sm">{periodo} Dia(s)</span>
                            </span>
                          </li>
                        </ul>
                      </div>
                      {/*end::Toolbar*/}
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className="card-body">
                      {/*begin::Chart*/}
                        <Bar data={dataGrafico} options={options} />
                      {/*end::Chart*/}
                    </div>
                    {/*end::Body*/}
                  </div>
                  {/*end::Charts Widget 2*/}
                  </LoadingOverlay>
                </div>

                <div className="col-xl-4">
                  {/*begin::List Widget 2*/}
                  <LoadingOverlay
                    active={caFechamento}
                    spinner
                    text='Carregando...'
                  >
                  <div className="card card-custom bg-light-info gutter-b card-stretch card-shadowless">
                    {/*begin::Header*/}
                    <div className="card-header border-0">
                      <h3 className="card-title font-weight-bolder text-info">Cotações Enviadas</h3>
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    
                    <div className="card-body pt-2">
                    {fechamentoPainel.length > 0 ? 
                      fechamentoPainel.map((item) => { return(
                      <>
                        <div className='row'>
                          <div className='col-md-2 mb-2'>
                            <div className="symbol symbol-40 symbol-light-white mr-5">
                              <div className="symbol-label">
                                {item.foto == null ? 
                                  <img src="assets/media/svg/avatars/004-boy-1.svg" className="h-75 align-self-end" alt="" />
                                  :
                                  <img src={'https://expertpluss.hotelfazendasaojoao.com.br/img/user/' + item.foto} className="h-100 w-100 rounded" alt="" />
                                }
                              </div>
                            </div>
                          </div>
                          <div className='col-md-8 text-center'>
                            <h4 className="text-dark">{item.name}</h4>  
                          </div>
                          <div className='col-md-2 text-center'>
                            <h4 className="text-dark">{item.qtdeCotacoes}</h4>  
                          </div>
                        </div>
                      </>
                      )}) : 
                        <img src={'https://expertpluss.hotelfazendasaojoao.com.br/img/user/398716.png'} className="h-100 w-100" alt="" />
                      }   
                      </div>
                    {/*end::Body*/}
                  </div>
                  {/*end::List Widget 2*/}
                  </LoadingOverlay>

                  <br />

                  {/*begin::List Widget 2*/}
                  <LoadingOverlay
                    active={caLigacao}
                    spinner
                    text='Carregando...'
                  >
                  <div className="card card-custom bg-light-warning gutter-b card-stretch card-shadowless">
                    {/*begin::Header*/}
                    <div className="card-header border-0">
                      <h3 className="card-title font-weight-bolder text-warning">Ligações por pessoa</h3>
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    
                    <div className="card-body pt-2">
                    {ligacoesPainel.length > 0 ? 
                      ligacoesPainel.map((item) => { return(
                      <>
                        <b><a href="#" className="text-dark text-hover-primary mb-1 font-size-lg">{item.name}</a>
                        </b>
                      <div className="d-flex align-items-center mb-10">
                        {/*begin::Symbol*/}
                        <div className="symbol symbol-40 symbol-light-white mr-5">
                          <div className="symbol-label">
                            {item.foto == null ? 
                              <img src="assets/media/svg/avatars/004-boy-1.svg" className="h-75 align-self-end" alt="" />
                              :
                              <img src={'https://expertpluss.hotelfazendasaojoao.com.br/img/user/' + item.foto} className="h-100 w-100 rounded" alt="" />
                            }
                          </div>
                        </div>
                        {/*end::Symbol*/}
                        {/*begin::Text*/}
                        <div className="d-flex flex-column font-weight-bold">
                          <div className="row">
                            <div className="col-sm-4 text-info text-center">
                              <b>Ligações Recebidas<br />
                              {item.realizada}</b>
                            </div>
                            <div className="col-sm-4 text-success text-center">
                              <b>Efetuadas Positivas<br />
                              {item.efetuadaPositiva}</b>
                            </div>
                            <div className="col-sm-4 text-danger text-center">
                              <b>Efetuadas Negativas<br />
                              {item.efetuadaNegativo}</b>
                            </div>
                          </div>
                        </div>
                        {/*end::Text*/}
                      </div>
                      </>
                      )}): 
                      <img src={'https://expertpluss.hotelfazendasaojoao.com.br/img/user/1182687.png'} className="h-100 w-100" alt="" />
                    }   
                      </div>
                    {/*end::Body*/}
                  </div>
                  {/*end::List Widget 2*/}
                  </LoadingOverlay>
                </div>
              </div>
              {/*end::Row*/}
              
              {/*end::Dashboard*/}
            </div>
            {/*end::Container*/}
          </div>
          {/*end::Entry*/}
        </div>
        {/*end::Content*/}
        {/*begin::Footer*/}
        <div className="footer bg-white py-4 d-flex flex-lg-column" id="kt_footer">
          {/*begin::Container*/}
          <div className="container d-flex flex-column flex-md-row align-items-center justify-content-between">
            {/*begin::Copyright*/}
            <div className="text-dark order-2 order-md-1">
              <span className="text-muted font-weight-bold mr-2">2021©</span>
              <a href="#" target="_blank" className="text-dark-75 text-hover-primary">LAC</a>
            </div>
            {/*end::Copyright*/}
            {/*begin::Nav*/}
            <div className="nav nav-dark order-1 order-md-2">
              {/*Aqui Link e informação a direita */}
            </div>
            {/*end::Nav*/}
          </div>
          {/*end::Container*/}
        </div>
        {/*end::Footer*/}
        {/*end::Wrapper*/}
        {/*end::Page*/}
        {/*end::Main*/}      
        </>
    );

}

export default withRouter(Painel);                  