import React, { useState, useEffect } from 'react';
import Api from '../../../services/api';
import { withRouter } from "react-router";

import Swal from "sweetalert2";
import { useToasts } from 'react-toast-notifications';
import LoadingOverlay from 'react-loading-overlay';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import DataTable from 'react-data-table-component';
import { Modal } from "react-bootstrap";
import Select from 'react-select';

import InputMask from 'react-input-mask';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt-BR"; 
registerLocale("pt-BR", pt);

const EventosCafes = () => {
  const { addToast } = useToasts();
  const [ cafes, setCafes ] = useState([]);
  const [ caProcessa, setCaProcessa ] = useState(false);
  const [ buscaNome, setBuscaNome ] = useState();
  const [ buscaStatus, setBuscaStatus ] = useState();
  const [ modal, setModal ] = useState(false);
  const [ formCafe, setFormCafe ] = useState({});

  function fecharModal(){
    setModal(false);
  }

  const data = cafes;
  const columns = [
    {
      name: <th>Nome</th>,
      selector: 'nome',
      sortable: true,
      width: '16%',
    },
    {
      name: <th>Descrição</th>,
      selector: 'descricao',
      width: '30%',
      sortable: true,
      cell: row => { return <span dangerouslySetInnerHTML={{__html: row.descricao}}></span> }
    },
    {
      name: <th>Valor</th>,
      selector: 'valor',
      width: '10%',
      sortable: true,
      right: true,
    },
    {
      name: <th>Ativo</th>,
      width: '10%',
      sortable: true,
      selector: 'ativo',
      center: 'true',
      cell: row => { if (row.ativo === "S") {return <span class="label label-success label-inline mr-2">Sim</span>}
        else {return <span class="label label-secondary label-inline mr-2">Não</span>}
      }
    },
    {
      name: <th>Data Criação</th>,
      width: '12%',
      sortable: true,
      selector: 'dataInc',
      center: 'true'
    }, 
    {
      name: <th>Data Alteração</th>,
      width: '12%',
      sortable: true,
      selector: 'dataAlt',
      center: 'true'
    },
    {
      name: <th>Ações</th>,
      cell: (row) => 
      { return (<>
      <div class="dropdown dropdown-inline">
      <button type="button" class="btn btn-light-info btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="ki ki-bold-more-ver"></i>
      </button>
      <div class="dropdown-menu">
        <a class="dropdown-item" onClick={e => { editarCafe(row) }}>Editar</a>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item" onClick={e => { excluirCafe(row) }}>Excluir</a>
      </div>
    </div>
    </>)
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      right: true,
    },
  ];

   useEffect(() => {
    getListar();
  }, []);

  function getListar() { 
    setCaProcessa(true); 
    Api.get("cafes/listar").then(rps => {
      setCafes(rps.data.obj);
      setCaProcessa(false);
    })
  }
  
  function adicionarCafe(){
    setFormCafe({});
    setModal(true);
  }

  function editarCafe(id){  
    var ca = JSON.parse(JSON.stringify(id));

    setFormCafe(ca);
    setModal(true);            
  }

  function salvar(){
    setCaProcessa(true);
    Api.post('cafes/adicionar', formCafe).then(rps => {
      if (rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
        setModal(false);
        getListar();
      } else {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: rps.data.erros,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
      setCaProcessa(false);
    });
  }

  function filtrar() {
    setCaProcessa(true); 
      var data = {
        nome: buscaNome,
        status: buscaStatus,
      };
    
      Api.post('cafes/filtrar', data).then((rps) => {

        if(rps.data.status==false){
          Swal.fire({
            title: "Atenção!",
            icon: "info",
            html: rps.data.erro,
            showCloseButton: true,
            showCancelButton: false,
          });
        } else {
          setCafes(rps.data.obj);
        }
      setCaProcessa(false); 
    });
  }

  function excluirCafe(id){
    var ca = JSON.parse(JSON.stringify(id));

    setCaProcessa(true);

    Swal.fire({
        icon: "question",
        title: 'Confirmação',
        html: 'Deseja excluir o registro?',
        showCancelButton: true,
        confirmButtonText: `Sim, Confirmar!`,
        cancelButtonText: 'Não, Cancelar.'
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Api.post('cafes/excluir', ca).then(rps => {
          
          if(rps.data.status==true){
            addToast(rps.data.mensagem, {
              appearance: "success",
              autoDismiss: true,
              autoDismissTimeout: 2000
            });
            getListar();
          } 
        })
      } 
      setCaProcessa(false);
    }) 
  }
    
    return (
        <>
<div>

<div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Gerenciar Tarifas - Coffee break</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center">
              <button className="btn btn-lg btn-success mt-3 mb-3" onClick={e => {adicionarCafe()}}><i className="fas fa-plus" />Adicionar</button>
            </div>
            {/*end::Toolbar*/}
          </div>
        </div>
        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
            <div className="card card-custom">
              <div className="ml-10 mr-10 mt-10">    
                <div className="row">
                <div className="form-group col-md-8">
                  <label>Nome</label>
                  <input type="text" value={buscaNome} onChange={e => {setBuscaNome(e.target.value)}} className="form-control" />
                </div>
                <div className="form-group col-md-2">
                  <label>Ativo</label>
                  <select className="form-control" value={buscaStatus} onChange={e => {setBuscaStatus(e.target.value)}}>
                    <option value="" selected>Selecione</option>    
                    <option value="S">Sim</option>    
                    <option value="N">Não</option>    
                  </select>
                </div>

                  <div className="col-md-2 form-group">
                  <label>&nbsp;</label><br />
                    <button className="btn btn-primary btn-block" onClick={e=>{filtrar()}}><i className="flaticon2-search-1" />Pesquisar</button>
                  </div> 
                </div> 
              </div>

              <LoadingOverlay
                active={caProcessa}
                spinner
                text='Carregando...'
              >                 
                <DataTable
                  title="Lista de Coffee Break"
                  columns={columns}
                  data={data}
                  striped="true" 
                  pagination="true"
                />
              </LoadingOverlay>                    
      
                  </div>
                  </div>
                  </div>
                  </div>
                  
      <Modal size={'xl'} show={modal} onHide={() => fecharModal()}>
      <Modal.Header>
      <Modal.Title>Coffee Break</Modal.Title>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => {fecharModal()}}>
            <i aria-hidden="true" className="ki ki-close" />
          </button>
      </Modal.Header>
      <div className="row mt-5 ml-5 mr-5">
        <div className="form-group col-md-8">
          <label>Nome</label>
          <input type="text" value={formCafe?.nome} onChange={e => {setFormCafe({...formCafe, nome: e.target.value})}} className="form-control" />
        </div>

        <div className="form-group col-md-2">
          <label>Valor</label>
          <input type="text" value={formCafe?.valor} onChange={e => {setFormCafe({...formCafe, valor: e.target.value})}} className="form-control" />
        </div>
        
        <div className="form-group col-md-2">
          <label>Ativo</label>
          <select className="form-control" value={formCafe?.ativo} onChange={e => {setFormCafe({...formCafe, ativo: e.target.value})}}>
            <option value="" selected>Selecione</option>    
            <option value="S">Sim</option>    
            <option value="N">Não</option>    
          </select>
        </div>     
      </div>  

      <div className="row ml-5 mr-5">
          <div className="form-group col-md-12">
            <label>Descrição</label>
            <CKEditor
              editor={ ClassicEditor }
              data={formCafe.descricao}
              onChange={( event, editor ) => { setFormCafe({...formCafe, descricao: editor.getData()}) }}            
            />
          </div>
        </div>  
      
      <Modal.Footer>
        <button type="button" onClick={e => { fecharModal(); }} className="btn btn-secondary font-weight-bold" data-dismiss="modal"><i className="fas fa-door-open" />Fechar</button>
        <button type="button" onClick={e => { salvar(); }} className="btn btn-success font-weight-bold"><i className="fas fa-save" />Salvar</button>
      </Modal.Footer>
    </Modal>        
                 
        </>
    );
}

export default withRouter(EventosCafes);                  