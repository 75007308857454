import React, { useState, useEffect } from 'react';
import Api from '../../../../services/api';
import { withRouter } from "react-router";

import Swal from "sweetalert2";
import { useToasts } from 'react-toast-notifications';
import LoadingOverlay from 'react-loading-overlay';
import { Pie } from 'react-chartjs-2';

import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import InputMask from 'react-input-mask';
import pt from "date-fns/locale/pt-BR"; 
registerLocale("pt-BR", pt);

const FecFechadaProgramacao = () => {
  const { addToast } = useToasts();

  const [ periodo, setPeriodo ] = useState(0);
  const [ dataInicial, setDataInicial ] = useState();
  const [ dataFinal, setDataFinal ] = useState(new Date());

  const [ carregando, setCarregando ] = useState(false);
  
  //Grafico Conheceu o Hotel
  const [ conheceu , setConheceu ] = useState([]);
  const [ conheceuLabel , setConheceuLabel ] = useState([]);
  const [ conheceuValor , setConheceuValor ] = useState([]);
  const [ totalQtdConheceu, setTotalQtdConheceu ] = useState(0);

  useEffect(() => {
    getGerar();
  }, []);

  function getGerar() {
    setCarregando(true);
      
      var data = {
        periodo: periodo,
        data_inicio: dataInicial,
        data_final: dataFinal,
      };
    
      Api.post("relatorio/fechada_programacao", data).then(rps => {
        //***Inicio do valor Grafico Programacao
        var formataConheceuTexto = [];
        var formataConheceuValor = [];
        var qtdConheceu = 0;
  
        setConheceu(rps.data.obj);

        rps.data.obj.map( item => {
            formataConheceuTexto.push(item.nome);
            formataConheceuValor.push(item.qtde);
            qtdConheceu = parseInt(qtdConheceu) + parseInt(item.qtde);
        })
  
        setConheceuLabel(formataConheceuTexto);
        setConheceuValor(formataConheceuValor);
        setTotalQtdConheceu(qtdConheceu);
      
        setCarregando(false);
      })
  }
  
  //Grafico
  const data = {
    labels: conheceuLabel,
    datasets: [{
      data: conheceuValor,
      backgroundColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
        'rgba(220, 20, 60, 1)',
        'rgba(127, 255, 0, 1)',
        'rgba(189, 183, 107, 1)',
        'rgba(255, 215, 0, 1)',
        'rgba(255, 255, 0, 1)',
        'rgba(128, 0, 128, 1)',
        'rgba(175, 238, 238, 1)',
        'rgba(0, 0, 128, 1)',
        'rgba(178, 34, 34, 1)',
      ],
      hoverOffset: 4
    }]
  };

    return (
      <>
      {/*begin::Entry*/}
      <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
  
          <div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
            <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
              {/*begin::Details*/}
              <div className="d-flex align-items-center flex-wrap mr-2">
                {/*begin::Title*/}
                <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Relatório</h5>
                {/*end::Title*/}
                {/*begin::Separator*/}
                <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
                {/*end::Separator*/}
                Fechamento - Fechadas por Programação 
              </div>
              {/*end::Details*/}
            </div>
          </div>
          {/*begin::Entry*/}
          {/*begin card de pesquisa de vendas */}
            <div className="card card-custom">
                          
              <div className="card-body">
                <div className="row">
                  <div className="col-md-2 form-group">
                      <label>Período</label>
                      <select onChange={(e) => { setPeriodo(e.target.value)}} className="form-control">
                        <option value="0">Dia atual</option>
                        <option value="7">Últimos 7 dias</option>
                        <option value="30">Últimos 30 dias</option>
                        <option value="60">Últimos 60 dias</option>
                        <option value="90">Últimos 90 dias</option>
                        <option value="PP">Período Personalizado</option>
                      </select>
                  </div>
                  { periodo === "PP" && <>
                    <div className="col-md-2 form-group">
                        <label>Data Inicial</label>
                        <div className="input-group">
                        <DatePicker 
                          onChange={setDataInicial}
                          isClearable
                          selectsStart
                          locale="pt-BR"
                          className="form-control"
                          placeholderText="dd/mm/aaaa" 
                          selected ={dataInicial}
                          dateFormat="dd/MM/y"
                          startDate={dataInicial}
                          endDate={dataFinal}
                          customInput={
                            <InputMask mask="99/99/9999" />}/>
                        </div>
                    </div>
                    <div className="col-md-2 form-group">
                      <label>Data Final</label>
                      <div className="input-group">
                        <DatePicker 
                          onChange={setDataFinal}
                          isClearable
                          locale="pt-BR"
                          className="form-control"
                          placeholderText="dd/mm/aaaa" 
                          selected ={dataFinal}
                          dateFormat="dd/MM/y"
                          selectsEnd
                          startDate={dataInicial}
                          endDate={dataFinal}
                          minDate={dataInicial} 
                          customInput={
                            <InputMask mask="99/99/9999" />}/>
                        </div>
                      </div>
                    </>
                  }
                  <div className="d-flex align-items-center">
                    {/*begin::Button*/}
                    <button onClick={e => { getGerar(); }} className="btn btn-info btn-block pl-10 pr-10 btn-md">Filtrar</button>
                    {/*end::Button*/}
                    {/*begin::Button*/}
                  </div>   
                </div>
                  
                  
              </div> 
            </div>                         
          <br />

          <LoadingOverlay
            active={carregando}
            spinner
            text='Carregando...'
          >
          <div className="row">
            <div className="col-md-4">
              <div className="card card-custom">
                <div className="card-body">
                  <h3 className="card-title"><b>{totalQtdConheceu} Fechada por Programação</b></h3>
                  
                  { conheceu.map((item) =>{ return(
                    <>
                    <hr />
                    <div className="row">
                      <div className="col-md-10">
                        <h6>{item.nome}</h6>
                      </div>
                      <div className="col-md-2 text-right">
                        <h6><b>{item.qtde}</b></h6>
                      </div>
                    </div>
                    </>
                    )}
                  )}
                  
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="card card-custom">
                <div className="card-body">
                  <Pie data={data}  />
                </div>
              </div>
            </div>
          </div>

          </LoadingOverlay>
            </div>
          </div>        
        </>
    );

}



export default withRouter(FecFechadaProgramacao);                  